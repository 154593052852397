// @ts-nocheck

import app from "@/main";
import {DosyaEvraklarCevapRow} from "../uyap/DosyaEvraklar";
import * as xml2js from "xml2js";
import JSZip from "jszip";
import UyapTakipTalebiContent from "./UyapTakipTalebiContent";
import {AvukatIcraDosyalariTalep} from "../uyap/AvukatIcraDosyalari";

export default class TakipSenkronize {
    queueCount: number = 0;
    workingNumber: number = 0;

    async runQueryLastOneWeek(): Promise<void> {
        try {
            let requestData = new AvukatIcraDosyalariTalep();
            requestData.dosyaAcilisTarihiStart = app.$uyapHelper.getDateNow(".", "day -7");
            requestData.dosyaAcilisTarihiEnd = app.$uyapHelper.getDateNow(".", "");
            let acikDosyalar = await app.$uyap.AvukatIcraDosyalari().run(requestData);
            this.queueCount = acikDosyalar.items.length;
            let key = 0;
            for (const dosya of acikDosyalar.items) {
                this.workingNumber = key + 1;
                let evraklar = await app.$uyap.DosyaEvraklar().run({
                    dosyaId: dosya.dosyaId
                });
                let takipTalebi = evraklar.items.find((x: DosyaEvraklarCevapRow) => x.turu == "Takip Talebi");
                if (takipTalebi) {
                    //Takip Talebi ve Ekleri (1)
                    let takipTalebiBlob = await app.$uyap.EvrakIndir().run({
                        dosyaId: dosya.dosyaId,
                        evrakId: takipTalebi.evrakId,
                        yargiTuru: 1
                    })
                    let parsedContent: any = await TakipSenkronize.parseContentXml(takipTalebiBlob);
                    let uyapTakipTalebiContent = UyapTakipTalebiContent.parse(parsedContent);
                    await app.$http.post('/api/v1/uyap-takip/', {
                        hash: uyapTakipTalebiContent.hash,
                        type: "uyap",
                        dosya: uyapTakipTalebiContent,
                        uyap_dosya_id: dosya.dosyaId,
                        status: 5,
                    });
                    //let response = await app.$http
                    console.log("created uyapTakipTalebiContent", uyapTakipTalebiContent);
                    key++;
                }
            }
        } catch (e) {
            throw new Error(e.message);
        }
    }

    async run(dosyaId: number): Promise<any> {
        let evraklar = await app.$uyap.DosyaEvraklar().run({
            dosyaId: dosyaId.toString()
        });
        console.log("evraklar", evraklar);
        let takipTalebi = evraklar.items.find((x: DosyaEvraklarCevapRow) => x.turu == "Takip Talebi");
        // @ts-ignore
        let odemeIcraEmri = evraklar.items.findLast((x: DosyaEvraklarCevapRow) => x.turu == "Ödeme İcra Emri");
        console.log("takipTalebi", takipTalebi);
        console.log("odemeIcraEmri", odemeIcraEmri);
        if (takipTalebi) {
            console.log("takiptalebi bulundu", takipTalebi);
            //Takip Talebi ve Ekleri (1)
            let takipTalebiBlob = await app.$uyap.EvrakIndir().run({
                dosyaId: dosyaId,
                evrakId: takipTalebi.evrakId,
                yargiTuru: 1
            })
            let content = await TakipSenkronize.parseContentXml(takipTalebiBlob);
            console.log("CONTENT", content);
            console.log("CONTENT", JSON.stringify(content));
        }
        /*else if(odemeIcraEmri){
            console.log("odemeIcraEmri bulundu",odemeIcraEmri);
            let blob = await app.$uyap.EvrakIndir().run({
                dosyaId:dosyaId,
                evrakId:odemeIcraEmri.evrakId,
                yargiTuru:1
            })
            let content = await TakipSenkronize.parseContentXml(blob);
            console.log("CONTENT",content);
            console.log("CONTENT",JSON.stringify(content));
        }

         */
    }

    static async parseContentXml(blob: Blob): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let zip = await JSZip.loadAsync(blob);
                // @ts-ignore
                zip.file("content.xml").async("string").then(function (xml) {
                    let startIndex = xml.indexOf("<data>") + 6;
                    let endIndex = xml.indexOf("</data>");
                    let xmlContentData = xml.substr(startIndex, endIndex - startIndex);
                    xmlContentData = "<data>" + xmlContentData + "</data>";
                    console.log(xmlContentData);
                    let parseString = xml2js.parseString;
                    parseString(xmlContentData, {explicitArray: false}, (error: Error | null, result: any) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve(result.data);
                    });
                });
            } catch (e) {
                return reject(e);
            }
        })
    }

}