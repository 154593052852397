import {UyapRequest} from "./UyapRequest";
import Adres from "../takip_ac/parser/Adres";
import app from "@/main";

export interface GetAdresListTalep {
    kisiKurumId: number,
    tarafTur: number
}

export default class GetAdresList extends UyapRequest<GetAdresListTalep, Adres[]> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetAdresList_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getAdresListesi_brd.ajx";
    }

    async parse(data: any): Promise<Adres[]> {
        return data;
    }

    getMockResponse(): any {
        return [{
            "ilKodu": 34,
            "ilAdi": "İSTANBUL",
            "ilceAdi": "TUZLA",
            "ilceKodu": 30,
            "adres": "AYDINLI MAH. SERVER SOK. NO: 16",
            "kisiKurumID": 213320803,
            "adresID": 266282426,
            "adresTuru": "ADRTR00002",
            "adresTuruAciklama": "Yurt İçi İşyeri Adresi",
            "ekleyeninAdi": "HALİL İBRAHİM",
            "ekleyeninSoyadi": "GÜNDÜR",
            "ekleyeninBirimi": "Büyükçekmece 2. İcra Dairesi",
            "ekleyeninBirimId": "1001529",
            "ekleyeninSicili": "102974",
            "eklemeTarihi": "Feb 11, 2020 10:51:48 AM",
            "eklenebilirAdresMi": false,
            "kayitliAdresMi": false,
            "aktifAdresmi": false,
            "guncellendiMi": false,
            "aktif": "E"
        }, {
            "adres": " ",
            "eTebligatAdresi": "25999-22139-56882",
            "kisiKurumID": 18216707,
            "adresID": 249229554,
            "adresTuru": "ADRTR00013",
            "adresTuruAciklama": "Elektronik Tebligat Adresi",
            "ekleyeninAdi": "MEHMET EMİN",
            "ekleyeninSoyadi": "KARANFİL",
            "ekleyeninBirimi": "Afyonkarahisar İcra Dairesi",
            "ekleyeninBirimId": "1039298",
            "ekleyeninSicili": "217830",
            "eklemeTarihi": "May 30, 2019 11:47:48 AM",
            "eklenebilirAdresMi": false,
            "kayitliAdresMi": false,
            "aktifAdresmi": false,
            "guncellendiMi": false,
            "aktif": "E"
        }];
    }
}