import {UyapRequest} from "./UyapRequest";

export interface MtsTakipTalebiOlusturTalep {
    // Content-Type: multipart/form-data; boundary=----WebKitFormBoundarypedja3U5tzJMdKDO
    MTSDosyaBilgileri: any,
    MTS: number,
    tarafList: any,
    ilamsizList: any,
    MTSHarcBilgileri: any
}


export interface MtsTakipTalebiOlusturCevap {
    dosyaId: string,
    takibinYolu: number,
    aciklama: string,
    dayanakBelgeZorunlumu: boolean,
    takibeEsasTutar: number,
    yenileme: string,
    terekeMi: string,
    dosyaDurumu: number,
    takipAnaTur: number,
    bk84Uygulansinmi: string,
    bsmvUygulansinmi: string,
    eskiDosyami: string,
    tefrikDosyasimi: string,
    dosyaTurKodu: number,
    aciklama48E9: string,
    dosyaAcilisTarihi: string,
    kkdfHesaplansinmi: string,
    vekaletUcretineKDVUygulansinmi: string,
    feragatIslmDrm: number,
    gelisYeri: number,
    kdv: string,
    vergi: string,
    mahiyetId: number,
    mtsAdliyeBirimID: string,
    gnlDosyaDurumu: number,
    birimID: string,
}


export default class MtsTakipTalebiOlustur extends UyapRequest<MtsTakipTalebiOlusturTalep, MtsTakipTalebiOlusturCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {"dosyaId":"ldTdEj@@VD0MLD1LbpeGzdZkDlfWet30ttCge@btCZXMQaV+0zswJwQZY3za64yn","takibinYolu":0,"aciklama":"Alacağın tahsili tarihine kadar %........ faiz masraf ve vekalet ücreti ile tahsili. Kısmi ödemelerde TBK 100 e göre hesap yapılmasını talep ederim.","dayanakBelgeZorunlumu":false,"takibeEsasTutar":1,"yenileme":"H","terekeMi":"H","dosyaDurumu":0,"takipAnaTur":1,"bk84Uygulansinmi":"E","bsmvUygulansinmi":"H","eskiDosyami":"H","tefrikDosyasimi":"H","dosyaTurKodu":294,"aciklama48E9":"Haciz Yolu","dosyaAcilisTarihi":"05/05/2024 19:14:22","kkdfHesaplansinmi":"H","vekaletUcretineKDVUygulansinmi":"H","feragatIslmDrm":2,"gelisYeri":1,"kdv":"H","vergi":"H","mahiyetId":6007,"mtsAdliyeBirimID":"1001497","gnlDosyaDurumu":0,"birimID":"1054286"};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsTakipTalebiOlustur_brd.ajx';
    }

    async parse(data: any): Promise<MtsTakipTalebiOlusturCevap> {
        return data;
    }

}

