export interface YuzucDavetiyesininTebligeCikartilmasiParamsInterface {
    tebligatTuru: number,
    tebligatTuruAciklama: string,
    adresTuru: number,
    adresTuruAciklama: string,
    adres: string,
    aciklama: string,
    hacizTarihi: string,
    malTip: number,
    malTipAciklama: string,
}

export interface BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface {
    tebligatTuru: number,
    tebligatTuruAciklama: string,
    adresTuru: number,
    adresTuruAciklama: string,
    adres: string,
}

export type IcraOdemeEmrininTebligeCikartilmasiParamsInterface = BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface;
export type YenilemeEmrininTebligeCikartilmasiParamsInterface = BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface;

export interface KiymetTakdirininTebligeCikartilmasiParamsInterface {
    tebligatTuru: number,
    tebligatTuruAciklama: string,
    adresTuru: number,
    adresTuruAciklama: string,
    adres: string,
    aciklama: string,
    kiymetTakdirTarihi: string,
    malTip: number,
    malTipAciklama: string,
}

export interface TaahhuduKabulMuhtirasiTebligiParamsInterface {
    tebligatTuru: number,
    tebligatTuruAciklama: string,
    adresTuru: number,
    adresTuruAciklama: string,
    adres: string,
    aciklama: string,
    taahhutTarihi: string
}

export interface TemlikbilgisininBildirilmesiParamsInterface {
    tebligatTuru: number,
    tebligatTuruAciklama: string,
    adresTuru: number,
    adresTuruAciklama: string,
    adres: string,
    kismiTemlikMi: boolean,
    kismiTemlikMiStr: string,
    temlikAlacakliKisiKurumId: number,
    temlikAlacakliAdi: string,
    temlikEdilen: string,
    temlikTipi: number,
    temlikTutarTuru: string,
    temlikTutari: number
}

export class DosyaBorcluTebligatInterface {
    yuzucDavetiyesininTebligeCikartilmasiParams: YuzucDavetiyesininTebligeCikartilmasiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
        aciklama: "",
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
    }

    bakiyeBorcMuhtirasininTebligeCikartilmasiParams: BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
    }

    icraOdemeEmrininTebligeCikartilmasiParams: IcraOdemeEmrininTebligeCikartilmasiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
    }

    kiymetTakdirininTebligeCikartilmasiParams: KiymetTakdirininTebligeCikartilmasiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
        aciklama: "",
        kiymetTakdirTarihi: "",
        malTip: 0,
        malTipAciklama: "",
    }

    taahuduKabulMuhtirasiTebligiParams: TaahhuduKabulMuhtirasiTebligiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
        aciklama: "",
        taahhutTarihi: ""
    }

    temlikBilgisininBildirilmesiParams: TemlikbilgisininBildirilmesiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
        kismiTemlikMi: false,
        kismiTemlikMiStr: "",
        temlikAlacakliKisiKurumId: 0,
        temlikAlacakliAdi: "",
        temlikEdilen: "",
        temlikTipi: 0,
        temlikTutarTuru: "",
        temlikTutari: 0
    }

    yenilemeEmrininTebligeCikartilmasiParams: YenilemeEmrininTebligeCikartilmasiParamsInterface = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,
        adresTuruAciklama: "",
        adres: "",
    }
}