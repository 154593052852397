import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";

export interface GetEvrakBlobTalep {
    evrakId: number | string,
    dosyaId: string,
}

export class EvrakIndir extends UyapRequest<GetEvrakBlobTalep, Blob> {
    delayTime = 2000;

    getMethod(): "POST" | "GET" {
        return 'GET';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): Blob {
        return new Blob();
    }

    getResponseType(): ResponseType {
        return 'blob';
    }

    getUrl(): string {
        return this.ImzaClientAddr + `download_document_brd.uyap`;//?evrakId=`+this.talepData.evrakId+`&dosyaId=`+this.talepData.dosyaId;
    }

    async parse(data: Blob): Promise<Blob> {
        return data;
    }
}

export default EvrakIndir;
