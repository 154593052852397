import {UyapRequest} from "./UyapRequest";

// TODO: har lazım gelen değer belli değil

export interface TakibiKesinlesenBorcluListesiTalep {
    dosyaId: number,
}

export interface TakibiKesinlesenBorcluListesiCevap {
    items: Array<TakibiKesinlesenBorcluListesiCevapRow>
}

export interface TakibiKesinlesenBorcluListesiCevapRow {
    // burası eksik
    ortakAd: string,
    kisiKurumId: number
}

export default class TakibiKesinlesenBorcluListesi extends UyapRequest<TakibiKesinlesenBorcluListesiTalep, TakibiKesinlesenBorcluListesiCevap> {
    getMethod(): 'GET' | 'POST' {
        return "POST";
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getTakibiKesinlesenBorcluListesi_brd.ajx";
    }

    async parse(data: Array<TakibiKesinlesenBorcluListesiCevapRow>): Promise<TakibiKesinlesenBorcluListesiCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        return [];
    }
}