import Args from "../Args";
import {Mixins} from "../../Mixins";
import {MtsBelge} from "../../MtsXmlParser";
import MtsDiger from "./MtsDiger";
import MtsAlacakKalem from "./MtsAlacakKalem";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export default class MtsIlamsizParser {
    turksatMersisNo = "0881026377200139";
    enerjisaMersisNo = "0335043212300017";
    digerNodeList !: NodeListOf<Element>;
    id: number = 0; //counter
    icraproXmliMi = false;
    belgeList: MtsBelge[] = [];
    tarafList: Array<TarafKisi | TarafKurum> = [];
    ilamsizList: MtsDiger[] = [];

    static parse(digerNodeList: NodeListOf<Element>,
                 belgeList: MtsBelge[],
                 tarafList: Array<TarafKisi | TarafKurum>,
                 args: Args): MtsDiger[] {
        if (digerNodeList) {
            let mtsIlamsiz = new MtsIlamsizParser();
            mtsIlamsiz.digerNodeList = digerNodeList;
            mtsIlamsiz.tarafList = tarafList;
            mtsIlamsiz.belgeList = belgeList;
            mtsIlamsiz.icraproXmliMi = args.icraproXmliMi;
            if (mtsIlamsiz.belgeVarmi()) {
                if (mtsIlamsiz.isTurksatXml() === true && mtsIlamsiz.isIcraproXml() === false)
                    mtsIlamsiz.turksatXmlParser();
                else if (mtsIlamsiz.isEnerjisaXml())
                    mtsIlamsiz.enerjisaXmlParser();
                else
                    mtsIlamsiz.digerKurumlaraOzelXmlParser();
            } else {
                mtsIlamsiz.digerParser();
            }
            return mtsIlamsiz.getIlamsizList();
        }
        return [];
    }

    getIlamsizList(): MtsDiger[] {
        return this.ilamsizList;
    }

    pushIlamsizList(ilamsiz: MtsDiger) {
        console.log("PUSH ILAMSIZ LIST", ilamsiz);
        this.ilamsizList.push(ilamsiz);
        this.id++;
    }

    digerParser() {
        console.log("MTS DIGER PARSER");
        this.digerNodeList.forEach((digerNode: Element) => {
            let mtsDiger = new MtsDiger();
            let alacakKalemleriNodeList = digerNode.querySelectorAll('alacakkalemi');
            let digerAlacakAciklama: any = Mixins.nullUndefCleaner(digerNode.getAttribute("digeralacakaciklama"));
            console.log("digerAlacakAciklama", digerAlacakAciklama);
            console.log("TANZIM TARIHI", digerNode.getAttribute("tanzimtarihi"));
            console.log("DUZ TARIHI", digerNode.getAttribute("tarih"));
            mtsDiger.id = this.id;
            mtsDiger.tarih = Mixins.tarihConverter(digerNode.getAttribute("tanzimtarihi"));//getDateNow();
            mtsDiger.muacceliyetTarihi = Mixins.tarihConverter(digerNode.getAttribute("tarih"));
            mtsDiger.no = Mixins.nullUndefCleaner(digerNode.getAttribute("alacakno"));
            mtsDiger.tutarTL = Mixins.replaceCommaDots(digerNode.getAttribute("tutar"));
            mtsDiger.aciklama = digerAlacakAciklama;
            if (alacakKalemleriNodeList.length > 0)
                this.setAlacakKalemleri(alacakKalemleriNodeList, mtsDiger);
            this.pushIlamsizList(mtsDiger);
        })
    }

    setAlacakKalemleri(alacakKalemleriNodeList: any, mtsDiger: MtsDiger) {
        let digerAlacakAciklama: any = mtsDiger.aciklama;
        if (!this.isIcraproXml())  //Sadece icrapro xmllerinde calismasi icin eup 230
            digerAlacakAciklama = null;
        let alacakKalemleri = this.createAlacakKalem(alacakKalemleriNodeList, digerAlacakAciklama);
        mtsDiger.alacakKalemleri = mtsDiger.alacakKalemleri.concat(alacakKalemleri);
        /*
       alacakKalemleriNodeList.forEach((node:Element)=>{
           let alacakKalem = MtsAlacakKalem.parse(node, this.tarafList,digerAlacakAciklama);
           if(alacakKalem != null){
               mtsDiger.alacakKalemleri.push(alacakKalem);
           }
       });
         */
    }

    createAlacakKalem(alacakKalemleriNodeList: any, digerAlacakAciklama = null): MtsAlacakKalem[] {
        let alacakKalemleri: MtsAlacakKalem[] = [];
        alacakKalemleriNodeList.forEach((node: Element) => {
            let alacakKalem = MtsAlacakKalem.parse(node, this.tarafList, digerAlacakAciklama);
            if (alacakKalem != null) {
                alacakKalemleri.push(alacakKalem);
            }
        });
        return alacakKalemleri;
    }

    digerKurumlaraOzelXmlParser() {
        console.log("** DigerKurumlaraOzelXmlParser **")

        let ignoreAlacakKalemIds: any[] = [];
        this.digerNodeList.forEach(digerNode => {
            let alacakKalemleriNodeList = digerNode.querySelectorAll('alacakkalemi');
            alacakKalemleriNodeList.forEach(alacakKalemiNode => {
                let alacakKalemId = Mixins.onlyDigit(alacakKalemiNode.getAttribute("id"));
                this.belgeList.forEach(belge => {
                    //console.log()
                    if (alacakKalemId == belge.id) {
                        let mtsDiger = new MtsDiger();
                        mtsDiger.tutarTL = Mixins.replaceCommaDots(alacakKalemiNode.getAttribute("alacakkalemtutar"));
                        if (mtsDiger.tutarTL == 0)
                            return;
                        mtsDiger.id = this.id;
                        mtsDiger.tarih = belge.faturaTarihi
                        mtsDiger.no = belge.faturaId;
                        mtsDiger.muacceliyetTarihi = belge.odemeTarihi;
                        mtsDiger.aciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkodaciklama"));

                        if (alacakKalemiNode.getAttribute("alacakkalemkod") == "6") {
                            ignoreAlacakKalemIds.push(alacakKalemId);
                        }

                        this.setAlacakKalemleri([alacakKalemiNode], mtsDiger);
                        if (mtsDiger.alacakKalemleri.length == 0)
                            return;

                        this.pushIlamsizList(mtsDiger);
                    }
                });
            });

            //EUP-504
            if (this.ilamsizList.length > 0) {
                let islemisFaizJsonAlacakKalemleri = this.islemisFaizAlacakKalemleri(alacakKalemleriNodeList, ignoreAlacakKalemIds);
                console.log("islemisFaizJsonAlacakKalemleri", islemisFaizJsonAlacakKalemleri);
                this.ilamsizList[0].alacakKalemleri = this.ilamsizList[0].alacakKalemleri.concat(islemisFaizJsonAlacakKalemleri);
            }
            //END OF EUP-504

        });


    }

    islemisFaizAlacakKalemleri(alacakKalemleri: NodeListOf<Element>, ignoreAlacakKalemIds: any[]) {
        let alacakKalemleriNodeList: any[] = [];
        alacakKalemleri.forEach(alacakKalemiNode => {
            let alacakKalemId = Mixins.onlyDigit(alacakKalemiNode.getAttribute("id"));
            let idExists = ignoreAlacakKalemIds.find((x: any) => x == alacakKalemId);
            //@ts-ignore
            if (!idExists && alacakKalemiNode.getAttribute("alacakkalemkod") == 6) {
                alacakKalemleriNodeList.push(alacakKalemiNode);
            }
        });
        return this.createAlacakKalem(
            alacakKalemleriNodeList,
            null
        );
    }

    enerjisaXmlParser() {
        console.log("ENERJISA XML TRYING TO  CONVERT");
        this.digerNodeList.forEach(digerNode => {
            let alacakKalemleriNodeList = digerNode.querySelectorAll('alacakkalemi');
            let digerTarih = Mixins.getCleanAttribute(digerNode, "tarih");
            let digerTutar = Mixins.getCleanAttribute(digerNode, "tutar");
            this.belgeList.forEach(belge => {
                if (digerTarih == belge.odemeTarihi && digerTutar == belge.acikTutar) {
                    let mtsDiger = new MtsDiger();
                    mtsDiger.tutarTL = belge.acikTutar;
                    if (mtsDiger.tutarTL == 0)
                        return;
                    mtsDiger.id = this.id;
                    mtsDiger.tarih = belge.faturaTarihi;
                    mtsDiger.no = belge.faturaId;
                    mtsDiger.muacceliyetTarihi = belge.odemeTarihi;
                    mtsDiger.aciklama = Mixins.getCleanAttribute(digerNode, "digeralacakaciklama");
                    if (alacakKalemleriNodeList.length > 0) {
                        this.setAlacakKalemleri(alacakKalemleriNodeList, mtsDiger)
                    }
                    this.pushIlamsizList(mtsDiger);
                }
            })
        })
    }

    turksatXmlParser() {
        console.log("MTS TURKSAT XML PARSER BEGIN");
        let digerNode = this.digerNodeList[0];
        let alacakKalemiNodeList = digerNode.querySelectorAll('alacakkalemi'); //ilkini al
        let alacakKalemiNode = alacakKalemiNodeList[0];
        let counter = 0;
        this.belgeList.forEach(belge => {
            if (belge.acikTutar <= 0)
                return;
            let mtsDiger = new MtsDiger();
            mtsDiger.tutarTL = belge.acikTutar;
            mtsDiger.id = this.id;
            mtsDiger.tarih = belge.faturaTarihi;//belge.faturaId;
            mtsDiger.no = belge.id;
            mtsDiger.muacceliyetTarihi = belge.odemeTarihi;
            mtsDiger.aciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkodaciklama"));
            //Alacak kalem tutari farkli oldugu icin o ilamsizin tutari ekleniyor.
            alacakKalemiNode.setAttribute("alacakkalemtutar", mtsDiger.tutarTL);
            alacakKalemiNode.setAttribute("alacakKalemIlkTutar", mtsDiger.tutarTL);

            let alacakKalemleri: MtsAlacakKalem[] = [];
            if (alacakKalemiNodeList.length > 1 && counter == 0) {
                //alcakKalemleri islemis faiz varsa bikerelik ilamsiza ekleniyor.TT ye yansimasi icin
                this.setAlacakKalemleri(alacakKalemiNodeList, mtsDiger);
                counter++;
            } else {

                //alcakKalemleri sadece o alacak kaleminin faiz i islensin diye ilk alacak kalemi gonderiliyor.
                let alacakKalem = MtsAlacakKalem.parse(alacakKalemiNode, this.tarafList);
                if (alacakKalem)
                    alacakKalemleri.push(alacakKalem);
            }
            if (alacakKalemleri.length != 0)
                mtsDiger.alacakKalemleri = alacakKalemleri;

            this.pushIlamsizList(mtsDiger);
        });
    }

    kurumVarmi() {
        return this.tarafList.find(taraf => taraf.isKurum());
    }

    belgeVarmi(): boolean {
        return this.belgeList.length > 0;
    }

    isEnerjisaXml(): boolean {
        if (this.tarafList.find(taraf => taraf.temelBilgileri.mersisNo == this.enerjisaMersisNo))
            return true;
        return false;
    }

    isTurksatXml(): boolean {
        if (this.tarafList.find(taraf => taraf.temelBilgileri.mersisNo == this.turksatMersisNo))
            return true;
        return false;
    }

    isIcraproXml(): boolean {
        return this.icraproXmliMi === true;
    }
}

