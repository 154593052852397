export enum AlacakKalemiAlani {
    asilAlacak = 1,
    takipOncesiFaiz,
    tazminat,
    komisyon,
    protesto,
    ihtarMasrafi,
    cezaiSart,
    takipOncesiBsmv,
    takipOncesiKkdf,
    ihtiyatiHaciz,
    takipOncesiMasraf,
    ekAlan1,
    ekAlan2,
    ekAlan3,
    ekAlan4,
    vekaletUcreti,
    takipSonrasiFaiz,
    takipSonrasiBsmv,
    takipSonrasiKkdf,
    harc,
    takipsonrasiMasraf,
    yasalVekaletUcreti,
    takipSonrasiAsilAlacak,
    takipOncesiGecikmeTazminati,
    takipSonrasiGecikmeTazminati,
}

export enum AlacakKalemiAlaniLabel {
    "Asıl Alacak" = 1,
    "Takip Öncesi Faiz",
    "Tazminat",
    "Komisyon",
    "Protesto",
    "İhtar Masrafı",
    "Cezai Şart",
    "Takip Öncesi BSMV",
    "Takip Öncesi KKDF",
    "İhtiyati Haciz",
    "Takip Öncesi Masraf",
    "Ek Alan 1",
    "Ek Alan 2",
    "Ek Alan 3",
    "Ek Alan 4",
    "Vekalet Ücreti",
    "Takip Sonrası Faiz",
    "Takip Sonrası BSMV",
    "Takip Sonrası KKDF",
    "Harç",
    "Takip Sonrası Masraf",
    "İhtiyati Haciz Vekalet Ücreti",
    "Takip Sonrası Asıl Alacak",
    "Takip Öncesi Gecikme Tazminatı",
    "Takip Sonrası Gecikme Tazminatı",
}