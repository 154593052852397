import {Mixins} from "../Mixins";
import Adres from "./Adres";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class TemelBilgileri {
    tcKimlikNo!: string;
    adi: string = "";
    soyadi: string = "";
    babaAdi: string = "";
    dogumTarihiStr: string = "";
    cinsiyeti: string = "";
    vergiNo: string = "";
    mersisNo: string = "";
    kurumAdi: string = "";
    olumKaydi: boolean = false;
    sorguTuru!: number;
    kisiKurumID!: number;
    kisiKurumId: any;
    merkezAdresi!: Adres;

    static fromJson(jsonData: any): TemelBilgileri{
        return Args.staticFromJson(jsonData,new TemelBilgileri());
    }

    static parse(kisiTumBilgileriNode: HTMLUnknownElement | Element): TemelBilgileri {
        let temelBilgiler = new TemelBilgileri();
        temelBilgiler.tcKimlikNo = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("tckimlikno"));
        temelBilgiler.adi = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("adi"));
        temelBilgiler.soyadi = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("soyadi"));
        temelBilgiler.babaAdi = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("babaadi"));
        temelBilgiler.dogumTarihiStr = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("dogumtarihi"));
        temelBilgiler.cinsiyeti = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("cinsiyeti"));
        temelBilgiler.vergiNo = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("vergino"));
        temelBilgiler.mersisNo = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("mersisno"));
        temelBilgiler.kurumAdi = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("kurumadi"));
        return temelBilgiler;
    }

}

