import {UyapRequest} from "./UyapRequest";

export interface EvrakGonderTalep {
    formData: true,
    data: FormData
}

export interface EvrakGonderCevap {
    type: string,
    message: string
}

export default class EvrakGonder extends UyapRequest<EvrakGonderTalep, EvrakGonderCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return `<root><ResultEvent>
  <type>success</type>
  <message>Evraklar, tevzi dosyasına gönderildi. </message>
  <log/>
</ResultEvent></root>`
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/evrakGonder_brd.ajx";
    }

    parse(data: any): Promise<EvrakGonderCevap> {
        return new Promise(async (resolve, reject) => {
            let jsonObj: any = await this.xml2json(data);
            if (jsonObj.root.ResultEvent.type == "success") {
                return resolve(jsonObj.root.ResultEvent);
            } else {
                return reject(jsonObj.root.ResultEvent);
            }
        })

    }
}

