import {UyapRequest} from "./UyapRequest";

export interface DosyaOdemeIslemleriTalep {
    dosyaId: string,
    harcMasrafTipi: string,
    harcMasrafList: string,
    postaMasraflariList: {
        id: number,
        tur: 'D' | string,
        adi: string,
        adet: number,
        tutar: number
    }[] | string,
    odemeTipi: string,
    tarafDVO: string,
    uyapId: string,
    vakifbankHesapBilgileri: string
}

export interface DosyaOdemeIslemleriCevap {
    type: string,
    message: string,
    log: string
}

export class DosyaOdemeIslemleri extends UyapRequest<DosyaOdemeIslemleriTalep, DosyaOdemeIslemleriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "type": "success",
            "message": "<strong>Eskişehir 7. İcra Dairesi - 2024/3260 </strong> nolu dosya için ödeme işlem(ler)i başarıyla gerçekleşti. 120,00 TL  ilgili vezne hesap(lar)ına aktarıldı. Yapılan ödemelere ait makbuzlar dosyaya eklendi. İlgili yargı birimi iş listesine yapılan ödemelere ilişkin uyarı düşürüldü.",
            "log": ""
        };
        //for error
        //`<root><errorCode>PRTL_AVKT_10038-1</errorCode><error>Bu işlemi gerçekleştirebilmek için barokart bakiyeniz yetersizdir. https://barokart.com.tr adresinden bakiye yükledikten sonra tekrar deneyiniz. </error></root>`,
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosya_odeme_islemleri_brd.ajx';
    }

    async parse(data: any): Promise<DosyaOdemeIslemleriCevap> {
        return data;
    }
}

export default DosyaOdemeIslemleri;
