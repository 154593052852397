export enum AdresTuru {
    mernis = 1,
    yurticiev,
    yurtdisiev,
    depo,
    yurticiis,
    yurtdisiis,
    cezaevi,
    askerlik,
    mersis
}

export enum AdresTuruLabel {
    "Mernis Adresi" = 1,
    "Yurt İçi İkametgah Adresi",
    "Yurt Dışı İkametgah Adresi",
    "Depo Adresi",
    "Yurt İçi İş Adresi",
    "Yurt Dışı İş Adresi",
    "Cezaevi Adresi",
    "Askerlik Adresi",
    "Mersis Adresi" = 9
}