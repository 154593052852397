import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export interface BorcluDibTalep {
    dosyaId: string,
    kisiKurumId: number,
}

export interface BorcluDibCevap extends ISorguSonuc<Array<IDibSonuc>> {
}

export interface IDibSonuc {
    adres: string,
    adresTipi: {
        "_value_": string
    },
    eyalet: string,
    postaKodu: string,
    sehir: string,
    ulke: string,
}

export default class BorcluDib extends UyapRequest<BorcluDibTalep, BorcluDibCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcludib_" + this.talepData.kisiKurumId
        };
    }

    getMockResponse(): any {
        // yok
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"2********14","adi":"MA****","soyadi":"KI********","babaAdi":"Y****","anaAdi":"S***","dogumTarihiStr":"1965","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":4000003527,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 7. Genel İcra Dairesi","birimId":"1000942","orgKodu":"1.04.011.000.6042","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6042","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/97995","dosyaNoYil":2023,"dosyaSira":97995,"dosyaId":"IplI9eWZYxV5k0SnSHvyzvFdfe0NGWKfCoR0MuaHiPYP6uccK8tDX6389MUN993S","dosyaAcilisTarihi":"11/11/2023 04:25","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"MAZHAR KILIÇASLAN","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"20/04/2024 16:25","sorgulamaTuru":"DIŞ İŞLERİ","sorguSonucu":false,"hasError":false,"hasData":false,"isMock":false};
        // var
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"2********16","adi":"ÇA****","soyadi":"TA***","babaAdi":"E****","anaAdi":"G******","dogumTarihiStr":"1981","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":43774689,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 2. Genel İcra Dairesi","birimId":"1000963","orgKodu":"1.04.011.000.6037","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6037","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/28859","dosyaNoYil":2023,"dosyaSira":28859,"dosyaId":"FtmFKLTJwPIcmUYbMromEqLeikEO97YuGCpo@jQTV3Wx1BAacaH5rGwfFTklEoMs","dosyaAcilisTarihi":"08/07/2023 12:39","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ÇAĞLAR TABAK","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"20/04/2024 16:29","sorguSonucDVO":[{"adres":"UZUN APT 10 D:14 KARDEŞLİK SK ","adresTipi":{"_value_":"Ev"},"eyalet":"0","postaKodu":"","sehir":"MAGOSA","ulke":"9875"},{"adres":"FENER MAH TEKLİOĞLU CAD KEDİKLİ SİTESİ A BLOK KAT:9 NO:22","adresTipi":{"_value_":"Turkiye"},"eyalet":"0","postaKodu":"","sehir":"ALTALYA","ulke":"9980"}],"sorgulamaTuru":"DIŞ İŞLERİ","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_dis_isleri.ajx';
    }

    async parse(data: BorcluDibCevap): Promise<BorcluDibCevap> {
        data.message = "Kişiye ait Dış İşleri Bakanlığı kayıtlarına göre yurt dışı adres kaydı "  + (data.sorguSonucu ? 'var' : 'yok');
        return data;
    }
}

