export enum HacizEvrakTypeEnum {
    ARAC_HACZI = "AvukatTalepAracHacziDVO",
    TASINMAZ_HACZI = "AvukatTalepGayrimenkulHacziDVO",
    ALACAKLI_OLDUGU_DOSYA = "AvukatTalepAlacakliDosyaDVO",
    BANKA_HACZI = "AvukatTalepBankaHacziDVO",
    POSTA_CEKI_HACZI = "AvukatTalepPostaCekiDVO",
    TASINIR_HACZI = "AvukatTalepGenelTasinirHacziCilingirDVO",
    TASINIR_HACIZ_TALIMATI = "AvukatTalepGenelHacizTalimatDVO",
    IHBARNAME_89_1 = "AvukatTalep89_1DVO",
    IHBARNAME_89_2 = "AvukatTalep89_2DVO",
    IHBARNAME_89_3 = "AvukatTalep89_3DVO",
    MAAS_HACZI = "AvukatTalepMaasHacziDVO"
}