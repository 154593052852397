export enum TaskTuru {
    mts_tebligat_gonder = 1,
    mts_odeme,
    harc_masraf_ode,
    toplu_haciz_hazirla,
    toplu_haciz_gonder,
    toplu_tebligat_hazirla,
    toplu_genel_talep_hazirla,
    evrak_gonder,
    hazir_evrak_gonder,
    makbuz_indir,
    takip_sorgula,
    takip_hazirla,
    takip_ac,
    mts_takip_hazirla,
    mts_takip_ac,
    evrak_indir,
    hizli_takip_ac
}

export enum TaskTuruLabel {
    'MTS Tebligat Gönder'=1,
    'MTS Ödeme',
    'Harc/Masraf Öde',
    'Toplu Haciz Hazırla',
    'Toplu Haciz Gönder',
    'Toplu Tebligat Hazırla',
    'Toplu Genel Talep Hazırla',
    'Evrak Gönder',
    'Hazır Evrak Gönder',
    'Makbuz İndir',
    'Takip Sorgula',
    'Takip Hazırla',
    'Takip Aç',
    'MTS Takip Hazırla',
    'MTS Takip Aç',
    'Evrak İndir',
    'Hızlı Takip Aç'
}