import {UyapRequest} from "./UyapRequest";

export interface AvukatKisiselBilgileriSorgulamaTalep {
}

export interface AvukatKisiselBilgileriSorgulamaCevap {
    avukatBilgileri: {
        avukatId: number,
        kisiKurumId: number,
        tcKimlikNo: string,
        ad: string,
        soyad: string,
        babaAd: string,
        anaAd: string,
        dogumTarihi: string,
        dogumYeri: string,
        mernisGuncellemesiYapildi: boolean,
        isTCGecerli: boolean,
        vekilTipiKod: string,
        baroNo: number,
        tbbNo: string,
        bagliOlduguBaroAdi: string,
        vergiNo: string,
        adresList: Array<any>,
        kamuAvukatiMi: boolean,
        isMock: boolean
    },
    barokartBilgileri: {
        type: string,
        value: string,  //bakiye
        log: string,
    },
    portalBakiye: {
        hasBarokart: boolean,
        sorguToplamIslemSayisi: number,
        sorguKalanIslemSayisi: number,
        ucretsizSorguLimit: number
    },
    sorguBakiye: {
        sorguBakiye: number,
        hasBarokart: boolean,
        sorguToplamIslemSayisi: number,
        sorguKalanIslemSayisi: number,
        ucretsizSorguLimit: number
    }
}

export class AvukatKisiselBilgileriSorgulama extends UyapRequest<AvukatKisiselBilgileriSorgulamaTalep, AvukatKisiselBilgileriSorgulamaCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'GET';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "AvukatKisiselBilgileriSorgulama"
        };
    }

    getMockResponse(): any {
        return [
            {
                "avukatId": 1711111,
                "kisiKurumId": 94011111,
                "tcKimlikNo": "14111111111",
                "ad": "N____",
                "soyad": "E______",
                "babaAd": "M______",
                "anaAd": "A___ N____",
                "dogumTarihi": "11/11/1980",
                "dogumYeri": "ANKARA",
                "mernisGuncellemesiYapildi": false,
                "isTCGecerli": true,
                "vekilTipiKod": "BARO",
                "baroNo": 20111,
                "tbbNo": "78111",
                "bagliOlduguBaroAdi": "ANKARA 1 NOLU BAROSU",
                "vergiNo": "14111111111",
                "adresList": [],
                "kamuAvukatiMi": false,
                "isMock": false
            },
            {
                "type": "success",
                "value": "61.35",
                "log": ""
            },
            {
                "hasBarokart": false,
                "sorguToplamIslemSayisi": 0,
                "sorguKalanIslemSayisi": 0,
                "ucretsizSorguLimit": 0
            },
            {
                "sorguBakiye": 10,
                "hasBarokart": false,
                "sorguToplamIslemSayisi": 0,
                "sorguKalanIslemSayisi": 0,
                "ucretsizSorguLimit": 0
            }
        ];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'avukatKisiselBilgileriSorgula.ajx';
    }

    async parse(data: any): Promise<AvukatKisiselBilgileriSorgulamaCevap> {
        let avukatBilgileri = data[0];
        let barokartBilgileri = data[1];
        let portalBakiye = data[2];
        let sorguBakiye = data[3];
        return {
            avukatBilgileri: avukatBilgileri,
            barokartBilgileri: barokartBilgileri,
            portalBakiye: portalBakiye,
            sorguBakiye: sorguBakiye
        }
    }
}

export default AvukatKisiselBilgileriSorgulama;
