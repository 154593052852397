import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import DigerTalepController from "../../../haciz/DigerTalepController";
import {DigerTalepEvrakTypeEnum} from "../../../uyap/enum/DigerTalepEvrakTypeEnum";
import {TopluTalepGonderTaskCevap} from "./TopluHacizHazirlaTaskHandler";
import {TopluHacizGonderTaskResponse} from "./TopluHacizGonderTaskHandler";
import {StatusEnum} from "../../../enum/StatusEnum";
import {TaskDurum} from "../../../enum/TaskDurum";
import JSZip from "jszip";
import app from "@/main";
import {IKisiTumDVO} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export class TopluDigerTalepGonderTaskHandler extends AbstractTaskHandler {
    params: any[] = [];

    async handle(task: ITask): Promise<ITask> {
        let data: TopluTalepGonderTaskCevap = new TopluTalepGonderTaskCevap(task.data.dosya, task.data.params, task.data.borclu);
        data.dosya = await app.$uyap.DosyaBul().run(task);
        this.params = data.params.digerTalepParams;
        let response: TopluHacizGonderTaskResponse = {
            dosyaEsasNo: data.dosya.dosyaNo,
            icraDairesi: data.dosya.birimAdi,
            message: "",
            evraklar: []
        };
        try {
            let digerTalepController = new DigerTalepController(data.borclu, data.dosya);
            for (const param of this.params) {
                switch (param.className) {
                    case DigerTalepEvrakTypeEnum.DosyaYenileme: {
                        digerTalepController.dosyaYenilemeEkle();
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.TakibinKesinlesmesi: {
                        digerTalepController.takibinKesinlesmesiEkle();
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.IbanGuncelleme: {
                        digerTalepController.ibanGuncellemesiEkle(data.borclu.digerTalep.ibanGuncellemeParams)
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.YuzuncuMaddeyeYararBilgiIstenilmesi: {
                        digerTalepController.yuzuncuMaddeyeYararBilgiIstenilmesiEkle(data.borclu.digerTalep.yuzuncuMaddeyeYararBilgiIstenilmesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.AcizBelgesiHazirlanmasi: {
                        digerTalepController.acizBelgesiHazirlanmasiEkle(data.borclu.digerTalep.acizBelgesiHazirlanmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.AracUzerindekiHacizSerhininKaldirilmasi: {
                        digerTalepController.aracUzerindekiHacizSerhininKaldirilmasiEkle(data.borclu.digerTalep.aracUzerindekiHacizSerhininKaldirilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.AracUzerindekiYakalamaSerhininKaldirilmasi: {
                        digerTalepController.aracUzerindekiYakalamaSerhininKaldirilmasiEkle(data.borclu.digerTalep.aracUzerindekiYakalamaSerhininKaldirilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.BankadanHacizliParaninIstenilmesi: {
                        digerTalepController.bankadanHacizliParaninIstenilmesiEkle(data.borclu.digerTalep.bankadanHacizliParaninIstenilmesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.BorclutarafindanYapilanOdemelerinHesabaAktarilmasi: {
                        digerTalepController.borcluTarafindanYapilanOdemelerinHesabaAktarilmasiEkle(data.borclu.digerTalep.borclutarafindanYapilanOdemelerinHesabaAktarilmasiParams);
                        break;
                    }
                    case  DigerTalepEvrakTypeEnum.DosyaHesabininYapilmasiVeDosyayaKaydedilmesi: {
                        digerTalepController.dosyaHesabininYapilmasiVeDosyayaKaydedilmesiEkle();
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.DosyadaHacizliMalinSatisi: {
                        digerTalepController.dosyadaHacizliMalinSatisiEkle(data.borclu.digerTalep.dosyadaHacizliMalinSatisiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.DosyadakiAvansinIadesi: {
                        digerTalepController.dosyadakiAvansinIadesiEkle(data.borclu.digerTalep.dosyadakiAvansinIadesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.DosyadakiHacizlerinKaldirilmasi: {
                        digerTalepController.dosyadakiHacizlerinKaldirilmasiEkle(data.borclu.digerTalep.dosyadakiHacizlerinKaldirilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.DosyaninIslemdenKaldirilmasi: {
                        digerTalepController.dosyaninIslemdenKaldirilmasiEkle(data.borclu.digerTalep.dosyaninIslemdenKaldirilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.DosyaninYetkiliIcraDairesineGonderilmesi: {
                        digerTalepController.dosyaninYetkiliIcraDairesineGonderilmesiEkle(data.borclu.digerTalep.dosyaninYetkiliIcraDairesineGonderilmesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.HacizliMalinKiymetTakdiriIcinTalimatYazilmasi: {
                        digerTalepController.hacizliMalinKiymetTakdiriIcinTalimatYazilmasiEkle(data.borclu.digerTalep.hacizliMalinKiymetTakdiriIcinTalimatYazilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.HacizliMalinSatisiIcinTalimatYazilmasi: {
                        digerTalepController.hacizliMalinSatisiIcinTalimatYazilmasiEkle(data.borclu.digerTalep.hacizliMalinSatisiIcinTalimatYazilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.HacizliTasinirMallarinMuhafazaAltinaAlinmasi: {
                        digerTalepController.hacizliTasinirMallarinMuhafazaAltinaAlinmasiEkle(data.borclu.digerTalep.hacizliTasinirMallarinMuhafazaAltinaAlinmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.HaricenTahsilatBildirimi: {
                        digerTalepController.haricenTahsilatBildirimiEkle(data.borclu.digerTalep.haricenTahsilatBildirimiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.IIk121MaddeyeGoreYetkiVerilmesi: {
                        digerTalepController.iik121MaddeyeGoreYetkiVerilmesiEkle(data.borclu.digerTalep.iik121MaddeyeGoreYetkiVerilmesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.IIk150cSerhiEklenmesi: {
                        digerTalepController.iik150cSerhiEklenmesiEkle(data.borclu.digerTalep.iik150cSerhiEklenmesiParams);
                        break;
                    }
                    case  DigerTalepEvrakTypeEnum.KiymetTakdirininYapilmasi: {
                        digerTalepController.kiymetTakdirininYapilmasiEkle(data.borclu.digerTalep.kiymetTakdirininYapilmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.MaasUzerindekiHacizlerinKaldirilmesi: {
                        digerTalepController.maasUzerindekiHacizlerinKaldirilmesiEkle(data.borclu.digerTalep.maasUzerindekiHacizlerinKaldirilmesiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi: {
                        digerTalepController.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiEkle(data.borclu.digerTalep.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.RehinAcigiBelgesiHazirlanmasi: {
                        digerTalepController.rehinAcigiBelgesiHazirlanmasiEkle(data.borclu.digerTalep.rehinAcigiBelgesiHazirlanmasiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.RehininParayaCevrilmesiSerhi: {
                        digerTalepController.rehininParayaCevrilmesiSerhiEkle(data.borclu.digerTalep.rehininParayaCevrilmesiSerhiParams);
                        break;
                    }
                    case DigerTalepEvrakTypeEnum.TasinmazHaczininKaldirilmasi: {
                        digerTalepController.tasinmazHaczininKaldirilmasiEkle(data.borclu.digerTalep.tasinmazHaczininKaldirilmasiParams);
                        break;
                    }
                }
            }
            await digerTalepController.hacizEvrakHazirla();
            await digerTalepController.hacizEvrakImzala();
            let evrakResult = await digerTalepController.gonder();
            let borcluKisi = data.borclu.borcluBilgileri as IKisiTumDVO;
            response.evraklar.push({
                message: evrakResult.message,
                status: StatusEnum.success,
                imzaliBlob: digerTalepController.getHacizEvrakImzali(),
                imzasizBlob: digerTalepController.getHacizEvrakImzasiz(),
                adSoyad: borcluKisi.adi + " " + borcluKisi.soyadi,
                tcKimlikNo: borcluKisi.tcKimlikNo,
                evrakTutari: digerTalepController.evrakTutar.toFixed(2),
                params: this.params
            })
        } catch (e) {
            let borcluKisi = data.borclu.borcluBilgileri as IKisiTumDVO;
            response.evraklar.push({
                message: e.message,
                status: StatusEnum.error,
                imzaliBlob: null,
                imzasizBlob: null,
                adSoyad: borcluKisi.adi + " " + borcluKisi.soyadi,
                tcKimlikNo: borcluKisi.tcKimlikNo,
                evrakTutari: 0,
                params: this.params
            });
        }

        let zip = new JSZip();
        for (let evrak of response.evraklar) {
            if (evrak.imzaliBlob) {
                // @ts-ignore
                zip.file((evrak.adSoyad + "/" + evrak.imzaliBlob.name), evrak.imzaliBlob);
            }
        }
        let zipBlob = await zip.generateAsync({type: "blob"});
        let formData = new FormData();
        // @ts-ignore
        let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Toplu Genel Talepleri.zip';
        formData.append('name', name);
        formData.append('file', zipBlob);
        formData.append('task_id', (task.id as number).toString());
        await app.$http.post('/api/v1/task/file', formData);

        let errorExists = response.evraklar.filter(evrak => evrak.status == StatusEnum.error);
        task.status = TaskDurum.BITTI_BASARILI;
        if (errorExists.length == response.evraklar.length)
            task.status = TaskDurum.BITTI_HATALI;
        else if (errorExists.length > 0)
            task.status = TaskDurum.BITTI_EKSIK;
        task.response = response;
        task.description = errorExists.map(x => x.message).join(" , ");
        if (response.message != "")
            task.description = response.message;
        return task;
    }
}