import {UyapRequest} from "./UyapRequest";

export interface DosyaTaraflarTalep {
    dosyaId: string
}

export type DosyaTaraflarCevap = Array<ITarafBilgisi>

export interface ITarafBilgisi {
    adi: string,
    rol: string,
    vekil: string,
    kisiKurum: string
}

export class DosyaTaraflar extends UyapRequest<DosyaTaraflarTalep, DosyaTaraflarCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaTaraflar_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): any {
        return [{"adi":"ÖZLEM ÖĞÜNÜK","rol":"Davacı","vekil":"[HANİFE YILDIRIM]","kisiKurum":"Kişi"},{"adi":"AFET VE ACİL DURUM YÖNETİMİ BAŞKANLIĞI","rol":"Davalı","vekil":"[TÜLAY ŞEN]","kisiKurum":"Kurum"},{"adi":"BAYRAKLI BELEDİYE BAŞKANLIĞI","rol":"Davalı","vekil":"[GÜNSEL SAĞLAM]","kisiKurum":"Kurum"},{"adi":"BORNOVA BELEDİYE BAŞKANLIĞI","rol":"Davalı","vekil":"[SAADET ÇAMİÇİ]","kisiKurum":"Kurum"},{"adi":"ÇEVRE, ŞEHİRCİLİK VE İKLİM DEĞİŞİKLİĞİ BAKANLIĞI","rol":"Davalı","vekil":"[NİHAN ERDOĞAN]","kisiKurum":"Kurum"},{"adi":"İZMİR BÜYÜKŞEHİR BELEDİYE BAŞKANLIĞI","rol":"Davalı","vekil":"[HAKAN OYAR]","kisiKurum":"Kurum"}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosya_taraf_bilgileri_brd.ajx';
    }

    async parse(data: any): Promise<DosyaTaraflarCevap> {
        return data;
    }

}

export default DosyaTaraflar;
