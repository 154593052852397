export enum OdemeTipiEnum {
    Barokart = "2",
    Vakifbank = "4"
}

export interface OdemeTipi {
    name: string,
    value: string
}

/*
    odemeTipiArray :OdemeTipi[] = [
        {
            name:"Barokart",
            value:"2"
        },
        {
            name :"Vakıfbank",
            value: "4"
        }
    ];
 */
