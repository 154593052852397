
import {Vue, Component, Watch} from "vue-property-decorator";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {}
})
export default class UrunlerInfo extends Vue {
  urunler = [
    {
      id: 1,
      isim: "OfisPro",
      logo: require("../../assets/cloudicon.svg"),
      color: "",
      color2: "black",
      btnColor: "white",
      route: ProgramPaths.ofispro,
      secili: false,
      authorizationKey: "ofis-pro",

    },
    {
      id: 2,
      isim: "İcraPro",
      logo: require("../../assets/icraprooldicon.svg"),
      color: "blue--text",
      color2: "blue",
      btnColor: "white",
      route: ProgramPaths.icrapro,
      secili: false,
      authorizationKey: "icra-pro",
    },
    {
      id: 3,
      isim: "e-İcraPro",
      logo: require("../../assets/eicraproicon.png"),
      color: "amber--text text--darken-4",
      color2: "amber darken-4",
      btnColor: "white",
      route: ProgramPaths.eicrapro,
      secili: false,
      authorizationKey: "eicra-pro",

    },
    {
      id: 4,
      isim: "DavaProWeb",
      logo: require("../../assets/davaprowebicon.svg"),
      color: "deep-orange--text text--darken-4",
      color2: "deep-orange darken-4",
      btnColor: "white",
      route: ProgramPaths.davapro,
      secili: false,
      authorizationKey: "dava-pro",

    },
    {
      id: 5,
      isim: "ArabulucuPro",
      logo: require("../../assets/arabulucuproicon.svg"),
      color: "light-blue--text text--darken-4",
      color2: "light-blue darken-4",
      btnColor: "white",
      route: ProgramPaths.arabulucupro,
      secili: false,
      authorizationKey: "arabulucu-pro",

    },
  ];

  secimiDegis(urun: any) {
    this.urunler.forEach((x: any) => {
      x.secili = false;
    });
    urun.secili = true;
  }

  getRoute(id: number) {
    return this.urunler[id - 1].route;
  }

  get logo() {
    let urunPath = this.$route.path.split('/')[1];
    if ('/' + urunPath == ProgramPaths.ofispro)
      return this.urunler[0].logo;
    else if ('/' + urunPath == ProgramPaths.icrapro)
      return this.urunler[1].logo;
    else if ('/' + urunPath == ProgramPaths.eicrapro)
      return this.urunler[2].logo;
    else if ('/' + urunPath == ProgramPaths.davapro)
      return this.urunler[3].logo;
    else if ('/' + urunPath == ProgramPaths.arabulucupro)
      return this.urunler[4].logo;
    else
      return this.urunler[0].logo;
  }

  mounted() {
    if (this.$store.getters.isOfisPro)
      this.urunler[0].secili = true;
    if (this.$store.getters.isIcraPro)
      this.urunler[1].secili = true;
    if (this.$store.getters.isEicraPro)
      this.urunler[2].secili = true;
    if (this.$store.getters.isDavaPro)
      this.urunler[3].secili = true;
    if (this.$store.getters.isArabulucuPro)
      this.urunler[4].secili = true;
  }
}
