
import {Component, Mixins} from 'vue-property-decorator';
import FormWrapper from "@/components/FormWrapper.vue";
import Password from "@/components/inputs/Password.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {SifreDegistirEntity} from "@/entity/SifreDegistirEntity";
import _ from "lodash"

@Component({
  components: {Password, FormWrapper}
})
export default class SifreDegistirForm extends Mixins(ObjectInputMixin) {
  localValue!: SifreDegistirEntity;

  get newPassRule(): string | boolean {
    if (this.localValue.new_password) {
      if (this.localValue.new_password.length >= 9) {
        return true;
      } else
        return 'Şifre en az 9 karakter olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  get newPassAgainRule(): string | boolean {
    if (this.localValue.new_password_again) {
      if (this.localValue.new_password_again == this.localValue.new_password) {
        return true;
      } else
        return 'Şifreler eşlemiyor.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  save() {
    let data = _.cloneDeep(this.localValue);
    delete data.new_password_again
    this.$http.post(this.$store.state.ssoUri + 'api/change-password', data).then(() => {
      this.$toast.success('Şifre başarıyla değiştirildi.');
      this.$emit('success');
    });
  }
}
