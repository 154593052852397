import {UyapRequest} from "./UyapRequest";
import {MDB} from "../MDB";
import app from "@/main";

export interface AvukatSorgulamaTalep {
    tcKimlikNo: string,
    baroNo: string,
    tbbNo: string,
    ilKodu: string,
    baroKodu: string,
}

export interface AvukatSorgulamaCevap {
    ad: string,
    soyad: string,
    avukatId: number,
    tcKimlikNo: number,
    isKamuAvukati: boolean,
    durum: number
}

export default class AvukatSorgulama extends UyapRequest<AvukatSorgulamaTalep, AvukatSorgulamaCevap> {
    delayTime = 0;

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "avukatsorgulama_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    async parse(data: any): Promise<AvukatSorgulamaCevap> {
        return data;
    }

    async recursive(data: AvukatSorgulamaTalep): Promise<AvukatSorgulamaCevap> {
        return new Promise(async (resolve, reject) => {
            this.delayTime = 3000;
            data.tcKimlikNo = data.tcKimlikNo.toString().trim();
            console.log("AvukatSorgulamaTalep DATA ==", data)
            let rejectMessage = {"message": "Vekil avukat bulunamadı"};
            if (app.$uyapHelper.tcKimlikNoValidate(data.tcKimlikNo)) {
                try {
                    let avukat = await this.run({
                        tcKimlikNo: data.tcKimlikNo,
                        baroNo: "",
                        tbbNo: "",
                        ilKodu: "",
                        baroKodu: ""
                    })
                    return resolve(avukat);
                } catch (e) {
                    //return reject(e);
                }
            }
            if (data.tbbNo != "") {
                try {
                    let avukat = await this.run({
                        tcKimlikNo: "",
                        baroNo: "",
                        tbbNo: data.tbbNo,
                        ilKodu: "",
                        baroKodu: ""
                    })
                    return resolve(avukat);
                } catch (e) {
                    //return reject(e);
                }
            }
            if (data.baroNo != "" && data.ilKodu != "") {
                let barolar = MDB.baroKodlari.filter((i) => i.ilKodu == data.ilKodu);
                let avukat: AvukatSorgulamaCevap;
                for (const baro of barolar) {
                    try {
                        let requestPayload = {
                            tcKimlikNo: "",
                            baroNo: data.baroNo,
                            tbbNo: "",
                            baroKodu: baro.baroKodu,
                            ilKodu: data.ilKodu
                        }
                        avukat = await this.run(requestPayload)
                        if (avukat.ad && avukat.ad != "")
                            return resolve(avukat);
                    } catch (e) {
                        console.log("CATCH ==== BARKO KODI", e);
                        // eğer patladığında devam etmesini istiyorsan alttakini dene @abdullah
                        // continue;
                    }
                }
                //return reject(rejectMessage);
            }
            return reject(rejectMessage);
            //EUP - 403
        })

    }

    getMockResponse(): any {
        return {"ad":"NİHAN","soyad":"ERDOĞAN","avukatId":1746688,"tcKimlikNo":"14537825364","isKamuAvukati":false,"durum":0};
    }

    getUrl(): string {
        return this.ImzaClientAddr + "avukat_sorgulama.ajx";
    }

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }
}


