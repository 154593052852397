// @ts-nocheck
/*
@author Yunus Emre Çalışkan
* emrecaliskann@outlook.com
* HTML to  UDF Converter
* 23.11.2018
* Requires
* JSZIP
* SaveAs (FileSaver)
* Kullanım şekli
* Arg html String html dosyası.
* Arg fileName String indirilecek dosyaya verilecek isim.
* var udf = new htmlToUdf();
* udf.getUdfBlob(html) // blob olarak return verir
* udf.downloadUdf(html,fileName) // udf dosyasina cevirir ve indirir.
 */
import JSZip from "jszip";
import {saveAs} from 'file-saver';

export class HtmlToUdf {
    elements: string;
    content: string;
    offset: number;
    arr: Array<any>;

    constructor() {
        this.elements = '';
        this.content = '';
        this.offset = 0;
        this.arr = [];
    }

    destructor() {
        this.elements = '';
        this.content = '';
        this.offset = 0;
        this.arr = [];
    }

    getUdfBlob(html: HTMLElement): Promise<Blob> {
        return this.init(html);
    }

    async downloadUdf(html: HTMLElement, fileName: string) {
        await this.init(html, fileName, true);
    }

    init(html: HTMLElement, fileName = "dilekce", download = false): Promise<Blob> {
        let myDom = document.createElement("content");
        myDom.innerHTML = "<root>" + html + "</root>";
        return this.initalize(myDom, fileName, download);
    }

    findColumnCount(node: HTMLElement) {
        if (node.querySelector("tbody"))
            node = node.querySelector("tbody");
        let trs = node.children;
        let count = 0;
        let ram = "";
        for (let j = 0; j < trs.length; j++) {
            let say = 0;
            let td = trs[j].children;
            let columnSpans = "";
            for (let i = 0; i < td.length; i++) {
                if (td[i].nodeName == "TD") {
                    say++;
                    if (td[i].style.width != "") {
                        columnSpans += td[i].style.width + " ";
                    } else {
                        if (td[i].getAttribute("width") != null) {
                            columnSpans += td[i].getAttribute("width") + " ";
                        }
                    }
                }
                if (say > count) {
                    count = say;
                    ram = columnSpans.trim().replace(/\ /g, ",").replace(/px/g, "").replace(/\%/g, "");
                }
            }
        }
        if (ram == "") {
            ram = this.columnSpans("100", count);
        }
        return {count: count, columnSpans: ram};
    }

    columnSpans(text: string, count: number) {
        let newText = text;
        for (let i = 0; i < count - 1; i++) {
            newText += "," + text;
        }
        return newText;
    }

    createParagraphWithContent(node: HTMLElement, size = "") {
        let alignmentMove = 0;
        if (((node.innerText.length) - (node.innerText.trim().length)) >= 10) {
            alignmentMove = 1;
        }
        if (node.nodeName == "LI") {
            return {
                open: "<paragraph " + this.getAttributes(node, alignmentMove) + " Bulleted='true' BulletType='BULLET_TYPE_ELLIPSE' ListLevel='1' ListId='1' LeftIndent='25.0'>" + this.createContent(node, size, alignmentMove),
                close: "</paragraph>"
            };
        }
        return {
            open: "<paragraph " + this.getAttributes(node, alignmentMove) + " resolver='hvl-default' LeftIndent='1.0' RightIndent='1.0'>" + this.createContent(node, size, alignmentMove),
            close: "</paragraph>"
        };
    }

    emptyParagraph(node: HTMLElement) {
        let data = node.children;
        for (let i = 0; i < data.length; i++) {
            if (this.backTagControl(data[i].nodeName)) {
                return true;
            }
        }
        return false;
    }

    parser(node: HTMLElement, rowCount: number) {
        switch (node.nodeName) {
            case "TABLE":
                let columnSpec = this.findColumnCount(node);
                return {
                    open: "<table tableName='Sabit' columnCount='" + columnSpec.count + "' columnSpans='" + columnSpec.columnSpans + "' border='borderCell' borderSpec=\"31\" borderColor=\"-16777216\" borderStyle=\"borderStyle-plain\" borderWidth=\"1.0\" >",
                    close: "</table>"
                };
            case "TBODY":
                return {open: "", close: ""};
            case "TR":
                let rC = "row" + rowCount;
                return {
                    open: "<row rowName='" + rC + "'  rowType='dataRow' height='0.0' border=\"borderNone\" borderWidth=\"0.5\" >",
                    close: "</row>"
                };
            case "TD":
                if (node.childElementCount == 0 || this.emptyParagraph(node) == false) {
                    let p = this.createParagraphWithContent(node);
                    return {open: "<cell>" + p.open, close: p.close + "</cell>"};
                }
                return {open: "<cell>", close: "</cell>"};
            case "H1":
                return this.createParagraphWithContent(node);
            case "H2":
                return this.createParagraphWithContent(node);
            case "H3":
                return this.createParagraphWithContent(node);
            case "H4":
                return this.createParagraphWithContent(node);
            case "H5":
                return this.createParagraphWithContent(node);
            case "H6":
                return this.createParagraphWithContent(node);
            case "LI":
                return this.createParagraphWithContent(node);
            case "P":
                return this.createParagraphWithContent(node);
            case "SPAN":
                return {open: this.createContent(node), close: ""};
            case "STRONG":
                return {open: this.createContent(node), close: ""};
            case "I":
                return {open: this.createContent(node), close: ""};
            case "B":
                return {open: this.createContent(node), close: ""};
            case "U":
                return {open: this.createContent(node), close: ""};
            default:
                return {open: "", close: ""};
        }
    }

    createContent(node: HTMLElement, size = "", alignmentMove = 0) {
        let data = this.parseContent(node);
        let attributes = "";
        //console.log("length",data.text.length , "text" , data.text);
        if (alignmentMove == 1 || ((data.text.length) - (data.text.trim().length) >= 10)) {
            data.text = data.text.trim();
            if (data.attributes.indexOf("Alignment") < 0) {
                data.attributes = this.textAlign("", 1) + data.attributes;
            } else {
                data.attributes = data.attributes.replace("Alignment='0'", "Alignment='1'").replace("Alignment='2'", "Alignment='1'");
            }
        }
        let parentAttr = this.getAttributes(node.parentElement);
        let parentParentAttr = this.getAttributes(node.parentElement.parentElement);
        data.attributes = this.cleanDuplicateAttr(data.attributes + parentAttr + parentParentAttr);
        this.content += String(data.text);
        let textLen = 1;
        if (data.text != null) {
            textLen = data.text.length;
        }
        let startOffset = this.offset;
        if (node.nodeName == "P" || node.nodeName == "TD") {
            this.content += "\n";
            textLen++;
        }
        this.offset += textLen;
        if (size != "") {
            attributes = size + data.attributes + " startOffset='" + startOffset + "' length='" + textLen + "' ";
        } else {
            attributes = data.attributes + "resolver='hvl-default' LeftIndent='3.0' RightIndent='1.0' startOffset='" + startOffset + "' length='" + textLen + "' ";
        }
        let contentDom = "<content " + attributes + " />";
        return contentDom;
    }

    fontSizeString(size: string) {
        switch (size) {
            case "xx-small":
                return "8";
            case "x-small":
                return "10";
            case "small":
                return "12";
            case "medium":
                return "14";
            case "large":
                return "18";
            case "x-large":
                return "24";
            case "xx-large":
                return "36";
            default:
                return size;
        }
    }

    size(node: HTMLElement) {
        if (node.style.fontSize != "") {
            let size = node.style.fontSize.replace("px", "");
            size = this.fontSizeString(size);
            size = parseInt(parseInt(size) * 0.75);//px to punto
            return " size='" + size + "' ";
        }
        switch (node.nodeName) {
            case "H1":
                return "size='16' bold='true' ";
            case "H2":
                return "size='14' bold='true' ";
            case "H3":
                return "size='12' bold='true' ";
            case "H4":
                return "size='10' bold='true' ";
            case "H5":
                return "size='8' bold='true' ";
            case "H6":
                return "size='6'  bold='true' ";
            default:
                return " ";
        }
    }

    bold(node: HTMLElement) {
        let attribute = " bold='true' ";
        if (node.nodeName == "STRONG" || node.nodeName == "B" || node.style.fontWeight == "bold" || node.style.fontWeight == "bolder") {
            return attribute;
        }
        return "";
    }

    overline(node: HTMLElement) {
        let attribute = " underline='true' ";
        if (node.style.textDecoration == "overline") {
            return attribute;
        }
        return "";
    }

    underline(node: HTMLElement) {
        let attribute = " underline='true' ";
        if (node.nodeName == "U" || node.style.textDecoration == "underline") {
            return attribute;
        }
        return "";
    }

    italic(node: HTMLElement) {
        let attribute = " italic='true' ";
        if (node.nodeName == "I" || node.style.textDecoration == "italic") {
            return attribute;
        }
        return "";
    }

    textAlign(node: HTMLElement, nbsp: any) {
        if (nbsp)
            return " Alignment='1' ";
        let align = node.style.textAlign;
        switch (align) {
            case "left":
                return " Alignment='0' ";
            case "center":
                return " Alignment='1' ";
            case "right":
                return " Alignment='2' ";
            case "justify":
                return "";
            default:
                return " Alignment='0' ";
        }
    }

    cleanDuplicateAttr(attrs: any) {
        attrs = attrs.split(" ");
        let text = "";
        let notSearch = ["resolver", "LeftIndent", "RightIndent"];
        let cleanAttr = [];
        attrs.forEach(function (val) {
            let control = false;
            for (let i = 0; i < notSearch.length; i++) {
                if (val.indexOf(notSearch[i]) >= 0)
                    control = true;
            }

            if (control == false) {
                let search = val.split("=")[0];
                let cleanControl = false;
                for (let j = 0; j < cleanAttr.length; j++) {
                    if (cleanAttr[j].indexOf(search) >= 0)
                        cleanControl = true;
                }
                if (cleanControl == false) {
                    cleanAttr.push(val);
                    text += val + " ";
                }
            }
        })
        return text;
    }

    getAttributes(node: HTMLElement, nbsp: any) {
        let attributes =
            this.size(node) +
            this.textAlign(node, nbsp) +
            this.bold(node) +
            this.italic(node) +
            this.underline(node) +
            this.overline(node);
        return attributes;
    }

    parseContent(node: HTMLElement) {
        let text = "";
        if (node.childElementCount == 0)
            text = node.firstChild;
        if (text == null)
            text = "";
        if (typeof text == "object") {
            text = text.nodeValue;
        }
        let attributes = this.getAttributes(node);
        return {attributes: attributes, text: text};
    }

    recursion(child: any) {
        let rowCount = 0;
        for (let i = 0; i < child.childElementCount; i++) {
            let node = child.children[i];
            if (node.nodeName == "TABLE")
                rowCount = 0;
            else
                rowCount++;
            let parsed = this.parser(node, rowCount);
            this.elements += parsed.open;
            this.recursion(node);
            this.elements += parsed.close;

        }
    }

    stringToXMLDom(string: string) {
        let xmlDoc = null;
        if (window.DOMParser) {
            let parser = new DOMParser();
            xmlDoc = parser.parseFromString(string, "text/xml");
        } else // Internet Explorer
        {
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = "false";
            xmlDoc.loadXML(string);
        }
        return xmlDoc;
    }

    initalize(dom: any, fileName: string, download: boolean): Promise<Blob> {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < dom.children.length; i++) {
                let child = dom.children[i];
                let rec = this.recursion(child);
            }
            let myElem = document.createElement("elements");
            myElem.innerHTML = this.elements;
            this.content += "\n\n";
            this.elements += " <paragraph resolver=\"hvl-default\" LeftIndent=\"0.0\" RightIndent=\"0.0\">" +
                "<content startOffset=\"" + this.offset + "\" length=\"1\" />" +
                "</paragraph>\n";
            let content = "<content><![CDATA[" + this.content + "]]></content>";

            let xml = "<?xml version=\"1.0\" encoding=\"UTF-8\" ?> \n" +
                "\n" +
                "<template format_id=\"1.7\" >\n" +
                content +
                "<properties><pageFormat mediaSizeName=\"1\" leftMargin=\"70.875\" rightMargin=\"70.875\" topMargin=\"70.875\" bottomMargin=\"70.875\" paperOrientation=\"1\" headerFOffset=\"20.0\" footerFOffset=\"20.0\" /></properties>\n" +
                "<elements >\n" + this.elements.replace(/\'/g, '"') + "</elements>\n" +
                "<styles><style name=\"default\" description=\"Geçerli\" family=\"Lucida Grande\" size=\"13\" bold=\"false\" italic=\"false\" foreground=\"-16777216\" FONT_ATTRIBUTE_KEY=\"com.apple.laf.AquaFonts$DerivedUIResourceFont[family=Lucida Grande,name=Lucida Grande,style=plain,size=13]\" /><style name=\"hvl-default\" family=\"Times New Roman\" size=\"12\" description=\"Gövde\" /></styles>\n" +
                "</template>\n";
            let zip = new JSZip();
            zip.file("content.xml", xml);
            zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 6
                }
            })
                .then((content) => {
                    if (download) {
                        saveAs(content, fileName + ".udf");
                    }
                    let blob = new Blob([content], {type: "application/octet-stream"});//udf type ına çeviriliyor.
                    //content.type="application/octet-stream";
                    console.log("UDF CONVERTING BLOB", blob);
                    return resolve(blob);
                }).catch((err) => {
                return reject(err);
            });
        })
    }

    frontTagControl(tag: string) {
        switch (tag) {
            case "SPAN":
                return true;
            case "STRONG":
                return true;
            case "B":
                return true;
            case "I":
                return true;
            case "U":
                return true;
            default:
                return false;
        }
    }

    backTagControl(tag: string) {
        switch (tag) {
            case "H1":
                return true;
            case "H2":
                return true;
            case "H3":
                return true;
            case "H4":
                return true;
            case "H5":
                return true;
            case "H6":
                return true;
            case "P":
                return true;
            case "DIV":
                return true;
            default:
                return false;
        }
    }
}
