import {Mixins} from "../../Mixins";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export class MtsParaBirimi {
    tktId: string = "PRBRMTL";
    kod: string = "TL";
}

export class AlacakKalemKod {
    name!: string;
    value!: any;
}

export class MtsTemelBilgiler {
    tutarTL: string = "";//"82.90",
    selectedParaBirimi: MtsParaBirimi = new MtsParaBirimi();
    KDV: boolean = false;
    aciklama: string = "";//"ASIL ALACAK ",
    selectedTarihTuru: string = "";
    detayliAciklama: string = "";
    selectedAlacakKalemKodu: AlacakKalemKod = new AlacakKalemKod();
}

export class MtsSelectedFaizBilgisi {
    tktId: string = "";
    kod: string = "";
    aciklama: string = "";
    kodTuru: string = "";
}

export class MtsAlacakKalemFaizBilgileri {
    selectedFaizTuru: MtsSelectedFaizBilgisi = new MtsSelectedFaizBilgisi();
    faizOraniTL: any = '';
    faizOraniKurus: number = 0;
    selectedFaizSureTipi: {
        id: string,
        adi: string
    } = {
        id: "",
        adi: ""
    }
}

export default class MtsAlacakKalem {
    selectedTarafHashKeyList: string[] = [];//["kurum_0", "kisi_0"],
    temelBilgiler: MtsTemelBilgiler = new MtsTemelBilgiler();
    faizBilgileri: MtsAlacakKalemFaizBilgileri | any = new MtsAlacakKalemFaizBilgileri();

    static parse(alacakKalemiNode: Element, tarafList: Array<TarafKisi | TarafKurum> = [], digerAlacakAciklama: any = null): MtsAlacakKalem | null {

        console.log("ALACAK     KALEMI BEGIN digerAlacakAciklama", digerAlacakAciklama)

        let mtsAlacakKalemi = new MtsAlacakKalem();
        let alacakKalemKodTuru = alacakKalemiNode.getAttribute("alacakkalemkodturu");
        mtsAlacakKalemi.parseAndSetselectedTarafHashKeyList(alacakKalemiNode, tarafList);
        let alacakKalemiTutar: any =
            Mixins.convertAlacakKalemTutar(
                Mixins.replaceCommaDots(alacakKalemiNode.getAttribute("alacakkalemtutar"))
            );
        if (alacakKalemiTutar <= 0)
            return null;
        mtsAlacakKalemi.parseAndSetTemelBilgiler(alacakKalemiNode);
        mtsAlacakKalemi.parseAndSetFaizBilgileri(alacakKalemiNode);
        //SABUNLAMALAR =
        if (mtsAlacakKalemi.temelBilgiler.selectedAlacakKalemKodu.name == "")
            mtsAlacakKalemi.temelBilgiler.selectedAlacakKalemKodu.name = mtsAlacakKalemi.temelBilgiler.aciklama; //SABUN EUP 230
        if (digerAlacakAciklama != null && alacakKalemKodTuru == "1")
            mtsAlacakKalemi.temelBilgiler.aciklama = digerAlacakAciklama; // SABUN EUP 230
        //END OF SABUNLAMALAR =
        return mtsAlacakKalemi;
    }

    parseAndSetselectedTarafHashKeyList(alacakKalemiDomNode: Element, tarafList: Array<TarafKisi | TarafKurum>) {
        alacakKalemiDomNode.querySelectorAll('ref').forEach((refDomNode) => {
            let refAttributeValue = refDomNode.getAttribute('id');
            let taraf = tarafList.find((tarafController) => tarafController.refId == refAttributeValue);
            console.log("TARAF", taraf);
            if (taraf) {
                let hashText, index;
                if (taraf.isKisi()) {
                    let kisiList = tarafList.filter((taraf) => taraf.isKisi() === true);
                    hashText = "kisi_";
                    index = kisiList.indexOf(taraf);
                } else {
                    let kurumList = tarafList.filter((taraf) => taraf.isKurum() === true);
                    hashText = "kurum_";
                    console.log("kurumList", kurumList);
                    index = kurumList.indexOf(taraf);
                }
                hashText += index;
                console.log("HASH TEXT", hashText);
                console.log("TEXT index", index);
                this.selectedTarafHashKeyList.push(hashText)
            }
        });
    }

    parseAndSetTemelBilgiler(alacakKalemiNode: Element) {

        this.temelBilgiler.tutarTL = Mixins.replaceCommaDots(alacakKalemiNode.getAttribute("alacakkalemtutar"));
        this.temelBilgiler.selectedParaBirimi.tktId = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("tutartur"));
        this.temelBilgiler.selectedParaBirimi.kod = "TL";

        this.temelBilgiler.detayliAciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("aciklama"));
        this.temelBilgiler.aciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemadi"));//alacakkalemkodaciklama
        console.log("this.temelBilgiler.aciklama ", this.temelBilgiler.aciklama);
        /*if(this.temelBilgiler.aciklama == "")
            this.temelBilgiler.aciklama = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemadi"));
         */

        this.temelBilgiler.selectedAlacakKalemKodu.name = Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkodaciklama")); //this.temelBilgiler.aciklama;
        this.temelBilgiler.selectedAlacakKalemKodu.value = parseInt(Mixins.nullUndefCleaner(alacakKalemiNode.getAttribute("alacakkalemkod")));
    }

    parseAndSetFaizBilgileri(alacakKalemiNode: Element) {
        let faizNode = alacakKalemiNode.querySelector('faiz');
        if (typeof faizNode != "undefined" && faizNode) {//faiz varsa
            let faizSureTip = Mixins.nullUndefCleaner(faizNode.getAttribute("faizsuretip"));
            let faizOran = Mixins.replaceCommaDots(faizNode.getAttribute("faizoran"));
            let oranlar = faizOran.split(".");
            let oranTl = oranlar[0];
            let oranKurus = oranlar[1];
            if (oranKurus == undefined)
                oranKurus = "00";
            let faizTipKod = Mixins.nullUndefCleaner(faizNode.getAttribute("faiztipkod"));

            this.faizBilgileri.selectedFaizTuru.tktId = faizTipKod;
            this.faizBilgileri.selectedFaizTuru.aciklama = Mixins.nullUndefCleaner(faizNode.getAttribute("faiztipkodaciklama"));
            this.faizBilgileri.selectedFaizTuru.kod = Mixins.onlyDigit(faizTipKod)
            this.faizBilgileri.selectedFaizTuru.kodTuru = Mixins.onlyLetters(faizTipKod);

            this.faizBilgileri.selectedFaizSureTipi.id = faizSureTip;
            this.faizBilgileri.selectedFaizSureTipi.adi = Mixins.faizSureTipConverter(faizSureTip);

            this.faizBilgileri.faizOraniTL = oranTl.length > 0 ? parseInt(oranTl) : "";
            this.faizBilgileri.faizOraniKurus = parseInt(oranKurus);

        } else {
            this.faizBilgileri = {};
        }
    }

    toUyapJson() {
        return this;
    }
}

