import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "./CommonInterfaces";
import app from "@/main";

export interface DosyaBorcluMersisTalep {
    dosyaId: string,
    kisiKurumId: number
}

export interface DosyaBorcluMersisCevap extends ISorguSonuc<string>{
}

export default class DosyaBorcluMersis extends UyapRequest<DosyaBorcluMersisTalep, DosyaBorcluMersisCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaBorcluMersis_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiKurumId":179748519,"kurumDVO":{"kurumAdi":" GÜ*** YA** İZ******* İN**** SA**** Tİ***** Lİ***** Şİ*****","vergiNo":"4350496749","veriGelisYeri":0,"faaliyetDurumu":-1,"tacirMi":"H","mersisIlKodu":0,"mersisIlceKodu":0},"turu":1},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 4. Genel İcra Dairesi","birimId":"1000954","orgKodu":"1.04.011.000.6039","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6039","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/91045","dosyaNoYil":2023,"dosyaSira":91045,"dosyaId":"Z+58wAieKla4f4N+OHThpiyj69vOcT3G2Cwb0AHF6N6w7+c9@AjjQ9Nu9jbAmRCi","dosyaAcilisTarihi":"05/09/2023 01:07","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"GÜNEY YAPI İZOLASYON İNŞAAT SANAYİ TİCARET LİMİTED ŞİRKETİ","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"25/04/2024 11:39","sorguSonucDVO":"ACIBADEM MAHALLESİ ASAFBEY SK.  NO: 7 A  KADIKÖY/İSTANBUL","sorgulamaTuru":"Mersis","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_mersis.ajx';
    }

    async parse(data: any): Promise<DosyaBorcluMersisCevap> {
        data.message = "Kuruma ait MERSİS kayıtlarına göre adres kaydı " + (data.sorguSonucu ? 'var' : 'yok');
        return data;
    }

}

