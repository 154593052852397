export default class Ilamsiz {
    sortArray(array: Array<any>, key: any) {
        array.sort((a, b) => {
            if (typeof a[key] == 'undefined' || typeof b[key] == 'undefined' || a[key] == null || b[key] == null) {
                return 0;
            }
            let aDateArr = a[key].split('/');
            let bDateArr = b[key].split('/');

            if (aDateArr.length != 3 || bDateArr.length != 3) {
                return 0;
            }

            let aDate = new Date(aDateArr[2], aDateArr[1], aDateArr[0]);
            let bDate = new Date(bDateArr[2], bDateArr[1], bDateArr[0]);
            if (aDate > bDate) {
                return 1;
            }
            if (aDate < bDate) {
                return -1;
            }
            return 0;
        });
    }

}
