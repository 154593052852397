import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";

export interface DosyaHesapBilgileriTalep {
    dosyaId: string
}

export type DosyaHesapBilgileriCevap = Array<IDosyaHesapBilgisi>

export interface IDosyaHesapBilgisi {
    textAlan: string,
    degerAlan?: number,
    grupId: number
}

export class DosyaHesapBilgileri extends UyapRequest<DosyaHesapBilgileriTalep, DosyaHesapBilgileriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"textAlan":"Takipte Kesinlesen Miktar","degerAlan":9314.16,"grupId":1},{"textAlan":"Basvurma Harci","grupId":1},{"textAlan":"Toplam Faiz Miktari","degerAlan":3111.77,"grupId":1},{"textAlan":"Vekalet Ücreti","degerAlan":3600,"grupId":1},{"textAlan":"Masraf Miktari","degerAlan":43.3,"grupId":1},{"textAlan":"BSMV Miktari","grupId":1},{"textAlan":"KKDF Miktari","grupId":1},{"textAlan":"KDV Miktari","grupId":1},{"textAlan":"Tahsil Harcı","degerAlan":423.79,"grupId":1},{"textAlan":"Özel Iletisim Vergisi","grupId":1},{"textAlan":"Toplam Alacak","degerAlan":16493.02,"grupId":1},{"textAlan":"Yatan Para","degerAlan":0,"grupId":2},{"textAlan":"Bakiye Borç Miktari","degerAlan":16493.02,"grupId":3}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosya_hesap_bilgileri.ajx';
    }

    getResponseType(): ResponseType {
        return 'json';
    }

    async parse(data: any): Promise<DosyaHesapBilgileriCevap> {
        return data;
    }

}

export default DosyaHesapBilgileri;
