import AvukatIcraDosyalari from "./AvukatIcraDosyalari";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";
import {
    TamamlanmayanDosyalar,
    TamamlanmayanDosyalarRow,
    TamamlanmayanDosyaTurKod
} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyalar";

export class TamamlanmayanDosyaBul {
    err: any = {};
    tamamlanmayanDosyalar: Array<TamamlanmayanDosyalarRow> = []

    constructor() {
        this.err = {message: "Dosya Bulunamadı."};
    }

    async getMockResponse(): Promise<IAvukatDosya> {
        let adsg = new TamamlanmayanDosyalar();
        let data = await adsg.parse(adsg.getMockResponse());
        // @ts-ignore
        return data[0];
    }

    run(dosya: { birimId: string, acilisTime: number }, isMTS: boolean = false): Promise<TamamlanmayanDosyalarRow> {
        return new Promise(async (resolve, reject) => {
            try {
                if (isMTS)
                    this.tamamlanmayanDosyalar = await new TamamlanmayanDosyalar().run({dosyaTurKod: TamamlanmayanDosyaTurKod.MTS});
                else
                    this.tamamlanmayanDosyalar = await new TamamlanmayanDosyalar().run({dosyaTurKod: TamamlanmayanDosyaTurKod.NORMAL});
                let bulunanDosya = this.tamamlanmayanDosyalar.find(tamamlanmayanDosya => (tamamlanmayanDosya.birimID == dosya.birimId && new Date(tamamlanmayanDosya.dosyaAcilisTarihi).getTime() == dosya.acilisTime))
                if (!bulunanDosya)
                    throw new Error('Dosya Bulunamadı.')
                return resolve(bulunanDosya);
            } catch (e) {
                return reject(e);
            }
        });
    }

    runToplu(dosyalar: Array<{
        birimId: string,
        acilisTime: number
    }>, isMTS: boolean = false): Promise<Array<TamamlanmayanDosyalarRow>> {
        return new Promise(async (resolve, reject) => {
            try {
                if (isMTS)
                    this.tamamlanmayanDosyalar = await new TamamlanmayanDosyalar().run({dosyaTurKod: TamamlanmayanDosyaTurKod.MTS});
                else
                    this.tamamlanmayanDosyalar = await new TamamlanmayanDosyalar().run({dosyaTurKod: TamamlanmayanDosyaTurKod.NORMAL});
                let filteredDosyalar = this.tamamlanmayanDosyalar.filter(tamamlanmayanDosya => {
                    let dosyaTime = new Date(tamamlanmayanDosya.dosyaAcilisTarihi).getTime();
                    let birimId = tamamlanmayanDosya.birimID;
                    return !!dosyalar.find(dosya=> dosya.birimId == birimId && dosya.acilisTime == dosyaTime);
                })
                return resolve(filteredDosyalar);
            } catch (e) {
                return reject(e);
            }
        });
    }
}

