import {UyapRequest} from "./UyapRequest";
import {MahkemeBirimleriRow} from "./CommonInterfaces";

export interface MahkemeBirimleriTalep {
    yargiTuru: number | string,
    yargiBirimi: number | string,
    dosyaKapaliMi: boolean | string
}

export interface MahkemeBirimleriCevap {
    items: Array<MahkemeBirimleriRow>
}

export class MahkemeBirimleri extends UyapRequest<MahkemeBirimleriTalep, MahkemeBirimleriCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return `<root><DVOList>
  <liste>
    <BirimDVO>
      <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
      <birimId>1000950</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 17. İcra Dairesi</birimAdi>
      <birimId>1000955</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 22. İcra Dairesi</birimAdi>
      <birimId>1000960</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 1. İcra Dairesi</birimAdi>
      <birimId>1000939</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 9. İcra Dairesi</birimAdi>
      <birimId>1000947</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>(Kapatılan)Ankara 32. İcra Dairesi</birimAdi>
      <birimId>1000970</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Kocaeli İcra Dairesi</birimAdi>
      <birimId>1052123</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
      <birimId>1000940</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 5. İcra Dairesi</birimAdi>
      <birimId>1000943</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Gölbaşı(Ankara) İcra Dairesi</birimAdi>
      <birimId>1001052</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 1. Genel İcra Dairesi</birimAdi>
      <birimId>1000967</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Batı İcra Dairesi</birimAdi>
      <birimId>1039299</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
      <birimId>1000944</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 16. İcra Dairesi</birimAdi>
      <birimId>1000954</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 26. İcra Dairesi</birimAdi>
      <birimId>1000964</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 24. İcra Dairesi</birimAdi>
      <birimId>1000962</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>İstanbul 19. İcra Dairesi</birimAdi>
      <birimId>1001899</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>İstanbul Anadolu 18. İcra Dairesi</birimAdi>
      <birimId>1008516</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 8. İcra Dairesi</birimAdi>
      <birimId>1000946</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara 14. İcra Dairesi</birimAdi>
      <birimId>1000952</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Bala İcra Dairesi</birimAdi>
      <birimId>1001004</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Polatlı İcra Dairesi</birimAdi>
      <birimId>1001116</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
  </liste>
  <actualCount>0</actualCount>
  <currentPage>0</currentPage>
  <pageSize>0</pageSize>
  <cacheID>0</cacheID>
  <recordCount>0</recordCount>
</DVOList></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/avukat_mahkemeleri_sorgula_brd.ajx';
    }

    async parse(data: string): Promise<MahkemeBirimleriCevap> {
        let json = await this.xml2json(data);
        let mahkemeBirimleri = json.root['DVOList'].liste.BirimDVO;
        let jsonData: Array<MahkemeBirimleriRow> = [];
        mahkemeBirimleri.forEach((mahkemeBirimi: any) => {
            jsonData.push({
                birimAdi: mahkemeBirimi.birimAdi,
                birimId: mahkemeBirimi.birimId,
                evraginBirimDVODaGonderilecegiBirimBilgisi: mahkemeBirimi.evraginBirimDVODaGonderilecegiBirimBilgisi,
                yeniBirimEkle: mahkemeBirimi.yeniBirimEkle,
                orgKoduDegisti: mahkemeBirimi.orgKoduDegisti,
                eskiAd: mahkemeBirimi.eskiAd,
                taranmamisKaydetme: mahkemeBirimi.taranmamisKaydetme,
                testMi: mahkemeBirimi.testMi
            })
        })
        return {
            items: jsonData
        };
    }

}

export default MahkemeBirimleri;
