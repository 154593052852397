import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface GetMaasHacziParamsTalep {
    // Boş
}

export type GetMaasHacziParamsCevap = Array<IMaasHacziParam>

export interface IMaasHacziParam {
    name: string,
    value: number
}


export default class GetMaasHacziParams extends UyapRequest<GetMaasHacziParamsTalep, GetMaasHacziParamsCevap> {
    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetMaasHacziParams_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return [{"name":"Çalışan Kamu","value":0},{"name":"Çalışan Özel","value":1},{"name":"Emekli Kamu","value":2},{"name":"Emekli Özel","value":3}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getMaasHacziParams_brd.ajx";
    }

    async parse(data: any): Promise<GetMaasHacziParamsCevap> {
        return data;
    }
}