import {UyapRequest} from "./UyapRequest";

export interface GetKurumMersisAdresiTalep {
    mersisNo: string,
    vergiNo: string

}

export interface GetKurumMersisAdresiCevap {
    ilKodu: number,
    ilceKodu: number,
    adres: string,
    ilAdi: string,
    ilceAdi: string,
    ulkeKodu: number,
    adresTuru: string,
    resultCode: number
}

export class GetKurumMersisAdresi extends UyapRequest<GetKurumMersisAdresiTalep, GetKurumMersisAdresiCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "ilKodu": 6,
            "ilceKodu": 25,
            "adres": "Hacettepe-Ä°vedik Osb Teknokenti 1368. Cadde (Melih GÃ¶kÃ§ek BulvarÄ±) No: 61 3. Kat Ofis No: 15 Yenimahalle/ANKARA",
            "ilAdi": "ANKARA",
            "ilceAdi": "YENÄ°MAHALLE",
            "ulkeKodu": 0,
            "adresTuru": "ADRTR00011",
            "resultCode": 0
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'get_kurum_mersis_adresi.ajx';
    }

    async parse(data: any): Promise<GetKurumMersisAdresiCevap> {
        return data;
    }

}

export default GetKurumMersisAdresi;
