import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface SorguBakiyesiTalep {
    dosyaId: string
}

export interface SorguBakiyesiCevap {
    dosyaKota: IDosyaKota,
    barokartBakiye: IBarokartBakiye
}

interface IDosyaKota {
    hasBarokart: boolean,
    sorguBakiyesi: number,
    sorguBakiyeAltLimit: number
    sorguKalanIslemSayisi: number,
    sorguToplamIslemSayisi: number,
    ucretsizSorguLimit: number,
}

interface IBarokartBakiye {
    type: string,
    value: string,
    log: string
}

export default class SorguBakiyesi extends UyapRequest<SorguBakiyesiTalep, SorguBakiyesiCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache(): { active: boolean; ttl: number; key: string } {
        return {
            active: false,
            ttl: 0,
            key: "_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "ws_sorgu_bakiyesi.ajx";
    }

    getMockResponse(): any {
        return [
            {
                "hasBarokart": false,
                "sorguToplamIslemSayisi": 0,
                "sorguKalanIslemSayisi": 5,
                "ucretsizSorguLimit": 5,
                "sorguBakiyeAltLimit": 50
            },
            {"type": "success", "value": "2.44", "log": ""}
        ];
    }

    async parse(data: any): Promise<SorguBakiyesiCevap> {
        return {
            dosyaKota: data[0],
            barokartBakiye: data[1]
        };
    }
}