import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";

export interface IcraTakipIlamDosyasiUygunMuTalep {
    birimId: string,
    ilamTuru: number,
    dosyaTurKod: number,
    dosyaYil: number,
    dosyaSiraNo: number,
    dosyaNo: string,
    TarafList: string   // Array<TarafKisi | TarafKurum>
}

export type IcraTakipIlamDosyasiUygunMuCevap = boolean;

export class IcraTakipIlamDosyasiUygunMu extends UyapRequest<IcraTakipIlamDosyasiUygunMuTalep, IcraTakipIlamDosyasiUygunMuCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [true, Math.random() * 100 > 20];
    }

    getResponseType(): ResponseType {
        return 'json';
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'icra_takip_ilam_dosyasi_uygunmu.ajx';
    }

    async parse(data: any): Promise<IcraTakipIlamDosyasiUygunMuCevap> {
        // data[1] true false farketmiyormuş. Hüseyin abi uyapdan denedi @Abdullah
        return data[0];
    }

}

export default IcraTakipIlamDosyasiUygunMu;
