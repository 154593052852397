import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface IldekiIdariBirimlerTalep {
    il: number | string,
    bltr2: string,           //yargiBirimi
    isAll: true
}

export type IldekiIdariBirimlerCevap = Array<IIdariBirim>;

interface IIdariBirim {
    birimAdi: string,
    birimId: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    isTumunuKopyala: boolean,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: number
}

export default class IldekiIdariBirimler extends UyapRequest<IldekiIdariBirimlerTalep, IldekiIdariBirimlerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ildekiIdariBirimler_" + this.talepData.il
        };
    }

    getMockResponse(): any {
        return [{"birimAdi":"İzmir Bölge İdare Mahkemesi Başkanlar Kurulu","birimId":"1048087","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"İzmir 6. İdari Dava Dairesi","birimId":"1046891","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"İzmir 7. İdari Dava Dairesi","birimId":"1046892","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"İzmir 8. İdari Dava Dairesi","birimId":"1065947","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"(Kapatılan)İzmir 9. İdari Dava Dairesi","birimId":"1065948","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"İzmir 1. Vergi Dava Dairesi","birimId":"1046857","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"İzmir 4. Vergi Dava Dairesi","birimId":"1046895","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "ildekiIdaribirimleriGetir.ajx";
    }

    async parse(data: any): Promise<IldekiIdariBirimlerCevap> {
        return data;
    }
}