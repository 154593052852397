import app from "@/main";
import {MtsTarafTebligatCevapRow} from "../../../uyap/MtsTarafTebligatList";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";


export class MtsTebligatGonderTaskResponse {
    message: string = "";
    dosyaEsasNo: string = "";
    taraflar: any[] = [];
    tebligatUcreti: number = 0;
    tebligatlar: Blob[] = [];
}

export class MtsTebligatGonderTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let data: {
                adresTuru: string,
                dosya: {
                    dosyaNo: string,
                    dosyaId: string,
                    taraflar: any[],
                }
            } = task.data;
            let response: MtsTebligatGonderTaskResponse = new MtsTebligatGonderTaskResponse();
            response.dosyaEsasNo = data.dosya.dosyaNo;
            response.taraflar = data.dosya.taraflar;
            let tebligatList = await app.$uyap.MtsTarafTebligatList().run({
                dosyaId: data.dosya.dosyaId
            })

            let MTSTebligat = 1;
            let tebligatKisiKurumIdList: any[] = [];
            tebligatList.forEach((tebligatDvo: MtsTarafTebligatCevapRow) => {
                tebligatKisiKurumIdList.push(tebligatDvo.tarafTebligatDVO.evrakKisiDVO.guncelKisiKurumId);
            })

            response.tebligatUcreti = await app.$uyap.MtsTebligatUcreti().run({
                dosyaId: data.dosya.dosyaId,
                tebligatKisiKurumIdList: JSON.stringify(tebligatKisiKurumIdList)
            })

            let tebligatlar = await app.$uyap.MtsTebligatGonder().run({
                MTSTebligat: MTSTebligat,
                dosyaId: data.dosya.dosyaId,
                tebligatKisiKurumIdList: tebligatKisiKurumIdList,
                tebligatUcreti: response.tebligatUcreti
            });

            for (const tebligat of tebligatlar) {
                let evrakBlob = await app.$uyap.EvrakIndir().run({
                    evrakId: tebligat.evrakId,
                    dosyaId: data.dosya.dosyaId
                });
                response.tebligatlar.push(evrakBlob);
            }
            response.message = "Tebligat Gönderildi";
            task.response = response;
            task.status = TaskDurum.BITTI_BASARILI;
            return task;
        } catch (e) {
            throw new Error(e.message);
        }
    }
}