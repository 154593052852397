export class TakipRol {
    rolID!: number;
    rolAdi!: string;
    sanikStatusu!: string;
    davaliDavaciGrubu!: string;
}

export default class TakipRolleri {
    takipRoller: TakipRol[] = [];
    ucuncuTarafRoller: TakipRol[] = [];

    constructor() {
        this.takipRoller = [
            {
                "rolID": 71,
                "rolAdi": "ARACIKISIKURUM",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 21,
                "rolAdi": "ALACAKLI",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 22,
                "rolAdi": "BORÇLU VE MÜFLİS",
                "sanikStatusu": "E",
                "davaliDavaciGrubu": "L"
            },
            {
                "rolID": 23,
                "rolAdi": "İFLAS İDARE MEMURU",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "C"
            },
            {
                "rolID": 24,
                "rolAdi": "İFLAS İDARE MEMURU ADAYI",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 34,
                "rolAdi": "TEREKE SORUMLUSU",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 41,
                "rolAdi": "iptal,sıfatı Borçu/Müflis yap",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 43,
                "rolAdi": "YED-İ EMİN KİSİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 44,
                "rolAdi": "REHİN SAHİBİ",
                "sanikStatusu": "E",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 45,
                "rolAdi": "IHALE KATILIMCISI",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 46,
                "rolAdi": "HİSSEDAR",
                "sanikStatusu": "h",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 47,
                "rolAdi": "İPOTEK SAHİBİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 53,
                "rolAdi": "KİRACI",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 54,
                "rolAdi": "İŞGALCİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 25,
                "rolAdi": "İSTİHKAK İDDİASI SAHİBİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 26,
                "rolAdi": "ÜÇÜNCÜ ŞAHIS",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 42,
                "rolAdi": "MURIS",
                "sanikStatusu": "E",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 116,
                "rolAdi": "TEDBİR KOYDURAN",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 117,
                "rolAdi": "HACİZ SAHİBİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 118,
                "rolAdi": "İPOTEK SAHİBİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 119,
                "rolAdi": "MÜKELLEFİYET SAHİBİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 132,
                "rolAdi": "MÜMESSİL",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 31,
                "rolAdi": "VASİ",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "C"
            },
            {
                "rolID": 32,
                "rolAdi": "KAYYIM",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "C"
            },
            {
                "rolID": 125,
                "rolAdi": "DİĞER HACİZ/REHİN ALACAKLISI",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            },
            {
                "rolID": 126,
                "rolAdi": "DİĞER HACİZ/REHİN BORÇLUSU",
                "sanikStatusu": "H",
                "davaliDavaciGrubu": "N"
            }
        ];
        this.ucuncuTarafRoller = this.takipRoller.filter((rol) => (rol.rolID == 71 || rol.rolID == 26));
    }

    find(rolId: any): TakipRol | undefined {
        let finded = this.takipRoller.find((x: TakipRol) => x.rolID == rolId)
        if (finded)
            return finded
    }
}
