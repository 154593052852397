import {UyapRequest} from "./UyapRequest";
import HesapBilgisi from "../takip_ac/parser/HesapBilgisi";

export interface IbanListesiTalep {
    kisiKurumId: number,
    tarafTur: number
}

export default class GetIbanListesi extends UyapRequest<IbanListesiTalep, HesapBilgisi[]> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getIbanListesi.ajx";
    }

    async parse(data: any): Promise<HesapBilgisi[]> {
        if (data.type == "success")
            return data.value;
        else if (data.message == "Banka hesap bilgisi bulunamadı.")
            return [];
        else
            return Promise.reject(data.message);
    }

    getMockResponse(): any {
        // var
        return {
            "type": "success",
            "message": "",
            "value": [
                {
                    "kayitID": 5437178,
                    "bankaAdi": "ING BANK A.S.",
                    "ibanNumarasi": "TR920009900955523400100001",
                    "hesapGenel": true,
                    "hesapAktifmi": "E"
                }
            ],
            "log": ""
        };
        // yok
        return {"type":"error","message":"Banka hesap bilgisi bulunamadı.","log":""};
    }
}