import {UyapRequest} from "./UyapRequest";
import {AdresRow} from "./CommonInterfaces";

export interface MtsTebligatListTalep {
    dosyaId: number | string
}

export class MtsTebligatListCevap {
    items: MtsTebligatListRow[] = [];
    message: string = '';
}

export interface MtsTebligatListRow {
    dosyaNoYil: number,
    dosyaSiraNo: number,
    dosyaNo: string,
    dosyaTurKod: number,
    TebligatStatu: number,
    TebligatTuru: number,
    TebligatTipi: string,
    TebligatTipiAciklamasi: string,
    TebligatYapilanKisininAdiSoyadi: string,
    TebligatAdresi: Array<AdresRow>,
    TebligatTarihi: string,
    mersule: string,
    dagitimID: number,
    secili: boolean,
    raporId: number,
    tebligedilenAdi: string,
    tebligedilenSoyadi: string,
    tebligatSonucKayitId: number,
    tarafList: Array<any>,
    vekilTarafList: Array<any>,
    tahsilatList: Array<any>,
    adliYardimTalebi: number,
    gonderiTipi: number,
    masrafGgIdList: Array<any>,
    donusluTebligat: number,
    icraDairesiMi: boolean,
    idariYargiMi: boolean,
    hukukMu: boolean,
    vekilliktenAzledilenlereMi: boolean,
    onaySonrasiIcraMi: boolean
}

export class MtsTebligatList extends UyapRequest<MtsTebligatListTalep, MtsTebligatListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{
            "dosyaNoYil": 2019,
            "dosyaSiraNo": 314688,
            "dosyaNo": "2019/314688",
            "dosyaTurKod": 294,
            "TebligatStatu": 1,
            "TebligatTuru": 0,
            "TebligatTipi": "TBLTP00001",
            "TebligatTipiAciklamasi": "Normal Tebligat",
            "TebligatYapilanKisininAdiSoyadi": "BORÇLU VE MÜFLİS ERSİN KURU",
            "TebligatAdresi": {
                "ilKodu": 37,
                "ilAdi": "KASTAMONU",
                "ilceAdi": "DADAY",
                "ilceKodu": 9,
                "adres": "MERKEZ  KUYU SK. NO:4",
                "kisiKurumID": 67091597,
                "adresID": 254002515,
                "adresTuru": "ADRTR00001",
                "eklenebilirAdresMi": false,
                "kayitliAdresMi": false,
                "aktifAdresmi": false,
                "guncellendiMi": false
            },
            "TebligatTarihi": "Aug 21, 2019 2:35:54 PM",
            "GGEvrakID": 6042385681,
            "postatebligatSekli": "TBLPT00001",
            "isTebligEdildi": "E",
            "tebligatIlanBilgileri": [],
            "KayitID": 6904930348,
            "postayaVerildi": false,
            "tebligatYapilanKisiTGNLKAYITKisiKurumID": 67091597,
            "dosyaID": 532189956,
            "tebligatKayitTablosuKayitTarihi": "Aug 21, 2019 2:35:54 PM",
            "tebligatKayitTablosuKayitTarihiFormatted": "21/08/2019 14:35",
            "tebligTarihi": "Aug 23, 2019 12:00:00 AM",
            "mersule": "4100003754712",
            "dagitimID": 1552010436,
            "secili": false,
            "raporId": 6042385681,
            "tebligedilenAdi": "BORÇLU VE MÜFLİS ERSİN",
            "tebligedilenSoyadi": "KURU",
            "tebligatSonucKayitId": 6912311909,
            "tarafList": [],
            "vekilTarafList": [],
            "tahsilatList": [],
            "adliYardimTalebi": 1,
            "gonderiTipi": 0,
            "masrafGgIdList": [],
            "donusluTebligat": 0,
            "icraDairesiMi": false,
            "idariYargiMi": false,
            "hukukMu": false,
            "vekilliktenAzledilenlereMi": false,
            "onaySonrasiIcraMi": false
        }];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_tebligat_list.ajx';
    }

    async parse(data: any): Promise<MtsTebligatListCevap> {
        if(data){
            return {
                items: data,
                message: ''
            }
        }else{
            throw new Error("Tebligat listesi bulunamadı");
        }

    }

}

export default MtsTebligatList;
