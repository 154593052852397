import TarafKisi from "./parser/TarafKisi";
import TarafKurum from "./parser/TarafKurum";
import TakipRolleri from "./parser/TakipRolleri";
import app from "@/main";

export default class UyapTakipTalebiContent {
    data: any;
    tarafList: Array<TarafKisi | TarafKurum> = [];
    alacakKalemleri: any[] = [];
    takibeEsasMiktar: number = 0;
    hashObj: any;
    hash: string = "";

    static parse(data: any): UyapTakipTalebiContent {
        let UTTC = new UyapTakipTalebiContent();
        UTTC.data = data;
        let alacakliObject = UTTC.data.GroupXfPGrp.alacakliAdiSoyadiGrp;
        let borcluObject = UTTC.data.GroupXgPGrp;
        if (typeof alacakliObject.length != "undefined") {
            alacakliObject.forEach((alacakli: any) => {
                UTTC.setTarafAlacakli(alacakli);
            })
        } else {
            UTTC.setTarafAlacakli(alacakliObject);
        }
        if (typeof borcluObject.length != "undefined") {
            borcluObject.forEach((borclu: any) => {
                UTTC.setTarafBorclu(borclu.borcluAdiSoyadiGrp);
            })
        } else {
            UTTC.setTarafBorclu(borcluObject.borcluAdiSoyadiGrp);
        }
        UTTC.setTakibeEsasMiktar();
        UTTC.createHash();
        return UTTC;
    }

    createHash() {
        let hashObj: any = {
            taraflar: [],
            takibeEsasMiktar: this.takibeEsasMiktar,
        };
        this.tarafList.forEach(taraf => {
            if (taraf.isKisi()) {
                hashObj.taraflar.push({
                    ad: taraf.temelBilgileri.adi,
                    soyad: taraf.temelBilgileri.soyadi,
                    tcKimlikNo: taraf.temelBilgileri.tcKimlikNo,
                    rolId: taraf.tarafSifati.rolID
                })
            }
            if (taraf.isKurum()) {
                hashObj.taraflar.push({
                    kurumadi: taraf.temelBilgileri.kurumAdi,
                    vergino: taraf.temelBilgileri.vergiNo,
                    mersisno: taraf.temelBilgileri.mersisNo,
                    rolId: taraf.tarafSifati.rolID
                })
            }
        });
        this.hashObj = hashObj;
        this.hash = app.$uyapHelper.hash(hashObj);
    }

    setTakibeEsasMiktar() {
        console.log("xx", this.data.TabloXeXa.rowXeXa.TabloXbXb.rowXbXb);
        let kalemler = this.data.TabloXeXa.rowXeXa.TabloXbXb.rowXbXb;
        //TODO DOLAR OLABILIR >>
        let toplamYaziliKalem = kalemler.find((x: any) => x.takipTalebiDortMaddFaizinIslemeyeBasladigiGunGrp.faizinIslemeyeBasladigiGun == "TL   Toplam Alacak ");
        console.log("toplamAlacak", toplamYaziliKalem);
        this.takibeEsasMiktar = toplamYaziliKalem.takipTalebiDortMaddeAlacaginTlOlarakKarsiligiFaizMiktarGrp.takipTalebiDortMaddeAlacaginTlOlarakKarsiligiFaizMiktari;
    }

    setTarafAlacakli(item: any) {
        let taraf: TarafKisi | TarafKurum;
        if (typeof item.tc_Kimlik != "undefined") {
            taraf = new TarafKisi();
            let adSoyad = item.alacakliAdiSoyadi.trim().trim(" ").replace(",", "");
            let ad = adSoyad.substr(0, adSoyad.lastIndexOf(" "));
            let soyad = adSoyad.substr(adSoyad.lastIndexOf(" "), adSoyad.length).trim();
            let tcKimlikNo = item.tc_Kimlik.substr(0, item.tc_Kimlik.indexOf("TC Nolu,")).trim();
            taraf.temelBilgileri.adi = ad;
            taraf.temelBilgileri.soyadi = soyad;
            taraf.temelBilgileri.tcKimlikNo = tcKimlikNo;
            taraf.adresBilgisi.adres = item.avukatYoksaAlacakliAdresi;
            taraf.kisiMi = true;
        } else {
            taraf = new TarafKurum();
            taraf.kurumMu = true;
            taraf.temelBilgileri.kurumAdi = item.alacakliAdiSoyadi.replace(",", "").trim();
            taraf.temelBilgileri.vergiNo = item.vergiNo.replace("Vergi Nolu,", "").trim();
            taraf.adresBilgisi.adres = item.avukatYoksaAlacakliAdresi;
        }
        //@ts-ignore
        taraf.tarafSifati = new TakipRolleri().find(21);
        this.tarafList.push(taraf);
    }

    setTarafBorclu(item: any) {
        let taraf: TarafKisi | TarafKurum;
        if (typeof item.tc_Kimlik != "undefined") {
            taraf = new TarafKisi();
            let adSoyad = item.borcluAdiSoyadi.trim().trim(" ").replace(",", "");
            let ad = adSoyad.substr(0, adSoyad.lastIndexOf(" "));
            let soyad = adSoyad.substr(adSoyad.lastIndexOf(" "), adSoyad.length).trim();
            let tcKimlikNo = item.tc_Kimlik.substr(0, item.tc_Kimlik.indexOf("TC Nolu,")).trim();
            taraf.temelBilgileri.adi = ad;
            taraf.temelBilgileri.soyadi = soyad;
            taraf.temelBilgileri.tcKimlikNo = tcKimlikNo;
            taraf.adresBilgisi.adres = item.avukatYoksaborcluAdresi;
            taraf.kisiMi = true;
        } else {
            taraf = new TarafKurum();
            taraf.kurumMu = true;
            taraf.temelBilgileri.kurumAdi = item.borcluAdiSoyadi.replace(",", "").trim();
            taraf.temelBilgileri.vergiNo = item.vergiNo.replace("Vergi Nolu,", "").trim();
            taraf.adresBilgisi.adres = item.avukatYoksaborcluAdresi;
        }
        //@ts-ignore
        taraf.tarafSifati = new TakipRolleri().find(22);
        this.tarafList.push(taraf);
    }

}