import {UyapRequest} from "./UyapRequest";

export class VakifbankHesapListesiTalep {
    tutar: any = 0
    uyapGrupId: any = ""
}

export interface VakifbankHesapBilgileri {
    bakiyeDurumu: string,
    hesapKartNo: any,
    hesapTipi: string,
    subeAdi: string,
    subeKodu: string,
    masrafTutari: any,
    odemeYontemi: string
}

export interface VakifbankHesapListesiCevap {
    sonucKodu: number,
    sonucAciklama: string,
    bankaId: any,
    islemSahiplikTuru: string,
    tcKimlikNo: string,
    toplamTutar: any,
    uyapGrupId: string,
    hesapBilgileri: {
        HesapBilgileriDVO: Array<VakifbankHesapBilgileri>
    }
}

export default class VakifbankHesapListesi extends UyapRequest<VakifbankHesapListesiTalep, VakifbankHesapListesiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return `<root><HesapListeleSonucDVO>
  <sonucKodu>76</sonucKodu>
  <sonucAciklama>SMS ONAY BEKLENIYOR</sonucAciklama>
  <bankaId/>
  <islemSahiplikTuru>KendiAdina</islemSahiplikTuru>
  <tcKimlikNo>14537825364</tcKimlikNo>
  <toplamTutar>13.75</toplamTutar>
  <uyapGrupId>36992574</uyapGrupId>
  <hesapBilgileri>
    <HesapBilgileriDVO>
      <bakiyeDurumu>YETERLI BAKIYE</bakiyeDurumu>
      <hesapKartNo>00158007300608019</hesapKartNo>
      <hesapTipi>VADESIZ</hesapTipi>
      <subeAdi>ADLİYE SARAYI ŞUBESİ</subeAdi>
      <subeKodu>S00325</subeKodu>
      <masrafTutari>0</masrafTutari>
      <odemeYontemi>Hesap</odemeYontemi>
    </HesapBilgileriDVO>
  </hesapBilgileri>
</HesapListeleSonucDVO></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/vakifbank_sms_onay.ajx';
    }

    async parse(data: string): Promise<VakifbankHesapListesiCevap> {
        let obj: any = await this.xml2json(data);
        return obj.root.HesapListeleSonucDVO;
    }

}
