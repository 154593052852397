import {UyapRequest} from "./UyapRequest";
import {KisiSorgulaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgula";

export interface KisiSorgulaWithAdSoyadTalep {
    ad: string,
    soyad: string,
    tcKimlikNo: string
}

export type KisiSorgulaWithAdSoyadCevap = KisiSorgulaCevap;

export default class KisiSorgulaWithAdSoyad extends UyapRequest<KisiSorgulaWithAdSoyadTalep, KisiSorgulaWithAdSoyadCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "kisiWithAdSoyad_" + this.talepData.tcKimlikNo
        };
    }

    getMockResponse(): any {
        return {"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"kisiKurumID":72835458,"tcKimlikNo":"25624973288","adi":"HÜSEYİN","soyadi":"TORUN","babaAdi":"DURAK","anaAdi":"DUDU","dogumTarihiStr":"26/07/1984","cinsiyeti":"E","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'kisiSorgulaWithAdSoyad.ajx';
    }

    async parse(data: any): Promise<KisiSorgulaWithAdSoyadCevap> {
        return data;
    }
}
