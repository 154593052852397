import {UyapRequest} from "./UyapRequest";

export interface YargiBirimleriTalep {
    yargiTuru: number
}

export type YargiBirimleriCevap = Array<YargiBirimleriCevapRow>

export interface YargiBirimleriCevapRow {
    altSistKodu: number,
    tablo: string,
    kod: string
}

export default class YargiBirimleri extends UyapRequest<YargiBirimleriTalep, YargiBirimleriCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "YargiBirimleri_" + this.talepData.yargiTuru
        };
    }

    getMockResponse(): any {
        return [{"altSistKodu":-1,"tablo":"0917","kod":"BÖLGE İDARE MAHKEMESİ"},{"altSistKodu":-1,"tablo":"0918","kod":"İDARE MAHKEMESİ"},{"altSistKodu":-1,"tablo":"0919","kod":"VERGİ MAHKEMESİ"}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'yargiBirimleriSorgula_brd.ajx';
    }

    async parse(data: any): Promise<YargiBirimleriCevap> {
        return data;
    }
}