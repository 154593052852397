import {Mixins} from "../../Mixins";
import {MDB} from "../../../MDB";
import {MtsDosyaFormData} from "../../MtsDosyaFormData";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

const mdb = new MDB();

export default class MtsDosyaBilgileri {
    selectedBirim: {
        birimId: string,
        birimAd: string
    } = {
        birimId: "",
        birimAd: ""
    }
    selectedIl: {
        il: string
    } = {
        il: ""
    };
    mahiyetList: any[] = [];
    dosyaKriterList: any[] = [];
    terekemi: boolean = false;
    dosyaAciklama_48_4: string | null = "";
    dosyaBelirleyici: any = {};
    abonelikMi: boolean = false;

    static fromJson(jsonData: any): MtsDosyaBilgileri {
        return Args.staticFromJson(jsonData,new MtsDosyaBilgileri());
    }

    static parse(rootDomNode: HTMLUnknownElement, formData: MtsDosyaFormData): MtsDosyaBilgileri {
        let mtsDosyaBilgileri = new MtsDosyaBilgileri();
        mtsDosyaBilgileri.setMahiyet(Mixins.nullUndefCleaner(rootDomNode.getAttribute("mahiyetkodu")));
        if (!mtsDosyaBilgileri.isMahiyet())
            mtsDosyaBilgileri.setMahiyet(formData.mahiyetId);
        mtsDosyaBilgileri.setTereke(formData.terekemi);
        mtsDosyaBilgileri.setDosyaBelirleyici(rootDomNode);
        console.log("FORMDATA", formData);
        mtsDosyaBilgileri.selectedIl.il = formData.il;
        mtsDosyaBilgileri.selectedBirim.birimId = formData.adliye;
        mtsDosyaBilgileri.selectedBirim.birimAd = formData.birimAd;
        mtsDosyaBilgileri.dosyaAciklama_48_4 = rootDomNode.getAttribute("alacaklininTalepEttigiHak");
        mtsDosyaBilgileri.dosyaKriterList = mtsDosyaBilgileri.dosyaKriterListConverter(rootDomNode);
        return mtsDosyaBilgileri;
    }

    isMahiyet() {
        return this.mahiyetList.length > 0
    }

    setMahiyet(mahiyetId: any) {
        let mahiyet = mdb.mtsTakipMahiyetleri.find(val => val.mahiyetId == mahiyetId);
        console.log("setMahiyet mahiyet", mahiyet);
        if (mahiyet) {
            this.mahiyetList = [mahiyet];
        }
    }

    setDosyaBelirleyici(rootDomNode: HTMLUnknownElement) {
        let text = rootDomNode.getAttribute("dosyabelirleyicisi");
        let dosyaBelirleyici = {};
        let abonelikMi = false;
        if (typeof text != "undefined" && text != "" && text != null) {
            let splited = text.split('_:');//dosya belirleyici _: id _: aboneliksozlesmesi seklinde veri geliyor
            //@ts-ignore
            if (splited[splited.length - 1] == 1)
                abonelikMi = true;
            dosyaBelirleyici = {name: splited[0], id: splited[1], abonelikSozlesmesi: abonelikMi};
        }
        this.dosyaBelirleyici = dosyaBelirleyici;
        this.abonelikMi = abonelikMi;
    }

    getMahiyetAdi() {
        console.log("getMahiyetAdi")
        if (this.isMahiyet()) {
            console.log(">> 0 ");
            console.log("this.mahiyetList[0]");
            return this.mahiyetList[0].mahiyetAdi
        }
    }

    dosyaKriterListConverter(rootDomNode: Element): any[] {
        let bk = rootDomNode.getAttribute("bk84maddeuygulansin");
        let vkdv = rootDomNode.getAttribute("vkdvuygulansin");
        let bsmv = rootDomNode.getAttribute("bsmvuygulansin");
        let kkdf = rootDomNode.getAttribute("kkdfuygulansin");
        let kdv = rootDomNode.getAttribute("kdvuygulansin");
        let oiv = rootDomNode.getAttribute("oivuygulansin");
        let result = [];
        if (bk == "E") {
            result.push(mdb.mtsDosyaKriterleri.find(val => val.kod == "bk"));
        }
        if (vkdv == "E") {
            result.push(mdb.mtsDosyaKriterleri.find(val => val.kod == "vkdv"));
        }
        if (bsmv == "E") {
            result.push(mdb.mtsDosyaKriterleri.find(val => val.kod == "bsmv"));
        }
        if (kdv == "E") {
            result.push(mdb.mtsDosyaKriterleri.find(val => val.kod == "kdv"));
        }
        if (oiv == "E") {
            result.push(mdb.mtsDosyaKriterleri.find(val => val.kod == "oiv"));
        }
        return result;
    }

    isIcraProXml(): boolean {
        console.log("dosyabelirleyici isIcraproXml=", (typeof this.dosyaBelirleyici.id != "undefined" && this.dosyaBelirleyici.id != "") === true);
        return (typeof this.dosyaBelirleyici.id != "undefined" && this.dosyaBelirleyici.id != "") === true;
    }

    setTereke(tereke: boolean) {
        if (tereke == true)
            this.terekemi = tereke;
        else
            this.terekemi = false;
    }

    getTereke() {
        return this.terekemi;
    }

}

