import Args from "../Args";
import {Mixins} from "../../Mixins";
import AlacakKalem from "../AlacakKalem";
import Ilamsiz from "./Ilamsiz";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export default class Diger extends Ilamsiz {
    ilamsizTipi: string = 'diger';
    id!: number;// id;
    alacakNo: string = ''
    meblagi: string = ''// Mixins.replaceCommaDots(meblagi);//"23000",
    meblagTuru: string = ''// meblagTuru;//"PRBRMTL",
    meblagTuruAciklama: string = ''//meblagTuruAciklama;//"TL-Türk Lirası",
    aciklama: string = ''// odemeYeri;
    alacakTarihi: string = ''// ibrazTarihi;//"25/03/2019",
    alacakKalemleri: AlacakKalem[] = []// alacakKalemleri;

    static fromJson(jsonData: any): Diger {
        let obj = Args.staticFromJson(jsonData, new Diger());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x: AlacakKalem) => AlacakKalem.fromJson(x));
        return obj;
    }

    static parse(
        domNode: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ) {
        let diger = new Diger();
        let aciklama = "";
        let sekizinciMadde = Mixins.nullUndefCleaner(domNode.getAttribute("sekizinciMadde"));
        if (sekizinciMadde != "")
            aciklama = sekizinciMadde;
        diger.id = args.counterId();
        diger.alacakNo = Mixins.nullUndefCleaner(domNode.getAttribute('alacakno'));
        diger.meblagi = Mixins.replaceCommaDots(domNode.getAttribute('tutar'));
        diger.meblagTuru = Mixins.nullUndefCleaner(domNode.getAttribute('tutartur'));
        diger.meblagTuruAciklama = Mixins.nullUndefCleaner(domNode.getAttribute('tutaradi'));
        diger.aciklama = aciklama != "" ? aciklama : Mixins.nullUndefCleaner(domNode.getAttribute('digeralacakaciklama'));
        diger.alacakTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('tarih'));
        diger.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(domNode, args, tarafList);
        return diger;
    }

    toUyapJson() {
        const {
            id,
            ilamsizTipi,
            alacakNo,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            alacakTarihi,
            alacakKalemleri,
            aciklama,
        } = this;
        return {
            id,
            ilamsizTipi,
            alacakNo,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            aciklama,
            alacakTarihi,
            alacakKalemleri: alacakKalemleri.map(i => i.toUyapJson()),
        }
    }

}

