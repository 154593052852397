import _Vue from "vue";
import axios, {Axios} from "axios";
import app from "@/main";
import {Mixins} from "@/plugins/uyap-plugin/takip_ac/Mixins";
import * as xml2js from "xml2js";
import {PlayerDurum} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {store} from "@/store";
import {v4 as uuidv4} from 'uuid';
import Swal from "sweetalert2";

const imzaciUrl = 'https://imza.eicrapro.com:6090/'
const proxyBaseUrl = 'https://imza.eicrapro.com:';
const uyapSignerUrl = 'https://imza.eicrapro.com:6057';
const avukatBetaUrl = 'https://imza.eicrapro.com:6099';

export enum UYAP_PLATFORM_SOCKET {
    ARABULUCU_PORTAL = 6080,
    ARABULUCU_PORTAL_URL = "arabulucu.uyap.gov.tr",
    AVUKAT_PORTAL = 6081,
    AVUKAT_PORTAL_URL = "avukat.uyap.gov.tr",
}

class ImzaClient {
    simulator = false;
    imzaLoginWaiting = false;
    waitingFailMessage = "Bekleyen bir giriş isteği mevcut.";

    setSimulator() {
        this.simulator = true;
    }

    heartbeat() {
        const xml = '<root><auth><token></token><hash></hash></auth><data></data></root>';
        return new Promise(async (s, f) => {
            if (this.simulator) {
                return s("");
            }
            try {
                let data = await axios.post(imzaciUrl + 'xml/heartbeat', {xml: xml}, {
                    withCredentials: true
                });
                app.$socket.client.connect();
                let tenant = await store.dispatch('tenantData');
                let apiType: string = "";
                switch (process.env.VUE_APP_API_TARGET) {
                    case 'http://localhost':
                        apiType = "local";
                        break;
                    case 'https://apicloudicra.neova.com.tr':
                        apiType = "neova";
                        break;
                    case 'https://apicloudtest.zirve-bilgisayar.com':
                        apiType = "test"
                        break;
                    case 'https://apibeta.zirve-bilgisayar.com':
                        apiType = "beta"
                        break;
                    case 'https://apicloud.zirve-bilgisayar.com':
                        apiType = "canli"
                        break;
                }
                const cookieSetUrl = encodeURI(proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL + '/setConfig?token=' + 'Bearer ' + store.getters.accessToken + '&tenant=' + JSON.stringify(tenant) + '&tckn=' + store.getters.userData.kimlik_no + '&apiType=' + apiType);
                await app.$uyap.SetCookie().run({url: cookieSetUrl});
                _Vue.prototype.$socket.client.emit("updateToken");
                return s(data);
            } catch (e) {
                //console.log('fail');
                return f(e);
            }
        });
    }

    async setCookieToImza() {
        if (window.sessionStorage.hasOwnProperty("INJECT_JSESSIONID")) {
            let jsessionId = window.sessionStorage.getItem("INJECT_JSESSIONID");
            const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/cookie?JSESSIONID=' + jsessionId + ';SameSite=None;Secure';
            await app.$uyap.SetCookie().run({url: cookieSetUrl});
        }
    }

    async changePlayerStatus(status: PlayerDurum) {
        const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/putTaskPlayerStatus?' + status;
        await app.$uyap.ChangePlayerStatus().run({url: cookieSetUrl});
    }

    async getPlayerStatus() {
        const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/getTaskPlayerStatus';
        return await app.$uyap.ChangePlayerStatus().run({url: cookieSetUrl});
    }

    async login(uyapPlatform: UYAP_PLATFORM_SOCKET) {
        try {
            if (this.imzaLoginWaiting == true)
                throw new Error(this.waitingFailMessage);
            this.imzaLoginWaiting = true;
            let authorizationCodeResponse = await axios.post(imzaciUrl + 'xml/session');
            window.sessionStorage.setItem("uyapAuthorizationCode", authorizationCodeResponse.data);
            let platform = UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL_URL;
            if (uyapPlatform == UYAP_PLATFORM_SOCKET.ARABULUCU_PORTAL)
                platform = UYAP_PLATFORM_SOCKET.ARABULUCU_PORTAL_URL;
            let uyapLoginResponse = await axios.get(imzaciUrl + 'uyapLogin?code=' + authorizationCodeResponse.data + '&platform=' + platform);
            let cookies = uyapLoginResponse.data;
            //@ts-ignore
            let jsessionId = cookies.find(c => c.indexOf("JSESSIONID") >= 0);
            const cookieSetUrl = proxyBaseUrl + uyapPlatform.toString() + '/cookie?' + cookies.join("; ") + ';SameSite=None;Secure';
            window.sessionStorage.setItem("JSESSIONID", jsessionId);
            app.$uyapHelper.setCookie("JSESSIONID", jsessionId.replace("JSESSIONID=", ""), 1);
            await app.$uyap.SetCookie().run({url: cookieSetUrl});
            app.$socket.client.emit("uyapcookies", {
                cookies: cookies,
            });
            let counter = 0;
            const checkSession = () => {
                app.$uyap.AvukatKisiselBilgileriSorgulama().run({}).then(avukat => {
                    this.imzaLoginWaiting = false;
                    app.$store.commit('setUyapAvukatBilgileri',avukat);
                    return avukat;
                }).catch((avukatErr) => {
                    console.log("CATCH AVUKAT", avukatErr);
                    if (counter > 5) {
                        this.imzaLoginWaiting = false;
                        throw new Error('UYAP oturumuna erişilemedi.');
                    } else {
                        counter++;
                        window.setTimeout(() => {
                            checkSession();
                        }, 3000)
                    }
                })
            }
            checkSession();
        } catch (e) {
            console.log("imza login CATCH", e);
            this.imzaLoginWaiting = false;
            throw new Error(e.message);
        }
    }

    async betaLogin() {
        try {
            if (this.imzaLoginWaiting == true)
                throw new Error(this.waitingFailMessage);
            this.imzaLoginWaiting = true;

            let myuuid = uuidv4();
            const licenseKey = "Mcv22utXDqmKirIDFtb5is/bKzevMNuILd1utHTFnntBUQe85RnYlB6kZtiSYu1Ga1NpoOCR1UsLU9VDxwLos10BAi9ZfM8fVJcVP18hE0YpGTNVMU+mQ1053ri37UfWQ+fweqqTr4qGWxqwyYvLkZdn9P5Sn+a6U79GfrIpHoIhpawHcc6trRTzYTO2LqFvZoS4WS8KlyqFd2k8eHgr6s19RhsUb2+XTXGoShgFMHJlKFXk2Q11VDrqep2Xet6PuCXzLEsc7oPDFQmwmcDsnXAJElNSy2Ug6DpzV32oHboVejDrv6P+h6aztM0LCBTmoaFTNC87lb6vFmG895cwbA==";
            let formData = new FormData();
            let firsReqData = {
                "action": "initialize",
                "licenseKey": licenseKey,
                "usedUrl": "https://avukatbeta.uyap.gov.tr/giris",
                "transactionUUID": myuuid
            };
            formData.append('parameters', window.btoa(JSON.stringify(firsReqData)));
            let firstRes = await axios.post(uyapSignerUrl, formData);
            let secondFormData = new FormData();
            let secondReqData = {
                "action": "listCardTypeAndSlots",
                "licenseKey": licenseKey,
                "usedUrl": "https://avukatbeta.uyap.gov.tr/giris",
                "transactionUUID": myuuid,
                "nonce": JSON.parse(firstRes.data.substring(1, firstRes.data.length-1))[0].nonce
            };
            secondFormData.append('parameters', window.btoa(JSON.stringify(secondReqData)));
            let secondRes = await axios.post(uyapSignerUrl, secondFormData);
            let thirdFormData = new FormData();
            let secondResData=JSON.parse(secondRes.data.substring(1, secondRes.data.length-1))[0]
            let thirdReqData = {
                "library": window.atob(secondResData.library),
                "slotId": window.atob(secondResData.slotId),
                "action": "listSignerCertificatesAsJSON",
                "licenseKey": licenseKey,
                "usedUrl": "https://avukatbeta.uyap.gov.tr/giris",
                "transactionUUID": myuuid,
                "nonce": secondResData.nonce
            };
            thirdFormData.append('parameters', window.btoa(JSON.stringify(thirdReqData)));
            let thirdRes = await axios.post(uyapSignerUrl, thirdFormData);
            let thirdResData=JSON.parse(thirdRes.data.substring(1, thirdRes.data.length-1))[0];
            await axios.post('https://imza.eicrapro.com:6090/focus');
            // TODO: buraya koşul koy user tckn thirdResData.subjectSerialNumber ile eşleşiyor eşleşiyor ise devam et
            // session stora tcknyi set et axios oradan çeksin
            console.log('tckn',thirdResData.subjectSerialNumber)
            if (sessionStorage.getItem('tckn') != atob(thirdResData.subjectSerialNumber))
                throw {type: 'unauthorized', message: 'İzinsiz İmza'};
            let pinCode = localStorage.getItem(atob(thirdResData.serialNumber));
            if (!pinCode || pinCode == "undefined" || pinCode == undefined) {
                const {value: deger} = await Swal.fire({
                    title: 'İmza Pin Kodu',
                    text: 'İmza: ' + window.atob(thirdResData.commonName),
                    icon: "question",
                    allowOutsideClick: false,
                    input: 'password',
                    inputPlaceholder: 'PIN',
                    confirmButtonText: 'Tamam'
                });
                localStorage.setItem(atob(thirdResData.serialNumber), deger);
                pinCode = deger;
            }
            let newAxios = new Axios({
                withCredentials: true,
            });
            await newAxios.get(avukatBetaUrl+'/clearCookies');
            // set-cookie
            let setCookieRes = await newAxios.get(avukatBetaUrl+'/web.uyap', {
                params: {
                    action:'initialize-signing',
                    signatureType: 1,
                    certificateBase64: app.$uyapHelper.hexToBase64(thirdResData.certificateHex)
                }
            });
            window.sessionStorage.setItem("JSESSIONID", setCookieRes.headers.cookie);
            let setCookieResData = JSON.parse(setCookieRes.data);
            let fourthFormData = new FormData();
            let fourthReqData = {
                "library": window.atob(secondResData.library),
                "slotId": window.atob(secondResData.slotId),
                "certSerialNumber": window.atob(thirdResData.serialNumber),
                "dataBase64": setCookieResData.digestBase64,
                "pincode": pinCode,
                "addSigningTime": true,
                "action": "signPKCS1Padding1.5",
                "licenseKey": licenseKey,
                "usedUrl": "https://avukatbeta.uyap.gov.tr/giris",
                "transactionUUID": myuuid,
                "nonce": thirdResData.nonce
            };
            fourthFormData.append('parameters', window.btoa(JSON.stringify(fourthReqData)));
            let fourthRes = await axios.post(uyapSignerUrl, fourthFormData);
            let fourthResData=JSON.parse(fourthRes.data.substring(1, fourthRes.data.length-1))[0];
            if (fourthResData.responseCode == "27") {
                localStorage.removeItem(atob(thirdResData.serialNumber));
                throw {type: 'pin', message: 'PIN Yanlış', serialNumber: window.atob(thirdResData.serialNumber)};
            }
            else if (fourthResData.responseCode == "16") {
                throw {type: 'signatureLocked', message: 'İmza Kilitli', serialNumber: window.atob(thirdResData.serialNumber)};
            }
            await newAxios.get(avukatBetaUrl+'/web.uyap', {
                params: {
                    signatureBase64: fourthResData.signature,
                    transactionUUID: setCookieResData.transactionUUID,
                    certificateBase64: thirdResData.commonName,
                    action:'finalize-signing'
                }
            });
            await newAxios.post(avukatBetaUrl+'/login.uyap', {});
            let counter = 0;
            const checkSession = () => {
                app.$uyap.AvukatKisiselBilgileriSorgulama().run({}).then(avukat => {
                    this.imzaLoginWaiting = false;
                    app.$store.commit('setUyapAvukatBilgileri',avukat);
                    return avukat;
                }).catch((avukatErr) => {
                    console.log("CATCH AVUKAT", avukatErr);
                    if (counter > 5) {
                        this.imzaLoginWaiting = false;
                        throw new Error('UYAP oturumuna erişilemedi.');
                    } else {
                        counter++;
                        window.setTimeout(() => {
                            checkSession();
                        }, 3000)
                    }
                })
            }
            checkSession();
            // let cookies = await newAxios.get(avukatBetaUrl+'/getCookies');
            // let cookiesArr = JSON.parse(cookies.data).cookies.split('; ');
            // let jsessionId = cookiesArr.find((x:string)=>x.includes('JSESSIONID'));
            // window.sessionStorage.setItem("JSESSIONID", jsessionId);
            // app.$uyapHelper.setCookie("JSESSIONID", jsessionId.replace("JSESSIONID=", ""), 1);
        } catch (e) {
            console.log("ERROR", e);
            if (e?.type == "pin")
                localStorage.removeItem(e.serialNumber);
            if (e?.type == "unauthorized") {
                await Swal.fire({
                    title: "İzinsiz İmza",
                    text: "Hesabınıza tanımlı kimlik numarası imza ile eşleşmiyor.",
                    icon: "warning",
                    allowOutsideClick: false,
                    confirmButtonText: 'Tamam'
                });
            } else {
                await Swal.fire({
                    title: e.message,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: 'Tamam'
                });
            }
            this.imzaLoginWaiting = false;
            throw new Error(e.message);
        }
    }

    fastLogin(uyapPlatform: number) {
        return new Promise((s, f) => {
            if (this.imzaLoginWaiting == true)
                return f({message: this.waitingFailMessage});
            this.imzaLoginWaiting = true;
            axios.post(imzaciUrl + 'xml/sessionFast').then((response) => {
                const cookieSetUrl = proxyBaseUrl + uyapPlatform.toString() + '/cookie?' + window.atob(response.data) + ';SameSite=None;Secure';
                axios.post(cookieSetUrl).then(() => {
                    this.imzaLoginWaiting = false;
                    return s(response.data);
                });
            }).catch((e) => {
                if (e.message != this.waitingFailMessage) {
                    this.imzaLoginWaiting = false;
                }
                f(e);
            });
        });
    }

    mesajImzala() {
        const myXml = '<root>' +
            '<auth><token></token></auth>' +
            '<data><message>Emre deneme imzala</message></data>' +
            '</root>';
        //readResult = reader.result.split(',')[1];
        axios.post(imzaciUrl + "xml/mesajImzala", myXml, {
            headers: {
                "Content-Type": "application/xml;charset=UTF-8"
            }
        })
    }

    dokumanImzala(data: Blob) {
        return new Promise((resolve, reject) => {
            if (this.simulator) {
                resolve("");
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(data); //udf base64 ceviriliyor..
            reader.onloadend = function () {
                //console.log("reader Result",reader.result);
                let b64Udf;
                if (typeof reader.result == "string") {
                    b64Udf = reader.result.split(',')[1];
                } else {
                    return reject('Dosya okunurken hata oluştu');
                }
                //console.log("b64UDF", b64Udf);

                //127.0.0.1:4723/xml/dokumanImzala
                //* blob data reader ile base 64 e ceviriliyor imzaciya gonderiliyor...

                const myXml = '<root>' +
                    '<auth><token></token></auth>' +
                    '<data><code>' + b64Udf + '</code></data>' +
                    '</root>';
                //readResult = reader.result.split(',')[1];
                axios.post(imzaciUrl + "xml/b64imzala", myXml, {
                    headers: {
                        "Content-Type": "application/xml;charset=UTF-8"
                    }
                }).then((data) => {
                    let parseString = xml2js.parseString;
                    parseString(data.data, {explicitArray: false}, (error: Error | null, result: any) => {
                        if (error) {
                            return reject(error);
                        }
                        //console.log("RESULT IMZALANMA", result);
                        return resolve(result.root.file);
                    });
                }).catch(function (err) {
                    if (err.response) {
                        let parseString = xml2js.parseString;
                        parseString(err.response.data, {explicitArray: false}, (error: Error | null, result: any) => {
                            app.$toast.error("Hata Kodu " + err.response.status + ": " + result.root.error_title);
                        });
                    } else if (err.request) {
                        app.$toast.error("İmzacı bağlantı hatası.");
                    } else {
                        app.$toast.error("Bilinmeyen hata (" + err.message + ")");
                    }
                    return reject(err);
                });

            };
        });
    }

    getFileFromLocalPath(path: string) {
        return new Promise((resolve, reject) => {
            let myXml = "<root>\n" +
                "<auth>\n" +
                "<token></token>\n" +
                "<hash></hash>\n" +
                "</auth>\n" +
                "<data>\n" +
                "<b64Xml>" + Mixins.utf8_to_b64("<path>" + path + "</path>") + "</b64Xml>\n" +
                "</data>\n" +
                "</root>"
            axios.post(imzaciUrl + "xml/getXmlContent", myXml, {
                headers: {
                    "Content-Type": "application/xml;charset=UTF-8"
                }
            }).then((data) => {
                let replacedData = String(data.data).replace(/\n/g, "");
                let parseString = xml2js.parseString;
                parseString(replacedData, {explicitArray: false}, (error: Error | null, jsonData: any) => {
                    if (error) {
                        return reject(error);
                    }
                    if (typeof jsonData.root.file != "undefined") {
                        return resolve(jsonData.root);
                    } else {
                        return reject({message: "Dosya bulunamadı !"});
                    }
                });
            }).catch(err => {
                return reject(err);
            })
        });
    }

    serialize(obj: any) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

}

export default function ImzaClientPlugin(Vue: typeof _Vue): void {
    // do stuff with options
    Vue.prototype.$imza = new ImzaClient();
}
