export class MessageLogger {
    messages: {
        type: 'info' | 'error' | 'success',
        message: string,
    }[] = [];

    constructor() {
        this.messages = [{type: 'info', message: 'Bekleniyor!'}];
    }

    /**
     * @param {'info'||'error'||'success'} type
     * @param {string} message
     */
    addMessage(type: 'info' | 'error' | 'success', message: any) {
        if (typeof message == "string") {
            this.messages.push({type, message});
        }

    }

    getLastMessage() {
        return this.messages[this.messages.length - 1].message;
    }
}
