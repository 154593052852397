import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface PortalAvukatTalepMalTuruListTalep {
    // Boş
}

export type PortalAvukatTalepMalTuruListCevap = Array<PortalAvukatTalepMalTuruListCevapRow>;

export interface PortalAvukatTalepMalTuruListCevapRow {
    name: string,
    value: number
}

export default class PortalAvukatTalepMalTuruList extends UyapRequest<PortalAvukatTalepMalTuruListTalep, PortalAvukatTalepMalTuruListCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "PortalAvukatTalepMalTuruList_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getPortalAvukatTalepMalTuruList_brd.ajx";
    }

    async parse(data: any): Promise<PortalAvukatTalepMalTuruListCevap> {
        return data;
    }

    getMockResponse(): any {
        return [{"name": "Taşınmaz", "value": 1}, {"name": "Taşıt", "value": 3}, {"name": "Taşınır", "value": 0}];
    }
}