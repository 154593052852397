class UyapHtmlParser {
    static normalizeXmlSourcedJson(obj: any): any {
        let keys = Object.keys(obj);
        let normalized: any = {};
        keys.forEach((key) => {
            normalized[key] = obj[key];
        })
        return normalized;
    }

    static parseAttributeCallbackArgs(htmlElement: HTMLElement): string[] {
        // @ts-ignore
        let line = htmlElement.attributes.onclick.value;
        let params = line.split("'").filter((i: any, index: number) => index % 2 == 1);
        return params;
    }

    static nodeListMap<T>(items: NodeList, callbackfn: (value: HTMLElement) => T | undefined): T[] {
        let arr: T[] = [];
        items.forEach((node: Node) => {
            let item = callbackfn(node as HTMLElement);
            if (item) {
                arr.push(item);
            }
        })
        return arr;
    }
}

export default UyapHtmlParser;
