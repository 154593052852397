import {UyapRequest} from "./UyapRequest";

export interface PortalAvukatTalepTebligatTuruListTalep {
    // Boş
}

export type PortalAvukatTalepTebligatTuruListCevap = Array<PortalAvukatTalepTebligatTuruListCevapRow>;

export interface PortalAvukatTalepTebligatTuruListCevapRow {
    masrafAdi: string,
    fiyati: number,
    postaMasraflariId: number
}

export default class PortalAvukatTalepTebligatTuruList extends UyapRequest<PortalAvukatTalepTebligatTuruListTalep, PortalAvukatTalepTebligatTuruListCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "PortalAvukatTalepTebligatTuruList"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getPortalAvukatTalepTebligatTuruList_brd.ajx";
    }

    async parse(data: any): Promise<PortalAvukatTalepTebligatTuruListCevap> {
        return data
    }

    getMockResponse(): any {
        return [{"masrafAdi":"E-Tebligat","fiyati":10,"postaMasraflariId":0},{"masrafAdi":"Normal Tebligat","fiyati":120,"postaMasraflariId":1},{"masrafAdi":"T.K.21/2 Şerhli","fiyati":120,"postaMasraflariId":2},{"masrafAdi":"Hızlı Tebligat","fiyati":240,"postaMasraflariId":3},{"masrafAdi":"T.K.21/2 Şerhli Hızlı","fiyati":240,"postaMasraflariId":4},{"masrafAdi":"35. Maddeye Göre Tebligat","fiyati":120,"postaMasraflariId":5},{"masrafAdi":"35. Maddeye Göre Hızlı Tebligat","fiyati":240,"postaMasraflariId":6}];
    }

}