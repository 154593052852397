import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface IcraTakipMahiyetleriTalep {
    takipTuru: number,//1
    takipYolu: number,// 0
    takipSekli: number// 0
}

export interface IcraTakipMahiyetleriCevap {
    items: Array<IcraTakipMahiyetleriCevapRow>
}

export interface IcraTakipMahiyetleriCevapRow {
    name: string,
    value: number
}

export default class IcraTakipMahiyetleri extends UyapRequest<IcraTakipMahiyetleriTalep, IcraTakipMahiyetleriCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "icra_takip_mahiyetleri" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "icra_takip_mahiyetleri.ajx";
    }

    async parse(data: any): Promise<IcraTakipMahiyetleriCevap> {
        return {items: data}
    }

    getMockResponse(): any {
        return [
            {"name": "Belgesiz", "value": 1307},
            {"name": "Cocuk Teslimi", "value": 1107},
            {"name": "Diğer", "value": 1407},
            {"name": "Doğal Gaz", "value": 6007},
            {"name": "Elektrik", "value": 5007},
            {"name": "İnternet/Tv", "value": 3007},
            {"name": "Kredi Kartı", "value": 7007},
            {"name": "Kredi Sözleşmesi", "value": 8008},
            {"name": "Nafaka", "value": 9009},
            {"name": "Su", "value": 4007},
            {"name": "Telefon(Cep)", "value": 2007},
            {"name": "Telefon(Sabit)", "value": 1007},
            {"name": "Tük.Hakem Heyeti", "value": 1207}
        ];
    }
}