export enum Product {
    OFISPRO=1,
    ICRAPROWEB,
    EICRAPRO,
    ARABULUCUPRO,
    DAVAPROWEB
}

export enum PathToProduct {
    "ofispro" = 1,
    "icrapro",
    "eicrapro",
    "arabulucupro",
    "davapro",
}