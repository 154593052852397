import {UyapRequest} from "./UyapRequest";
import {IHarcMasraf} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export interface HarcHesaplaTalep {
    IcraDosyaBilgileri: any,
    TarafList: any,
    IlamsizList: any,
    IlamliList: any,
    TahsilatList: any
}

export interface HarcHesaplaCevap {
    toplam: number,
    harclar: Array<IHarcMasraf>
}

export default class JsonHarcHesapla extends UyapRequest<HarcHesaplaTalep, HarcHesaplaCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [[{
            "harcmasrafKodStr": 71961,
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": 44.4,
            "hesapMiktar": 44.4,
            "ekranID": 8,
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": 0,
            "hmAktif": false,
            "isSatirDegisecek": false,
            "harcMasrafKod": 12805,
            "harcMasrafAdi": "BaÅŸvurma HarcÄ±",
            "aciklama": "Takibe BaÅŸvurulurken Ã–denen HarÃ§ MiktarÄ±",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 0,
            "gv_tabi": 0,
            "dv_tabi": 0,
            "yg_tabi": 0,
            "makbuzTipi": 1
        }, {
            "harcmasrafKodStr": 14603,
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": 0.5,
            "hesapMiktar": 2.90,
            "ekranID": 8,
            "pesinHarcDusulsunmu": "H",
            "harcMasrafAltSiniri": 0,
            "hmDurumu": 0,
            "hmAktif": false,
            "isSatirDegisecek": false,
            "harcMasrafKod": 12808,
            "harcMasrafAdi": "PeÅŸin HarÃ§",
            "aciklama": "Takip Talebi AÃ§Ä±lÄ±rken AlÄ±nÄ±r",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 2,
            "gv_tabi": 0,
            "dv_tabi": 0,
            "yg_tabi": 0,
            "makbuzTipi": 1
        }, {
            "harcmasrafKodStr": 71965,
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": 6.4,
            "hesapMiktar": 6.4,
            "ekranID": 8,
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": 0,
            "hmAktif": false,
            "isSatirDegisecek": false,
            "harcMasrafKod": 12809,
            "harcMasrafAdi": "Vekalet Suret HarcÄ±",
            "aciklama": "Vekaletname Suret HarcÄ±dÄ±r",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 14,
            "gv_tabi": 0,
            "dv_tabi": 0,
            "yg_tabi": 0,
            "makbuzTipi": 1
        }, {
            "hesapMiktar": 10.1,
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": 0,
            "hmAktif": false,
            "isSatirDegisecek": false,
            "harcMasrafAdi": "Vekalet Pulu",
            "harcMasraf": "D",
            "gv_tabi": 0,
            "dv_tabi": 0,
            "yg_tabi": 0
        }], 63.80];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'icra_harc_hesaplama_islemleri.ajx';
    }

    async parse(data: any): Promise<HarcHesaplaCevap> {
        return {
            toplam: data[1],
            harclar: data[0]
        }
    }
}
