import {UyapRequest} from "./UyapRequest";

export interface ParaBirimleriTalep {
    // Boş
}

export interface ParaBirimleriCevap {
    items: Array<ParaBirimleriCevapRow>
}

export interface ParaBirimleriCevapRow {
    name: string,
    value: string
}

export default class ParaBirimleri extends UyapRequest<ParaBirimleriTalep, ParaBirimleriCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ParaBirimleri"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "get_para_birimleriJSON.ajx";
    }

    async parse(data: any): Promise<ParaBirimleriCevap> {
        return {items: data}
    }

    getMockResponse(): any {
        return [
            {"name": "DENEME", "value": "PRBRMDDD"},
            {"name": "ANDORRA PESETA\u0027SI", "value": "PRBRMADP"},
            {"name": "UAE DİRHAM\u0027I", "value": "PRBRMAED"},
            {"name": "AFGHANİ", "value": "PRBRMAFA"},
            {"name": "ARNAVUTLUK LEK\u0027İ", "value": "PRBRMALL"},
            {"name": "DRAM", "value": "PRBRMAMD"},
            {"name": "ARJANTİN PESO\u0027SU", "value": "PRBRMARA"},
            {"name": "YUGOSLAVYA DİNAR\u0027I", "value": "PRBRMYUD"},
            {"name": "GÜNEY AFRİKA RAND\u0027I", "value": "PRBRMZAR"},
            {"name": "ZAMBİYA KWACHA\u0027SI", "value": "PRBRMZMK"},
            {"name": "ZİMBABVE DOLARI", "value": "PRBRMZWD"},
            {"name": "TRL - Türk Lirası", "value": "PRBRM01"}
        ];
    }

}