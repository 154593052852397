
import {Component, Vue,} from "vue-property-decorator";

@Component({
  components: {}
})
export default class ImzaUyapConnectionInfo extends Vue {
  get isImzaConnected() {
    return this.$store.getters.isImzaConnected;
  }

  get isUyapConnected() {
    return this.$store.getters.isUyapConnected;
  }

  connectToUyap() {
    this.$socket.client.emit('connectToUyap');
  }

  async mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('checkUyapSession');
    });
  }

  openUyap() {
    this.$socket.client.emit('openUyapWindow');
  }
}
