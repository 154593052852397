import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface IldekiAdliyelerTalep {
    il: number
}

export type IldekiAdliyelerCevap = Array<IAdliye>;

interface IAdliye {
    birimAdi: string,
    birimId: string,
    orgKodu: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    isTumunuKopyala: boolean,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: number
}

export default class IldekiAdliyeler extends UyapRequest<IldekiAdliyelerTalep, IldekiAdliyelerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ildekiAdliyeler_" + this.talepData.il
        };
    }

    getMockResponse(): any {
        return [{"birimAdi":"Şereflikoçhisar Adliye","birimId":"1000555","orgKodu":"1.04.005.008.4001","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"Ankara Adliye","birimId":"1000937","orgKodu":"1.04.011.000.4001","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"Kızılcahamam Adliye","birimId":"1001096","orgKodu":"1.04.134.006.4001","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "ildekiAdliyeleriGetir.ajx";
    }

    async parse(data: any): Promise<IldekiAdliyelerCevap> {
        return data;
    }
}