import Args from "./Args";
import {Mixins} from "../Mixins";
import AlacakKalem from "./AlacakKalem";
import TarafKisi from "./TarafKisi";
import TarafKurum from "./TarafKurum";

export default class Ilam {
    ilamBilgileri: {
        yargiTuru: string,
        yargiBirimi: string,
        dosyaYil: string,
        dosyaSira: string,
        ilamTuru: string,
    } = {
        yargiTuru: "",
        yargiBirimi: "",
        dosyaYil: "",
        dosyaSira: "",
        ilamTuru: ""
    }
    id!: number;//id;
    ilamTuru: any;//ilamTuru;
    ilamTuruText: string = ''//ilamTuruText;
    birimId: any;//birimId;
    birimAdi: string = ''//birimAdi;//Ankara 24. Asliye Hukuk Mahkemesi;
    dosyaTurKod: string = ''//dosyaTurKod;//15;
    dosyaTurKodText: string = ''//dosyaTurKodText;//Hukuk Dava Dosyası;
    dosyaNo: string = ''//dosyaNo;//2017/309;
    dosyaYil: string = ''//dosyaYil;//2017;
    dosyaSira: string = ''//dosyaSira;//309;
    yevmiyeYil: string = ''//yevmiyeYil;//;
    yevmiyeSira: string = ''//yevmiyeSira;//;
    ilamliKurumAdi: string = ''//ilamliKurumAdi;//;
    ilamliAciklama: string = ''//ilamliAciklama;//Ankara 24. Asliye Hukuk Mahkemesi'nin 2017/309 E.; 2018/68 K. s.lı ilamı gereğince 5000 TL vekalet ücretinin tahsili istemidir.;
    kararDosyaYil: string = ''//kararDosyaYil;//;
    kararDosyaSira: string = ''//kararDosyaSira;//;
    ilamTarihi: string = ''//ilamTarihi;//;
    ilamliParaylaOlculemeyenAlacak: string = ''//ilamliParaylaOlculemeyenAlacak;//;
    alacakKalemleri: AlacakKalem[] = []//alacakKalemleri;

    static fromJson(jsonData: any): Ilam {
        let obj = Args.staticFromJson(jsonData,new Ilam());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x: AlacakKalem) => AlacakKalem.fromJson(x));
        return obj;
    }

    static parse(ilamNode: HTMLUnknownElement,
                 args: Args,
                 tarafList: Array<TarafKisi | TarafKurum>): Ilam {
        args.setIlamli();
        let ilamliParaylaOlculemeyenAlacak = "";
        let paraylaOlculemeyenAlacaklar = ilamNode.querySelectorAll("takipSekliIlamliParaylaOlculemeyen");
        paraylaOlculemeyenAlacaklar.forEach((paraylaOlculemeyenAlacak, key) => {
            if (key > 0)
                ilamliParaylaOlculemeyenAlacak += ",";
            ilamliParaylaOlculemeyenAlacak += paraylaOlculemeyenAlacak.getAttribute("aciklama");
        });
        let ilam = new Ilam();

        ilam.id = args.ilamCounterId();
        ilam.ilamTuru = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamkurumtip'));
        // @ts-ignore
        ilam.ilamTuruText = ilam.getIlamKurumTurleri().find(x => x.value == ilam.ilamTuru).name;
        ilam.birimId = Mixins.nullUndefCleaner(ilamNode.getAttribute('birimid'));
        ilam.birimAdi = Mixins.nullUndefCleaner(ilamNode.getAttribute('birimadi'));
        ilam.dosyaTurKod = Mixins.nullUndefCleaner(ilamNode.getAttribute('dosyaturkod'));
        ilam.dosyaTurKodText = Mixins.nullUndefCleaner(ilamNode.getAttribute('dosyaturkodtext'));

        ilam.dosyaYil = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamdosyanoyil'));
        ilam.dosyaSira = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamdosyasira'));
        ilam.kararDosyaYil = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamkararnoyil'));
        ilam.kararDosyaSira = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamkararsira'));

        ilam.dosyaNo = ilam.dosyaYil.toString() + '/' + ilam.dosyaSira.toString();
        ilam.ilamliKurumAdi = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamkurumad'));
        ilam.ilamliAciklama = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamaciklama'));
        ilam.ilamTarihi = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamtarihi'));
        ilam.ilamliParaylaOlculemeyenAlacak = ilamliParaylaOlculemeyenAlacak;

        if (ilam.birimId == "") {
            ilam.ilamTuru = 6;
            ilam.ilamTuruText = "Diğer";
            ilam.birimAdi = "";
            ilam.dosyaTurKod = "";
            ilam.dosyaTurKodText = "";
            ilam.dosyaTurKodText = "";
        }

        ilam.ilamBilgileri.yargiTuru = Mixins.nullUndefCleaner(ilamNode.getAttribute('yargiBirimiId'));
        ilam.ilamBilgileri.yargiBirimi = Mixins.nullUndefCleaner(ilamNode.getAttribute('yargiBirimi'));
        ilam.ilamBilgileri.dosyaYil = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamDosyaNoYil'));
        ilam.ilamBilgileri.dosyaSira = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamDosyaSira'));
        ilam.ilamBilgileri.ilamTuru = Mixins.nullUndefCleaner(ilamNode.getAttribute('ilamKurumTip'));

        let altinciMadde = Mixins.nullUndefCleaner(ilamNode.getAttribute("altinciMadde"));
        if (altinciMadde)
            ilam.ilamliAciklama = altinciMadde;

        ilam.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(ilamNode, args, tarafList);
        return ilam;
    }

    getIlamKurumTurleri() {
        return [
            {name: 'Hukuk Mahkemesi', value: 0},
            {name: 'Ceza Mahkemesi', value: 1},
            {name: 'İdare Mahkemesi', value: 2},
            {name: 'Noter', value: 3},
            {name: 'Hakem Kararı', value: 4},
            {name: 'Arabuluculuk', value: 5},
            {name: 'Diğer', value: 6}
        ]
    }

    toUyapJson() {
        let {
            birimAdi, birimId, dosyaNo, dosyaSira, dosyaTurKod, dosyaTurKodText, dosyaYil, id,
            ilamliAciklama, ilamliKurumAdi, ilamliParaylaOlculemeyenAlacak,
            ilamTarihi, ilamTuru, ilamTuruText, kararDosyaSira, kararDosyaYil, yevmiyeSira, yevmiyeYil
        } = this;
        console.log("alacakKalemleri", this.alacakKalemleri);
        return {
            alacakKalemleri: this.alacakKalemleri.map(i => i.toUyapJson()),
            birimAdi,
            birimId,
            dosyaNo,
            dosyaSira,
            dosyaTurKod,
            dosyaTurKodText,
            dosyaYil,
            id,
            ilamliAciklama,
            ilamliKurumAdi,
            ilamliParaylaOlculemeyenAlacak,
            ilamTarihi,
            ilamTuru,
            ilamTuruText,
            kararDosyaSira,
            kararDosyaYil,
            yevmiyeSira,
            yevmiyeYil
        };
    }
}

