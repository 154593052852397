import {UyapRequest} from "./UyapRequest";

export default class SetCookie extends UyapRequest<any, any> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: ""
        };
    }

    getMockResponse(): any {
        return [];
    }

    getUrl(): string {
        return this.talepData.url;
    }

    async parse(data: string): Promise<any> {
        return data
    }
}
