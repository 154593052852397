import Vue from 'vue'
import router from "@/router";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {store} from '@/store';
import Vuelidate from "vuelidate";
import helper from './plugins/helper';
import {AxiosPlugin} from './plugins/axios';
// @ts-ignore
import excel from 'vue-excel-export'
import imza from './plugins/uyap-plugin/imza'
import uyap from './plugins/uyap-plugin/uyap'
import uyapHelper from './plugins/uyap-plugin/helper'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import VueApexCharts from 'vue-apexcharts'

const toastOptions = {
    // Default bildirim ayarları
    transition: "Vue-Toastification__fade",
    position: 'top-right',
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    // Custom icon kullanımı
    // icon: {
    //     iconClass: 'mdi mdi-eye',
    //     iconTag: 'span'
    // },
    maxToasts: 5
}

Vue.use(AxiosPlugin);
Vue.use(Vuelidate);
Vue.use(helper);
Vue.use(excel);
Vue.use(imza);
Vue.use(uyap);
Vue.use(uyapHelper);
Vue.use(Toast, toastOptions);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
store.commit("setRouter", router);
const app: Vue = new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
export default app;
