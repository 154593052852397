import {UyapRequest} from "./UyapRequest";

export interface KisiMernisAdresiVarmiTalep {
    tcKimlikNo: any
}

export type KisiMernisAdresiVarmiCevap = boolean

export default class KisiMernisAdresiVarmi extends UyapRequest<KisiMernisAdresiVarmiTalep, KisiMernisAdresiVarmiCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "KisiMernisAdresiVarmi_" + this.talepData.tcKimlikNo
        };
    }

    getMockResponse(): any {
        return true;
        // return false;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsMernisAdresiKontrol_brd.ajx';
    }

    async parse(data: boolean): Promise<KisiMernisAdresiVarmiCevap> {
        return data;
    }

}