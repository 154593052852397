export enum TakipAcStatusEnum {
    QUERY_PENDING = 'query_pending',
    QUERY_FAIL = 'query_fail',
    QUERY_SUCCESS = 'query_success',
    PREPARE_PROCESSING = 'prepare_processing',
    PREPARE_FAILED = 'prepare_failed',
    PREPARE_SUCCESS = 'prepare_success',
    OPEN_PROCESSING = 'open_processing',
    OPEN_FAIL = 'open_fail',
    OPEN_SUCCESS = 'open_success',
    TAHSILAT_FAIL = 'tahsilat_fail'
}
