export class Mixins {
    static faizSureTipConverter(sureTip: string): string {
        if (sureTip == "2")
            return "Yıllık";
        if (sureTip == "1")
            return "Aylık";
        return "";
    }

    static utf8_to_b64(str: any) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    static onlyLetters(text: string): string {
        return text.replace(/[^A-Za-z]/g, "");
    }

    static onlyDigit(text: any): string {
        if (text == null || text == undefined)
            return "";
        return text.replace(/[^0-9]/g, "");
    }

    static nullUndefCleaner(obj: any): string {
        //// console.log("obj",obj);
        if (obj == undefined || obj == null) {
            return "";
        } else {
            return String(obj).trim();
        }
    }


    static checkTcNum(value: string): boolean {
        if (value == null || value == undefined)
            return false;
        value = value.toString();
        let isEleven = /^[0-9]{11}$/.test(value);
        let totalX = 0;

        for (let i = 0; i < 10; i++) {
            totalX += Number(value.substr(i, 1));
        }
        //@ts-ignore
        let isRuleX = totalX % 10 == value.substr(10, 1);
        let totalY1 = 0;
        let totalY2 = 0;

        for (let i = 0; i < 10; i += 2) {
            totalY1 += Number(value.substr(i, 1));
        }

        for (let i = 1; i < 10; i += 2) {
            totalY2 += Number(value.substr(i, 1));
        }
        //@ts-ignore
        let isRuleY = ((totalY1 * 7) - totalY2) % 10 == value.substr(9, 0);
        return isEleven && isRuleX && isRuleY;

    }

    static titleConverter(search: string, searchData: any) {
        let keys = Object.keys(searchData);
        let values = Object.values(searchData);
        for (let i = 0; i < keys.length; i++) {
            if (search == keys[i]) {
                return values[i];
            }
        }
        return null;
    }

    static convertAlacakKalemTutar(tutar: any) {
        tutar = parseFloat(tutar.toString()).toFixed(2); //EUP 237
        return parseFloat(tutar).toFixed(2); // 0- 1 arasi tutarlarda parseFloat 0 olarak geliyordu ,den sonrasi gelsin diye bu yuzden bu sekilde revize ettim.
    }

    static replaceCommaDots(value: any) {
        if (value == null || value == undefined)
            return "";
        return value.replace(",", ".");//Neye gore değişiyor belli değil . ile gönderiyorum hep .//.replace(".",""); //double 35.00 // 3500 olarak gidiyor...
    }

    static meblagiReplaceCommaDots(itemKey: string, itemValue: string) {
        if (itemKey == "meblagi" || itemKey == "tutarTL") {
            itemValue = itemValue.replace(",", ".");//Neye gore değişiyor belli değil . ile gönderiyorum hep .//.replace(".",""); //double 35.00 // 3500 olarak gidiyor...
        }
        return itemValue;
    }

    static setDefaultHesapSecenegi(value: any) {
        sessionStorage.setItem("defaultHesapSecenegi", value);
    }

    static getDefaultHesapSecenegi() {
        if (sessionStorage.hasOwnProperty("defaultHesapSecenegi")) {
            return sessionStorage.getItem("defaultHesapSecenegi")
        }
        return "";
    }

    static hesapSecenegi(paraBirim: string, defaultHesapSecenegi: any = ""): any {
        if (Mixins.getDefaultHesapSecenegi() != "")
            return Mixins.getDefaultHesapSecenegi();
        let cevap = prompt("Alacak kalemi parabirimi '" + paraBirim + "' döviz olduğu için hesap seçeneği belirlemeniz gerekmektedir.\nLütfen belirlediğiniz seçeneği 0 veya 1 olarak kodlayınız. \n 0 - Takip Tarihi  \n 1 - Fiili Ödeme Tarihi", "1");
        // @ts-ignore
        if (cevap < 0 && cevap > 2)
            Mixins.hesapSecenegi(paraBirim);
        let defaultUygulansin = confirm("Alacak kalemi parabirimi '" + paraBirim + "' hesap seçeği olarak tüm alacak kalemlerinde ve dosyalarda aynı seçenek seçilsin mi ?");
        if (defaultUygulansin == true)
            Mixins.setDefaultHesapSecenegi(cevap);
        return cevap;
    }

    static replaceHtmlCode(text: any): string {
        if (text == null || text == undefined)
            return "";
        return text.replace(/\%/g, "&#37;")
        //.replace(/\(/g,"&#40;")
        //.replace(/\)/g,"&#41;")
        //.replace(/\+/g,"&#43;")
        //.replace(/\-/g,"&#45;")
        //.replace(/\./g,"&#46;")
        //.replace(/\=/g,"&#61;")
        //.replace(/\:/g,"&#58;")
        //.replace(/\//g,"&#47;")
        //.replace(/\\/g,"&#92;");
    }

    static generateHash(args: any): string {
        let stringfyData = JSON.stringify(args);
        //@ts-ignore
        let hashedData = md5(stringfyData);
        return hashedData;
    }

    static tarihConverter(tarih: any): string {
        if (!tarih)
            return "";
        tarih = tarih.trim().replace(/\-/g, "/").replace(/\./g, "/").replace(/ /g, "/");
        let explode = tarih.split("/");
        let yil, ay, gun;
        ay = explode[1];
        explode.forEach((v: any, k: number) => {
            if (v.length == 4) {
                yil = v;
                if (k == 0) {
                    gun = explode[2];
                } else {
                    gun = explode[0];
                }
            }
        });
        if (gun == undefined || ay == undefined || yil == undefined)
            return "";
        else
            return gun + "/" + ay + "/" + yil;
    }

    static base64EvrakToObject(domEvrak: any): any {
        // console.log("domEvrak",domEvrak);
        if (typeof domEvrak != "undefined" && domEvrak != null && domEvrak != "") {
            let fileName = domEvrak.attributes['fileName'].value;
            let mimeType = Mixins.findFileMimeType(fileName);
            let b64Data = domEvrak.innerHTML;
            let response = {name: fileName, type: mimeType, data: b64Data};
            return response;
        }
        return {};
    }

    static findFileMimeType(fileName: string): string {
        fileName = fileName.toLowerCase();
        let dotExp = fileName.split(".");
        let ext = dotExp[dotExp.length - 1];
        //// console.log("ext",ext);
        let fileMimeTypes = [
            {
                ext: "udf",
                type: "application/octet-stream"
            },
            {
                ext: "pdf",
                type: "application/pdf"
            },
            {
                ext: "png",
                type: "image/png"
            },
            {
                ext: "jpg",
                type: "image/jpeg"
            },
            {
                ext: "jpeg",
                type: "image/jpeg"
            },
            {
                ext: "tiff",
                type: "image/tiff"
            },
            {
                ext: "tif",
                type: "image/tiff"
            },
        ];
        let obj = fileMimeTypes.find(x => x.ext == ext);
        if (obj) {
            return obj.type;
        } else {
            return "application/octet-stream";
        }
    }

    // @ts-ignore
    static booleanToXmlStrings(val: boolean): "E" | "H" {
        if (val === true) return "E";
        if (val === false) return "H";
    }

    /**
     * @param str // 'E' || 'H'
     * @return boolean
     */
    static xmlStringsToBoolean(str: any): boolean {
        if (str == 'E') return true;
        if (str == 'H') return false;
        return false
    }

    static getCleanAttribute(dom: any, attr: string) {
        let val = dom.getAttribute(attr);
        if (val == undefined) {
            val = "";
        }
        return val.trim();
    }

    static formatTelefonForMask(telefon: string): any {
        if (telefon.length == 11) {
            return "0 (" + telefon.substr(1, 3) + ") " + telefon.substr(4, 3) + "-" + telefon.substr(7, 4);
        }
    }

    //TakipAcInjectable:238
    static b64toBlob(b64Data: string, contentType: string, sliceSize: any): Blob {
        try {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;
            let byteCharacters = window.atob(b64Data);
            /*
            var byteCharacters =  "";
            try {
                byteCharacters = b64_to_utf8(b64Data);
            }catch (Exc)
            {
                byteCharacters = b64Data.Base64().decode();
            }
            */
            let byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                let byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, {type: contentType});
        } catch (ex) {
            throw new Error(ex.message)
        }
    }

    static fileMimeTypes = [
        {
            ext: "udf",
            type: "application/octet-stream"
        },
        {
            ext: "pdf",
            type: "application/pdf"
        },
        {
            ext: "png",
            type: "image/png"
        },
        {
            ext: "jpg",
            type: "image/jpeg"
        },
        {
            ext: "jpeg",
            type: "image/jpeg"
        },
        {
            ext: "tiff",
            type: "image/tiff"
        },
        {
            ext: "tif",
            type: "image/tiff"
        },
    ];

    static getFileExtension(mimeType: string): string {
        let obj = this.fileMimeTypes.find(x => x.type == mimeType);
        //// console.log("findFileExt",obj);
        if (obj) {
            return obj.ext;
        } else {
            return "udf";
        }
    }

    static getFileMimeType(fileName: string): string {
        fileName = fileName.toLowerCase();
        let dotExp = fileName.split(".");
        let ext = dotExp[dotExp.length - 1];
        //// console.log("ext",ext);
        let obj = this.fileMimeTypes.find(x => x.ext == ext);
        if (obj) {
            return obj.type
        } else {
            return "application/octet-stream";
        }
    }


}
