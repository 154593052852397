import {UyapRequest} from "./UyapRequest";

export interface KisiSorgulaTalep {
    tcKimlikNo: string
}

export interface KisiSorgulaCevap {
    olumKaydi: boolean,
    isAvukat: boolean,
    isNoter: boolean,
    isTarafUyari: boolean,
    isHakimSavci: boolean,
    mernisDegisiklikVarmi: boolean,
    mernisDegisiklikNedeni: string,
    sorguTuru: number,
    kisiKurumID: number,
    tcKimlikNo: string,
    adi: string,
    soyadi: string,
    babaAdi: string,
    anaAdi: string,
    dogumTarihiStr: string,
    cinsiyeti: string,
    aktifMernisAdresiSorgusu: boolean,
    hasOlumKaydi: boolean
}

export default class KisiSorgula extends UyapRequest<KisiSorgulaTalep, KisiSorgulaCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "kisi_" + this.talepData.tcKimlikNo
        };
    }

    getMockResponse(): any {
        return {"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"kisiKurumID":144140118,"tcKimlikNo":"57490126802","adi":"ÖM**","soyadi":"ŞE*","babaAdi":"SÜ******","anaAdi":"YI****","dogumTarihiStr":"*2/*3/1991","cinsiyeti":"E","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'kisiSorgula.ajx';
    }

    async parse(data: any): Promise<KisiSorgulaCevap> {
        return data;
    }

}
