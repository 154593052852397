export enum TaskDurum {
    SIRADA=1,
    ISLENIYOR,
    BITTI_BASARILI,
    BITTI_HATALI,
    ERTELENDI,
    DURAKLATILDI,
    IPTAL_EDILDI,
    BITTI_EKSIK,
    YENIDEN_BASLATILDI
}