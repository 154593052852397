import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface SorguDosyaKontrolTalep {
    dosyaList: string   // dosyaid
}

export interface SorguDosyaKontrolCevap {
    hasBarokart: boolean,
    sorguToplamIslemSayisi: number,
    sorguKalanIslemSayisi: number,
    ucretsizSorguLimit: number,
    ucretAlinacakDosyaSayisi: number,
    alinacakUcret: number
}

export default class SorguDosyaKontrol extends UyapRequest<SorguDosyaKontrolTalep, SorguDosyaKontrolCevap> {
    getUrl(): string {
        return this.ImzaClientAddr + "ws_sorgu_dosya_kontrolu_brd.ajx"
    }

    getMockResponse(): any {
        return {"hasBarokart":false,"sorguToplamIslemSayisi":1,"sorguKalanIslemSayisi":0,"ucretsizSorguLimit":0,"ucretAlinacakDosyaSayisi":1,"alinacakUcret":3};
    }

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache(): { active: boolean; ttl: number; key: string; } {
        return { active: false, ttl: 0, key: "_" }
    }

    async parse(data: any): Promise<SorguDosyaKontrolCevap> {
        return data;
    }
}