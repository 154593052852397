export enum DigerTalepEvrakTypeEnum {
    DosyaYenileme = "AvukatTalepDosyaYenilemeDvo",
    TakibinKesinlesmesi = "AvukatTalepGenelTakibiKesinlestirDVO",
    IbanGuncelleme = "AvukatTalepGenelIbanGuncelleDVO",
    YuzuncuMaddeyeYararBilgiIstenilmesi = "AvukatTalepGenel100MaddeYararBilgiDvo",
    AcizBelgesiHazirlanmasi = "AvukatTalepGenelAcizBelgesiDvo",
    AracUzerindekiHacizSerhininKaldirilmasi = "AvukatTalepGenelAracHacziKaldirDVO",
    AracUzerindekiYakalamaSerhininKaldirilmasi = "AvukatTalepGenelAracYakalamaKaldirDVO",
    BankadanHacizliParaninIstenilmesi = "AvukatTalepBankadanHacizliParaIsteDVO",
    BorclutarafindanYapilanOdemelerinHesabaAktarilmasi = "AvukatTalepGenelOdemeAktarDVO",
    DosyaHesabininYapilmasiVeDosyayaKaydedilmesi = "AvukatTalepGenelDosyaHesabiDVO",
    DosyadaHacizliMalinSatisi = "AvukatTalepGenelSatisTalebiDVO",
    DosyadakiAvansinIadesi = "AvukatTalepDosyaAvansIadesiDvo",
    DosyadakiHacizlerinKaldirilmasi = "AvukatTalepGenelTumHacziKaldirDVO",
    DosyaninIslemdenKaldirilmasi = "AvukatTalepGenelDosyaKapatDVO",
    DosyaninYetkiliIcraDairesineGonderilmesi = "AvukatTalepGenelYetkiliIcraDVO",
    HacizliMalinKiymetTakdiriIcinTalimatYazilmasi = "AvukatTalepGenelKiymetTakdiricinTalimatDVO",
    HacizliMalinSatisiIcinTalimatYazilmasi = "AvukatTalepGenelSatisicinTalimatDVO",
    HacizliTasinirMallarinMuhafazaAltinaAlinmasi = "AvukatTalepGenelTasinirMuhafazaDVO",
    HaricenTahsilatBildirimi = "AvukatTalepHaricenTahsilatDvo",
    IIk121MaddeyeGoreYetkiVerilmesi = "AvukatTalepGenelIIk121YetkiVerDvo",
    IIk150cSerhiEklenmesi = "AvukatTalepGenelIIk150CSerhiEkleDvo",
    KiymetTakdirininYapilmasi = "AvukatTalepGenelKiymetTakdiriDVO",
    MaasUzerindekiHacizlerinKaldirilmesi = "AvukatTalepGenelMaasHacziKaldirDVO",
    MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi = "AvukatTalepGenelYeddiEminDegisikligiDVO",
    RehinAcigiBelgesiHazirlanmasi = "AvukatTalepGenelRehinAcigiBelgesiDvo",
    RehininParayaCevrilmesiSerhi = "AvukatTalepGenelRehninParayaCevrilmesiSerhiDVO",
    TasinmazHaczininKaldirilmasi = "AvukatTalepGenelTasinmazHacziKaldirDVO"
}