import MtsTakipAc from "../../../takip_ac/MtsTakipAc";
import {MtsXmlParser} from "../../../takip_ac/MtsXmlParser";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class MtsTakipTevziAlTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let parsedFile: MtsXmlParser = MtsXmlParser.fromJson(task.data);
            let takip = new MtsTakipAc();
            takip.parsedFile = parsedFile;
            await takip.runQuery();
            await takip.tevziAl();
            task.response = takip;
            task.status = TaskDurum.BITTI_BASARILI;
            return task;
        } catch (e) {
            throw new Error(e.message);
        }
    }
}