import {UyapRequest} from "./UyapRequest";

export interface TahsilHarcOranlariniGetirTalep {
}

export type TahsilHarcOranlariniGetirCevap = Array<TahsilHarcOranlariniGetirRow>

export interface TahsilHarcOranlariniGetirRow {
    aciklama: string,
    oran: number,
    kayitId: number
}

export class TahsilHarcOranlariniGetir extends UyapRequest<TahsilHarcOranlariniGetirTalep, TahsilHarcOranlariniGetirCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "TahsilHarcOranlariniGetir"
        };
    }

    getMockResponse(): any {
        return [{"aciklama":"(%0) Harçtan Muaf","oran":0,"kayitId":1024},{"aciklama":"(%0.56) Tahsil Harci 492 S.K.nun 1.S.T.B.I.3/h bendi","oran":0.56,"kayitId":409606},{"aciklama":"(%1.13) Konkordatoda Alacaklilara Verilmesi Kararlastirilan Para Üzerinden","oran":1.13,"kayitId":5650},{"aciklama":"(%1.13) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi","oran":1.1375,"kayitId":409604},{"aciklama":"(%2.27) Takip Talebi Bulunmayan Alacaklılara İİK. 125/3 Maddesi Gereğince Ödenen Paralardan","oran":2.27,"kayitId":5648},{"aciklama":"(%2.27) Hacizden Önce Haricen Tahsilde","oran":2.275,"kayitId":5646},{"aciklama":"(%2,27) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi","oran":2.275,"kayitId":409605},{"aciklama":"(%2.84) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi","oran":2.845,"kayitId":409607},{"aciklama":"(%4.55) Maaş Ve Ücretlerden","oran":4.55,"kayitId":5647},{"aciklama":"(%4.55) Hacizden Sonra Satıştan Önce Haricen Tahsilde","oran":4.55,"kayitId":773},{"aciklama":"(%4.55) İflasta Paylaştırılan Para Üzerinden","oran":4.55,"kayitId":5649},{"aciklama":"(%4.55) Ödeme veya İcra Emrinin Tebliğinden Sonra, Hacizden Önce","oran":4.55,"kayitId":21},{"aciklama":"(%9.10) Hacizden Sonra, Satıştan Önce","oran":9.1,"kayitId":23},{"aciklama":"(%11.38) Hacizli veya Rehinli Malın Satışından Sonra","oran":11.38,"kayitId":22}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'tahsil_harc_oranlarini_getir.ajx';
    }

    async parse(data: any): Promise<TahsilHarcOranlariniGetirCevap> {
       return data;
    }
}

export default TahsilHarcOranlariniGetir;
