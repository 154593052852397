import app from "@/main";
import {DosyaBorclu} from "../DosyaBorclu";
import {ITarafBilgisi} from "../DosyaTarafBilgileri";
import {ITarafBilgisiDetayli} from "../GetDosyaTarafBilgileri";
import {ITask, Task} from "../../store/modules/GorevYoneticisiTypes";
import Args from "../../takip_ac/parser/Args";
import {IAvukatDosya, UyapTarih} from "../CommonInterfaces";

const DOSYA_KAC_GUN_SONRA_DUSECEK: number = 350;
export default class TakipDosya implements IAvukatDosya {
    sonIslemTarihindenItibarenGecenGunSayisi: number = 0;
    sonIslemTarihindenItibarenKalanGunSayisi: number = 0;
    // TODO: kullanılan yerler kontrol edilecek
    borclular: Array<DosyaBorclu> = [];
    tarafBilgileri: Array<ITarafBilgisi> = [];
    detayliTarafBilgileri: Array<ITarafBilgisiDetayli> = [];
    task!: Task;
    // IAvukatDosya
    birimAdi: string = "";
    birimId: string = "";
    birimTuru1: string = "";
    birimTuru2: string = "";
    birimTuru3: string = "";
    // @ts-ignore
    dosyaAcilisTarihi: UyapTarih = null;
    dosyaDurum: string = "";
    dosyaDurumKod: number = -1;
    dosyaId: string = "";
    // @ts-ignore
    dosyaKapanisTarihi: UyapTarih = null;
    dosyaNo: string = "";
    dosyaTur: string = "";
    dosyaTurKod: number = -1;
    isDavaDosyasiAcilmisMi: boolean = false;
    isNew: boolean = false;

    static fromJson(jsonData: IAvukatDosya | ITask): TakipDosya {
        let json = Args.staticFromJson(jsonData, new TakipDosya());
        if ((jsonData as ITask).dosya_id)
            json.dosyaId = (jsonData as ITask).dosya_id;
        if ((jsonData as ITask).dosya_esas_no)
            json.dosyaNo = (jsonData as ITask).dosya_esas_no;
        if ((jsonData as ITask).birim_adi)
            json.birimAdi = (jsonData as ITask).birim_adi;
        return json;
    }

    dosyaGuncelMi(): boolean {
        return this.sonIslemTarihindenItibarenGecenGunSayisi < DOSYA_KAC_GUN_SONRA_DUSECEK;
    }

    async dosyaDusecekMiSorgula(): Promise<void> {
        let acilisTarihi = new Date(app.$uyapHelper.formatDate(this.dosyaAcilisTarihi)).getTime();
        this.sonIslemTarihindenItibarenGecenGunSayisi = ((Date.now() - acilisTarihi) / 86400000);
        this.sonIslemTarihindenItibarenKalanGunSayisi = Math.floor(365 - this.sonIslemTarihindenItibarenGecenGunSayisi);
        if (this.dosyaGuncelMi()) {
            console.log("DOSYA GUNCEL..");
            return;
        }
        let dosyaEvraklarCevap = await app.$uyap.DosyaEvraklar().run({
            dosyaId: this.dosyaId
        })
        let sonIslemTarih = 1;
        dosyaEvraklarCevap.son20Evrak.forEach((evrak) => {
            let s = String(evrak.onaylandigiTarih).split("/");
            let d = s[1] + "/" + s[0] + "/" + s[2];
            let islemTarihi = new Date(d).getTime();
            if (islemTarihi > sonIslemTarih)
                sonIslemTarih = islemTarihi;
        })
        this.sonIslemTarihindenItibarenGecenGunSayisi = Math.floor((Date.now() - sonIslemTarih) / 86400000);
        this.sonIslemTarihindenItibarenKalanGunSayisi = Math.floor(365 - this.sonIslemTarihindenItibarenGecenGunSayisi);
        return;
    }

    async tarafBilgileriQuery() {
        let tarafBilgileri = await app.$uyap.DosyaTarafBilgileri().run({dosyaId: this.dosyaId});
        this.tarafBilgileri = tarafBilgileri;
        return this.tarafBilgileri;
    }

    async dosyaDetayliTarafBilgileriQuery() {
        let response = await app.$uyap.GetDosyaTarafBilgileri().run({dosyaId: this.dosyaId});
        this.detayliTarafBilgileri = response;
        return this.detayliTarafBilgileri;
    }

    async borclularQuery() {
        let borclular = await app.$uyap.DosyaBorclulari().run({
            dosyaId: this.dosyaId
        })
        this.borclular = borclular;
        return this.borclular;
    }
}