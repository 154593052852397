import {UyapRequest} from "./UyapRequest";

export interface AvukatKayitliIbanTalep {
    // yok
}

export interface AvukatKayitliIbanCevap {
    type: string,
    message: string,
    value: Array<IIban>,
    log: string
}

export interface IIban {
    kayitID: number,
    bankaAdi: string,
    hesapSahibininAdi: string,
    ibanNumarasi: string,
    hesapGenel: boolean,
    hesapAktifmi: string
}

export default class AvukatKayitliIbanList extends UyapRequest<AvukatKayitliIbanTalep, AvukatKayitliIbanCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "_AvukatKayitliIbanList"
        };
    }

    getMockResponse(): any {
        return {"type":"success","message":"","value":[{"kayitID":356199,"bankaAdi":"T.VAKIFLAR BANKASI T.A.O.","hesapSahibininAdi":"Nİ***ER*****","ibanNumarasi":"TR55******************8019","hesapGenel":true,"hesapAktifmi":"E"}],"log":""};
    }

    getUrl(): string {
        return this.ImzaClientAddr + "kayitliIbanListesi.ajx";
    }

    async parse(data: any): Promise<AvukatKayitliIbanCevap> {
        if (!data.message)
            data.message = "Sorgulama başarılı";
        return data;
    }
}