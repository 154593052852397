
import {Component, Vue} from "vue-property-decorator";
import {IBen} from "@/interface/IBen";
import {LisansEntity} from "@/entity/LisansEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import SifreDegistirForm from "@/components/forms/SifreDegistirForm.vue";

@Component({
  components: {SifreDegistirForm, FormDialog}
})
export default class HesapBilgileriView extends Vue {
  get userData(): IBen {
    return this.$store.getters.userData;
  }

  get lisanslar(): Array<LisansEntity> {
    return this.$store.getters.licenses;
  }

  // mounted(){
  //   this.$http.get('/api/v1/active-package/1')
  // }
}
