import {UyapRequest} from "./UyapRequest";
import HesapBilgisi from "../takip_ac/parser/HesapBilgisi";


export interface GetIbanDetailsTalep {
    iban: number | string,
    isSansurlenecek: boolean    // false
}

export class GetIbanDetails extends UyapRequest<GetIbanDetailsTalep, HesapBilgisi> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetIbanDetails_" + this.talepData.iban
        };
    }

    getMockResponse(): any {
        return {
            "type": "success",
            "message": "",
            "value":
                {
                    "bankaAdi": "T.VAKIFLAR BANKASI T.A.O.          ", "ibanNumarasi": "420001500158007301470680",
                    "hesapGenel": true
                }
            , "log": ""
        };
    }

    getUrl(): string {
        // TODO: t'yi unutmuşlar ilerde düzeltebilirler
        return this.ImzaClientAddr + 'geIbanDetails.ajx';
    }

    async parse(data: any): Promise<HesapBilgisi> {
        if (data.type == "success") {
            return data.value;
        } else {
            return Promise.reject(data);
        }
    }

}

export default GetIbanDetails;
