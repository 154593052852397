import {UyapRequest} from "./UyapRequest";

export interface MtsHarcListTalep {
    // Content-Type:  multipart/form-data; boundary=----WebKitFormBoundary72BTOZVc7YRxnyg9
    ilamsizList: string,    // Array<any>
    tarafList: string       // kisiList: any[], kurumList: any[]
}

export interface MtsHarcListCevap {
    data: Array<IMtsHarc>,
    toplam: number
}

interface IMtsHarc {
    harcmasrafKodStr: number,
    harcmasrafBedelmiOranmi: string,
    harcMasrafOran?: number,
    hesapMiktar: number,
    ekranID: number,
    pesinHarcDusulsunmu: string,
    harcMasrafAltSiniri?: number,
    hmDurumu: number,
    hmAktif: boolean,
    isSatirDegisecek: boolean,
    harcMasrafKod: number,
    harcMasrafAdi: string,
    aciklama: string,
    baslangicHarci: string,
    harcMasraf: string,
    harcTip: number,
    gv_tabi: number,
    dv_tabi: number,
    yg_tabi: number,
    makbuzTipi: number,
    harcMasrafBedel?: number
}

export default class MtsHarcList extends UyapRequest<MtsHarcListTalep, MtsHarcListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"harcmasrafKodStr":144142,"harcmasrafBedelmiOranmi":"O","harcMasrafOran":2,"hesapMiktar":427.60,"ekranID":9,"pesinHarcDusulsunmu":"H","harcMasrafAltSiniri":427.6,"hmDurumu":0,"hmAktif":false,"isSatirDegisecek":false,"harcMasrafKod":63266,"harcMasrafAdi":"MTS Harcı","aciklama":"Mts Harcı","baslangicHarci":"E","harcMasraf":"H","harcTip":34,"gv_tabi":0,"dv_tabi":0,"yg_tabi":0,"makbuzTipi":1},{"harcmasrafKodStr":144140,"harcmasrafBedelmiOranmi":"B","harcMasrafBedel":60.8,"hesapMiktar":60.8,"ekranID":9,"pesinHarcDusulsunmu":"H","hmDurumu":0,"hmAktif":false,"isSatirDegisecek":false,"harcMasrafKod":12809,"harcMasrafAdi":"Vekalet Suret Harcı","aciklama":"Vekaletname Suret Harcıdır","baslangicHarci":"E","harcMasraf":"H","harcTip":14,"gv_tabi":0,"dv_tabi":0,"yg_tabi":0,"makbuzTipi":1}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsHarcList_brd.ajx';
    }

    parse(data: any): Promise<MtsHarcListCevap> {
        return new Promise<MtsHarcListCevap>((resolve, reject) => {
            try {
                if (typeof data.error === undefined) {
                    return reject(data.error);
                }
                let toplam = 0;
                data.forEach((val: any) => {
                    toplam += val.hesapMiktar;
                })
                return resolve({data: data, toplam: toplam});
            } catch (e) {
                return reject(e);
            }
        });
    }

}

