import UyapTakipAc from "../../../takip_ac/UyapTakipAc";
import {TaskDurum} from "../../../enum/TaskDurum";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";

export class UyapTakipTevziAlTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            task.response =  UyapTakipAc.fromJson(task.data);
            console.log("takip", task.response);
            // await task.response.runQuery();
            await task.response.tevziAl();
            //await task.response.takipTalebiIndir();
            task.status = TaskDurum.BITTI_BASARILI;
            return task;
        } catch (e) {
            throw new Error(e.message);
        }
    }

}