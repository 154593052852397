import {UyapRequest} from "./UyapRequest";

export interface AdliyelerTalep {
    ilKodu: number
}

export type AdliyelerCevap = AdliyelerRow[];

export interface AdliyelerRow {
    gayriMenkulIcraDairesiSayisi: number,
    bankaIcraDairesiSayisi: number,
    adliyeBirimID: string,
    adliyeIsmi: string,
    tevziBelirlemeKriteri: number,
    kayitVar: boolean
}

export default class Adliyeler extends UyapRequest<AdliyelerTalep, AdliyelerCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "adliyeler_" + this.talepData.ilKodu
        };
    }

    getMockResponse(): any {
        return [{"gayriMenkulIcraDairesiSayisi":1,"bankaIcraDairesiSayisi":0,"adliyeBirimID":"1005433","adliyeIsmi":"Aliağa Adliye","tevziBelirlemeKriteri":0,"kayitVar":false},{"gayriMenkulIcraDairesiSayisi":1,"bankaIcraDairesiSayisi":0,"adliyeBirimID":"1006986","adliyeIsmi":"Bayındır Adliye","tevziBelirlemeKriteri":0,"kayitVar":false},{"gayriMenkulIcraDairesiSayisi":1,"bankaIcraDairesiSayisi":0,"adliyeBirimID":"1001833","adliyeIsmi":"Bergama Adliye","tevziBelirlemeKriteri":0,"kayitVar":false}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'icraTakipAdliyeler.ajx';
    }

    async parse(data: any): Promise<AdliyelerCevap> {
        return data;
    }
}
