export enum UyapHacizEvrakTuru {
    egm = 0,
    maas_haczi = 1,
    takbis = 2,
    posta_ceki_haciz = 3,
    banka_haczi = 4,
    sgk_mossip_haczi = 6,
    alacakli_oldugu_dosya = 7,
    tasinir_haciz = 23,
    tasinir_haciz_talimati = 24,
    ihbarname89_1 = 5,
    ihbarname89_2 = 43,
    ihbarname89_3 = 44
}