import {UyapRequest} from "./UyapRequest";

export interface MtsDavaAcilisTalep {
    dosyaId: string
}

export interface MtsDavaAcilisCevap {
    dosyaId: number,
    takibinYolu: number,
    aciklama: number,
    terekeMi: "E" | "H",
    dosyaDurumu: number,
    takipAnaTur: number,
    takipTur: number,
    bk84Uygulansinmi: "E" | "H",
    bsmvUygulansinmi: "E" | "H",
    dosyaTurKodu: number,
    dosyaNo: string,
    dosyaYil: number,
    aciklama48E9: string,
    dosyaAcilisTarihi: string,
    kkdfHesaplansinmi: "E" | "H",
    birimAdi: string,
    vekaletUcretineKDVUygulansinmi: "H" | "E",
    feragatIslmDrm: number,
    harcKayitID: number,
    harcKayitEvrakID: number,
    uyapID: number,
    kdv: "H" | "E",
    vergi: "H" | "E",
    mahiyetId: number,
    mtsAdliyeBirimID: string,
    gnlDosyaDurumu: number,
    birimID: string,
    dosyaSira: number
}

export default class MtsDavaAcilis extends UyapRequest<MtsDavaAcilisTalep, MtsDavaAcilisCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "dosyaId": 522641040,
            "takibinYolu": 0,
            "aciklama": "Alacağın tahsili tarihine kadar 16,80faiz masraf ve vekalet ücreti ile tahsili. Kısmi ödemelerde TBK 100 e göre hesap yapılmasını talep ederim.",
            "terekeMi": "H",
            "dosyaDurumu": 0,
            "takipAnaTur": 1,
            "takipTur": 0,
            "bk84Uygulansinmi": "E",
            "bsmvUygulansinmi": "E",
            "dosyaTurKodu": 294,
            "dosyaNo": "2019/1147",
            "dosyaYil": 2019,
            "aciklama48E9": "Haciz Yolu",
            "dosyaAcilisTarihi": "15/06/2019",
            "kkdfHesaplansinmi": "E",
            "birimAdi": "Merkezi Takip Sistemi",
            "vekaletUcretineKDVUygulansinmi": "H",
            "feragatIslmDrm": 2,
            "harcKayitID": 6731647360,
            "harcKayitEvrakID": 5871661686,
            "uyapID": 111514275,
            "kdv": "H",
            "vergi": "H",
            "mahiyetId": 2007,
            "mtsAdliyeBirimID": "1006927",
            "gnlDosyaDurumu": 0,
            "birimID": "1054286",
            "dosyaSira": 1147
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsDavaAcilis_brd.ajx';
    }

    parse(data: any): Promise<MtsDavaAcilisCevap> {
        return data;
    }

}

