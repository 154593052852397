import {UyapRequest} from "./UyapRequest";

export interface AvukatIcraTalepEvrakiGonderTalep {
    formData: boolean,
    data: FormData
}

export interface AvukatIcraTalepEvrakiGonderCevap {
    message: string,
    result: string
}

export class AvukatIcraTalepEvrakiGonder extends UyapRequest<AvukatIcraTalepEvrakiGonderTalep, AvukatIcraTalepEvrakiGonderCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {"type":"success","message":"İnternet yoluyla gönderdiğiniz evrakları Çanakkale İcra Dairesi - 2024/7780 nolu dosyada görebilirsiniz. ","log":""};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'avukatIcraTalepEvrakiGonder.ajx';
    }

    async parse(data: any): Promise<AvukatIcraTalepEvrakiGonderCevap> {
        return {
            message: data.message,
            result: data.type
        }
    }
}

export default AvukatIcraTalepEvrakiGonder;