import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";
import app from "@/main";

export class TaskResponser {
    public async response(task: ITask) {
        let childTasks: Array<ITask> = (await app.$http.get('/api/v1/task/'+ task.id +'/child-tasks')).data;
        let hataliGorevSayisi: number = childTasks.filter((value: ITask) => (value.status == TaskDurum.BITTI_EKSIK || value.status == TaskDurum.BITTI_HATALI)).length;
        if (hataliGorevSayisi == childTasks.length)
            task.status = TaskDurum.BITTI_HATALI
        else if (hataliGorevSayisi != childTasks.length && hataliGorevSayisi > 0)
            task.status = TaskDurum.BITTI_EKSIK;
        else
            task.status = TaskDurum.BITTI_BASARILI;
        task.finished_at = new Date();
        await app.$store.dispatch('putTask', task);
    }
}