import * as xml2js from "xml2js";
import {AxiosResponse} from "axios";
import app from "@/main";

export class UyapRequestResponseHandler {
    async tryForBlob(data: AxiosResponse): Promise<AxiosResponse> {
        if (typeof data.headers["content-disposition"] != "undefined") {
            data.data.name = data.headers["content-disposition"];
            let filenameStrIndex = data.data.name.indexOf("filename=");
            if(filenameStrIndex > 0){
                data.data.name = data.data.name.substring(filenameStrIndex + 9);
            }
            console.log("data.headers[\"content-disposition\"] added");
        }
        return data;
    }

    tryForXml(data: AxiosResponse): Promise<AxiosResponse> {
        return  new Promise(async (resolve, reject)=>{
            try {
                let obj: any = await this.xml2json(data.data);
                //console.log("obj",obj);
                if (typeof obj.root.error != "undefined") {
                    return  reject({message:obj.root.error})
                } else if (obj.root.ResultEvent.type == "error") {
                    return  reject({message:obj.root.ResultEvent.message})
                } else {
                    return resolve(data);
                }
                // @ts-ignore
            } catch (e) {
                //console.log("Error tryForXml",e);
                return resolve(data);
            }
        })
    }

    tryForJson(data: AxiosResponse): Promise<AxiosResponse> {
        return  new Promise(async (resolve, reject)=>{
            try {
                //{"errorCode":"PRTL_GNL_10001-53", "error":"Arayuzden alınan veri okunurken hata oluştu [JSONParse:IcraDosyaBilgileri]. "}
                if (typeof data.data.error != "undefined") {
                    return reject({message:data.data.error});
                } else {
                    return resolve(data);
                }
                // @ts-ignore
            } catch (e) {
                return resolve(data);
            }
        })

    }

    xml2json(data: string): Promise<any> {
        return new Promise((resolve, reject) => {
            xml2js.parseString(data, {explicitArray: false}, (err, result) => {
                if (err) {
                    return reject(err);
                }
                resolve(result);
            });
        })
    }
}