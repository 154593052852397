interface HashKisiInterface {
    adi: string,
    soyadi: string,
    tckimlikno: string,
    rolId: string
}

interface HashKurumInterface {
    kurumadi: string,
    vergino: string,
    mersisno: string,
    rolId: string
}

export class HashArguments {
    constructor() {
        return {
            taraflar: [],
            takibeEsasMiktar: 0,
            //id:0,
            type: ""
        }
    }

    static kisi(): HashKisiInterface {
        return {
            adi: "",
            soyadi: "",
            tckimlikno: "",
            rolId: ""
        }
    }

    static kurum(): HashKurumInterface {
        return {
            kurumadi: "",
            vergino: "",
            mersisno: "",
            rolId: ""
        }
    }
}
