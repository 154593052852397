import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import app from "@/main";
import JSZip from 'jszip';
import {DosyaEvraklarCevap, IDosyaEvrak} from "../../../uyap/DosyaEvraklar";
import {TaskDurum} from "../../../enum/TaskDurum";

export class EvrakIndirTaskHandler extends AbstractTaskHandler {
    zip: JSZip = new JSZip();
    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            task.dosya_id = dosya.dosyaId;
            let evraklarRes: DosyaEvraklarCevap = await app.$uyap.DosyaEvraklar().run({dosyaId: task.dosya_id.toString()});
            let evraklar: Array<IDosyaEvrak> = [];
            for (let tumEvraklarKey in evraklarRes.tumEvraklar) {
                evraklar = evraklar.concat(evraklarRes.tumEvraklar[tumEvraklarKey]);
            }
            for (let tur of task.data.turler) {
                if (tur == "Dosyaya Eklenen Son ... Evrak") {
                    let path = 'Dosyaya eklenen son ' + task.data.sonEvrakSayisi + ' evrak/';
                    let indirilecekEvraklar = evraklar.sort((a, b) => (new Date(this.formatDate(b.sistemeGonderildigiTarih)).getTime() - new Date(this.formatDate(a.sistemeGonderildigiTarih)).getTime())).slice(0, Number(task.data.sonEvrakSayisi));
                    for (const indirilecekEvrak of indirilecekEvraklar) {
                        await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                    }
                } else {
                    let indirilecekEvraklar = evraklar.filter(evrak=> evrak.tur==tur);
                    for (const indirilecekEvrak of indirilecekEvraklar) {
                        await this.setEvrakToZip((tur+"/"),task.dosya_id,indirilecekEvrak.evrakId);
                    }
                }
            }
            let zipBlob = await this.zip.generateAsync({type: "blob"});
            let formData = new FormData();
            // @ts-ignore
            let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Evrakları_' + new Date().getTime() + '.zip';
            formData.append('name', name);
            formData.append('file', zipBlob);
            formData.append('task_id', (task.id as number).toString());
            await app.$http.post('/api/v1/task/file', formData);
            task.status = TaskDurum.BITTI_BASARILI
            task.response = [task.dosya_esas_no, task.birim_adi, name];
            return task;
        } catch (e) {
            throw new Error(e.message);
        }
    }

    formatDate(tarih: string){
        let splitedDate = tarih.split('/');
        return splitedDate[2] + "-" + splitedDate[1] + "-" + splitedDate[0];
    }

   async setEvrakToZip(path: string, dosyaId: any, evrakId: any){
        try{
            let evrakBlob = await app.$uyap.EvrakIndir().run({
                dosyaId: dosyaId,
                evrakId: evrakId
            });
            //@ts-ignore
            this.zip.file(path + evrakBlob.name, evrakBlob);
        }catch (e) {
            console.log("setEvrakToZip Error ? ",e);
        }
    }
}