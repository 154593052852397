import {UyapRequest} from "./UyapRequest";

export interface MtsAlacakliIletisimBilgisiTalep {
    kisiKurumId: number
}

export interface MtsAlacakliIletisimBilgisiCevap {
    basarilimi: boolean,
    object: IIletisimBilgisi
}

interface IIletisimBilgisi {
    iletisimId: number,
    telefon: string,
    elektronikPosta: string,
    kisiKurumId: number,
    kayitTipi: string,
    ekleme: boolean,
    guncelleme: boolean,
    mode: number
}

export default class MtsAlacakliIletisimBilgisi extends UyapRequest<MtsAlacakliIletisimBilgisiTalep, MtsAlacakliIletisimBilgisiCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "MtsAlacakliIletisimBilgisi_" + this.talepData.kisiKurumId
        };
    }

    getMockResponse(): any {
        return {"basarilimi":true,"object":{"iletisimId":150053122,"telefon":"3123122000","elektronikPosta":"a@a.com","kisiKurumId":208632690,"kayitTipi":"D","ekleme":false,"guncelleme":false,"mode":2}};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsAlacakliIletisimBilgisi.ajx';
    }

    async parse(data: any): Promise<MtsAlacakliIletisimBilgisiCevap> {
        return data;
    }

}
