
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class Password extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() label!: string;

  localValue!: string;
  show=false;
}
