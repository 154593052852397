import {UyapRequest} from "./UyapRequest";

export class VakifbankSmsOnayTalep {
    smsSifre: any;
    uyapGrupId: any;
}

export interface VakifbankSmsOnayCevap {
    sonucKodu: string,
    sonucAciklama: string,
    bankaId: string,
    smsSifre: string,
    tcKimlikNo: string,
    uyapGrupId: string,

}

export default class VakifbankHesapListesi extends UyapRequest<VakifbankSmsOnayTalep, VakifbankSmsOnayCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return `<root><SmsOnaySonucDVO>
      <sonucKodu>00</sonucKodu>
      <sonucAciklama>ISLEM BASARILI</sonucAciklama>
      <bankaId>c4678f50-5409-4cae-b954-381a07063f23</bankaId>
      <smsSifre>858283</smsSifre>
      <tcKimlikNo>14537825364</tcKimlikNo>
      <uyapGrupId>36992574</uyapGrupId>
    </SmsOnaySonucDVO></root>`;
        /*
        SMS HATALI RESPONSE
        <root><SmsOnaySonucDVO>
          <sonucKodu>78</sonucKodu>
          <sonucAciklama>SMS SIFRE HATALI</sonucAciklama>
          <smsSifre>999999999</smsSifre>
          <tcKimlikNo>14537825364</tcKimlikNo>
          <uyapGrupId>37069665</uyapGrupId>
        </SmsOnaySonucDVO></root>
       */
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/vakifbank_sms_onay.ajx';
    }

    async parse(data: string): Promise<VakifbankSmsOnayCevap> {
        let obj: any = await this.xml2json(data);
        return obj.root.SmsOnaySonucDVO;
    }

}
