import {UyapRequest} from "./UyapRequest";
import {TarafRol} from "../enum/uyap/TarafRol";

export interface GetDosyaTarafBilgileriTalep {
    dosyaId: string
}

export type GetDosyaTarafBilgileriCevap = Array<ITarafBilgisiDetayli>

export interface ITarafBilgisiDetayli {
    kisiKurumBilgileriDVO: any,      // {}
    dosyaKisiDVO: IDosyaKisiDVO,
    secili: boolean,
    dogacakHakDahilmi: boolean,
    borcluBelediye: boolean,
    maasHesabiHaric: boolean,
    aboneVarmi: boolean
}

interface IDosyaKisiDVO {
    kisiBilgDVO: IKisiBilgDVO,
    kurumBilgDVO: IKurumBilgDVO,
    dosyasiIncelenebilir: boolean,
    kisiMiKurumMu: number,
    guncelKisiKurumId: number,
    ilkKisiKurumId: number,
    tarafRolu: TarafRol,
    dosyaKisiId: number,
    aboneVarmi: boolean,
}

export interface IKisiBilgDVO {
    tcKimlikNo?: string,
    adi?: string,
    soyadi?: string,
    isEngelliKisiKurum: boolean
}

export interface IKurumBilgDVO {
    isEngelliKisiKurum: boolean,
    kurumAdi?: string
}


export default class GetDosyaTarafBilgileri extends UyapRequest<GetDosyaTarafBilgileriTalep, GetDosyaTarafBilgileriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetDosyaTarafBilgileri_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): any {
        return [{"kisiKurumBilgileriDVO":{},"dosyaKisiDVO":{"kisiBilgDVO":{"tcKimlikNo":"11*******54","adi":"SERDAR","soyadi":"KABAŞ","isEngelliKisiKurum":false},"kurumBilgDVO":{"isEngelliKisiKurum":false},"dosyasiIncelenebilir":true,"kisiMiKurumMu":0,"guncelKisiKurumId":39545546,"ilkKisiKurumId":39545546,"tarafRolu":22,"dosyaKisiId":432055467,"aboneVarmi":true},"secili":false,"dogacakHakDahilmi":false,"borcluBelediye":false,"maasHesabiHaric":false,"aboneVarmi":true},{"kisiKurumBilgileriDVO":{},"dosyaKisiDVO":{"kisiBilgDVO":{"isEngelliKisiKurum":false},"kurumBilgDVO":{"kurumAdi":"İZZET DEMİRCİOĞLU YEM TARIM ÜRÜNLERİ HAYVANCILIK GIDA TURİZM OTOMOTİV TAŞIMACILIK SANAYİ TİCARET LİMİTED ŞİRKETİ","isEngelliKisiKurum":false},"dosyasiIncelenebilir":true,"kisiMiKurumMu":1,"guncelKisiKurumId":198399381,"ilkKisiKurumId":198399381,"tarafRolu":71,"dosyaKisiId":1412443802,"aboneVarmi":true},"secili":false,"dogacakHakDahilmi":false,"borcluBelediye":false,"maasHesabiHaric":false,"aboneVarmi":true},{"kisiKurumBilgileriDVO":{},"dosyaKisiDVO":{"kisiBilgDVO":{"isEngelliKisiKurum":false},"kurumBilgDVO":{"kurumAdi":"ZİRVE BİLGİSAYAR TİC. VE SAN. LTD.ŞTİ","isEngelliKisiKurum":false},"dosyasiIncelenebilir":true,"kisiMiKurumMu":1,"guncelKisiKurumId":142885524,"ilkKisiKurumId":142885524,"tarafRolu":21,"dosyaKisiId":432055463,"aboneVarmi":true},"secili":false,"dogacakHakDahilmi":false,"borcluBelediye":false,"maasHesabiHaric":false,"aboneVarmi":true}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'getDosyaTarafBilgileri_brd.ajx';
    }

    async parse(data: any): Promise<GetDosyaTarafBilgileriCevap> {
        return data;
    }
}
