export enum TakipDurumu{
    acilmamis="acilmamis",
    acik="acik",
    kapali="kapali",
}

export enum TakipDurumuLabel{
    acilmamis="Açılmamış",
    acik="Açık (Derdest)",
    kapali="Kapalı (Hitam)",
}