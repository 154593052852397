import app from "@/main";
import {MtsTebligatSafahatListRow} from "../../../uyap/MtsTebligatSafahatList";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class MtsOdemeTaskResponse {
    dosyaEsasNo: string = ""
    message: string = ""
    safahat: any[] = []
}

export class MtsOdemeTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            const tebligatlar = (await app.$uyap.MtsTebligatList().run({dosyaId: task.dosya_id})).items;
            task.response = new MtsOdemeTaskResponse();
            task.response.dosyaEsasNo = task.dosya_esas_no;
            let teslimEdildi: boolean = false;
            for (let tebligat of tebligatlar) {
                const safahatler = await app.$uyap.MtsTebligatSafahatList().run({barkodNo: parseInt(tebligat.mersule)});
                const teslimSafahati = safahatler.find((safahat: MtsTebligatSafahatListRow) => safahat.aciklama == "MAZBATA TESLİM");
                if (teslimSafahati) {
                    teslimEdildi = true;
                }
                task.response.safahat = safahatler.map((safahat: MtsTebligatSafahatListRow) => {
                    return [
                        task.dosya_esas_no,
                        tebligat.mersule,
                        safahat.aciklama,
                        safahat.islemYeri,
                        safahat.tarih,
                    ]
                });
            }
            if (teslimEdildi) {
                const alacaklilar = await app.$uyap.MtsIcraAcilisAlacakliTaraflar().run({dosyaId: task.dosya_id});
                if (alacaklilar.taraflarList.length > 0) {
                    let alacakliKisiKurumId = alacaklilar.taraflarList[0].asilTarafIlkKisiKurumId
                    const mtsIcraTakipBaslatCevap = await app.$uyap.MtsIcraTakipBaslat().run({
                        alacakliKisiKurumId: alacakliKisiKurumId,
                        dosyaId: task.dosya_id,
                        icraTakipSebepId: task.data.icraTakipSebep.id,
                        kotaTur: task.data.kotaTur
                    });
                    task.response.message = mtsIcraTakipBaslatCevap.message;
                    task.status = TaskDurum.BITTI_BASARILI;
                    return task;
                } else {
                    console.log("alacaklilar not found");
                    throw new Error("Alacaklı Bulunamadı.")
                }
            } else {
                task.response.message = "Teslim edilmiş tebligat bulunamadı.";
                throw new Error(task.response.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e.message);
        }
    }
}