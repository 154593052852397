import Args from "../Args";
import {Mixins} from "../../Mixins";
import AlacakKalem from "../AlacakKalem";
import Ilamsiz from "./Ilamsiz";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export default class Cek extends Ilamsiz {
    ilamsizTipi: string = 'cek';
    id!: number;// id;
    seriNumarasi: string = ''
    kesideTarihi: string = ''// //"13/03/2019",
    kesideYeri: string = ''// kesideYeri;
    bankaNumarasi: string = ''// bankaNumarasi;
    meblagi: string = ''// Mixins.replaceCommaDots(meblagi);//"23000",
    meblagTuru: string = ''// meblagTuru;//"PRBRMTL",
    meblagTuruAciklama: string = ''//meblagTuruAciklama;//"TL-Türk Lirası",
    odemeYeri: string = ''// odemeYeri;
    ibrazTarihi: string = ''// ibrazTarihi;//"25/03/2019",
    banka: string = ''// banka;
    aciklama!: string;
    alacakKalemleri: AlacakKalem[] = []// alacakKalemleri;

    static fromJson(jsonData: any): Cek {
        let obj = Args.staticFromJson(jsonData, new Cek());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x: AlacakKalem) => AlacakKalem.fromJson(x));
        return obj;
    }

    static parse(
        domNode: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ): Cek {
        let cek = new Cek();
        cek.id = args.counterId();
        cek.seriNumarasi = cek.serinoConverter(domNode.getAttribute('serino'));
        cek.kesideTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('kesidetarihi'));
        cek.kesideYeri = Mixins.nullUndefCleaner(domNode.getAttribute('kesideyeri'));
        cek.bankaNumarasi = Mixins.nullUndefCleaner(domNode.getAttribute('kocanno'));
        cek.meblagi = Mixins.replaceCommaDots(domNode.getAttribute('tutar'));
        cek.meblagTuru = Mixins.nullUndefCleaner(domNode.getAttribute('tutartur'));
        cek.meblagTuruAciklama = Mixins.nullUndefCleaner(domNode.getAttribute('tutarturaciklama'));
        cek.odemeYeri = Mixins.nullUndefCleaner(domNode.getAttribute('odemeyeri'));
        cek.ibrazTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('ibraztarihi'));
        cek.banka = Mixins.nullUndefCleaner(domNode.getAttribute('bankaadi'));

        let sekizinciMadde = domNode.getAttribute("sekizinciMadde");
        if (sekizinciMadde) {
            if (sekizinciMadde.trim() != "")
                cek.aciklama = sekizinciMadde;
        }

        cek.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(domNode, args, tarafList);
        return cek;
    }

    serinoConverter(value: any): string {
        //EUP 158 // cek seri nolarina String giriliyormus uyapta takip acilamiyormus kontrol eklendi // emre
        let splited;
        value = value.trim();
        if (value.indexOf("-") >= 0) {
            splited = value.split("-");
            value = splited[splited.length - 1];
        } else if (value.indexOf(" ") >= 0) {
            splited = value.split(" ");
            value = splited[splited.length - 1];
        } else if (value.indexOf("/") >= 0) {
            splited = value.split("/");
            value = splited[splited.length - 1];
        } else {
            value = Mixins.onlyDigit(value);
        }
        return value;
    }

    toUyapJson() {
        const {
            id,
            ilamsizTipi,
            seriNumarasi,
            kesideTarihi,
            kesideYeri,
            bankaNumarasi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            odemeYeri,
            ibrazTarihi,
            banka,
            aciklama,
            alacakKalemleri,
        } = this;
        let obj: any = {
            id,
            ilamsizTipi,
            seriNumarasi,
            kesideTarihi,
            kesideYeri,
            bankaNumarasi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            odemeYeri,
            ibrazTarihi,
            banka,
            alacakKalemleri: alacakKalemleri.map(i => i.toUyapJson()),
        }
        if (aciklama != "")
            obj["aciklama"] = aciklama;
        return obj;
    }

}

