import {MDB} from "../MDB";
import HacizInjectable from "./HacizInjectable";
import {
    BankadanHacizliParaninIstenilmesiParamsInterface,
    BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiParamsInterface,
    DosyadaHacizliMalinSatisiParamsInterface,
    DosyadakiAvansinIadesiParamsInterface,
    DosyadakiHacizlerinKaldirilmasiParamsInterface,
    DosyaninYetkiliIcraDairesineGonderilmesiParamsInterface,
    HacizliMalinKiymetTakdiriIcinTalimatYazilmasiParamsInterface,
    HacizliMalinSatisiIcinTalimatYazilmasiParamsInterface,
    HaricenTahsilatBildirimiParamsInterface,
    KiymetTakdirininYapilmasiParamsInterface,
    MaasUzerindekiHacizlerinKaldirilmasiParamsInterface,
    MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParamsInterface,
    RehinAcigiBelgesiHazirlanmasiParamsInterface,
    YuzuncuMaddeyeYararBilgiIstenilmesiParamsInterface
} from "@/plugins/uyap-plugin/haciz/TopluDigerTalepParamsInterface";
import {DigerTalepEvrakTypeEnum} from "../uyap/enum/DigerTalepEvrakTypeEnum";
import TakipDosya from "@/plugins/uyap-plugin/uyap/classes/TakipDosya";

const mdb = new MDB();

export default class DigerTalepController extends HacizInjectable {
    constructor(borclu: any, dosya: TakipDosya) {
        super(borclu, dosya);
    }

    yuzuncuMaddeyeYararBilgiIstenilmesiEkle(params: YuzuncuMaddeyeYararBilgiIstenilmesiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.YuzuncuMaddeyeYararBilgiIstenilmesi);
        let talepObj = Object.assign({}, obj, {
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "aciklama": params.aciklama,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    acizBelgesiHazirlanmasiEkle(params: {
        aciklama: string,
    }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.AcizBelgesiHazirlanmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    aracUzerindekiHacizSerhininKaldirilmasiEkle(params: {
        plaka: string,
    }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.AracUzerindekiHacizSerhininKaldirilmasi);
        let talepObj = Object.assign({}, obj, {
            "plaka": params.plaka,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    aracUzerindekiYakalamaSerhininKaldirilmasiEkle(params: {
        plaka: string,
    }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.AracUzerindekiYakalamaSerhininKaldirilmasi);
        let talepObj = Object.assign({}, obj, {
            "plaka": params.plaka,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    bankadanHacizliParaninIstenilmesiEkle(params: BankadanHacizliParaninIstenilmesiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.BankadanHacizliParaninIstenilmesi);
        let talepObj = Object.assign({}, obj, {
            "bankaList": params.bankaList.join(","),
            "aciklama": params.aciklama,
            "hacizTarihi": params.hacizTarihi,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    borcluTarafindanYapilanOdemelerinHesabaAktarilmasiEkle(params: BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.BorclutarafindanYapilanOdemelerinHesabaAktarilmasi);
        let talepObj = Object.assign({}, obj, {
            "hesapKimeAit": params.hesapKimeAit.substr(0, 1),
            "hesapKimeAitText": params.hesapKimeAit,
            "ibanNo": params.ibanNo.replace(" ",""),
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyaHesabininYapilmasiVeDosyayaKaydedilmesiEkle() {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyaHesabininYapilmasiVeDosyayaKaydedilmesi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": ""
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyadaHacizliMalinSatisiEkle(params: DosyadaHacizliMalinSatisiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyadaHacizliMalinSatisi);
        let talepObj = Object.assign({}, obj, {
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "aciklama": params.aciklama,
            "hacizTarihi": params.hacizTarihi
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyadakiAvansinIadesiEkle(params: DosyadakiAvansinIadesiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyadakiAvansinIadesi);
        let talepObj = Object.assign({}, obj, {
            "hesapKimeAit": params.hesapKimeAit.substr(0, 1),
            "hesapKimeAitText": params.hesapKimeAit,
            "ibanNo": params.ibanNo.replace(" ",""),
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyadakiHacizlerinKaldirilmasiEkle(params: {
        aciklama: string
    }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyadakiHacizlerinKaldirilmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyaninIslemdenKaldirilmasiEkle(params: DosyadakiHacizlerinKaldirilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyaninIslemdenKaldirilmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "kapanmaAdi": params.kapanmaAdi,
            "kapanmaId": params.kapanmaId,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyaninYetkiliIcraDairesineGonderilmesiEkle(params: DosyaninYetkiliIcraDairesineGonderilmesiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyaninYetkiliIcraDairesineGonderilmesi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "birimAdi": params.birimAdi,
            "birimId": params.birimId,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    hacizliMalinKiymetTakdiriIcinTalimatYazilmasiEkle(params: HacizliMalinKiymetTakdiriIcinTalimatYazilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.HacizliMalinKiymetTakdiriIcinTalimatYazilmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "birimAdi": params.birimAdi,
            "birimId": params.birimId,
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "hacizTarihi": params.hacizTarihi,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    hacizliMalinSatisiIcinTalimatYazilmasiEkle(params: HacizliMalinSatisiIcinTalimatYazilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.HacizliMalinSatisiIcinTalimatYazilmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "birimAdi": params.birimAdi,
            "birimId": params.birimId,
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "hacizTarihi": params.hacizTarihi,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    hacizliTasinirMallarinMuhafazaAltinaAlinmasiEkle(params: { hacizTarihi: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.HacizliTasinirMallarinMuhafazaAltinaAlinmasi);
        let talepObj = Object.assign({}, obj, {
            "hacizTarihi": params.hacizTarihi,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    haricenTahsilatBildirimiEkle(params: HaricenTahsilatBildirimiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.HaricenTahsilatBildirimi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "tahsilatTarihi": params.tahsilatTarihi,
            "tahsilatTutari": params.tahsilatTutari,
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    iik121MaddeyeGoreYetkiVerilmesiEkle(params: { aciklama: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.IIk121MaddeyeGoreYetkiVerilmesi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    iik150cSerhiEklenmesiEkle(params: { aciklama: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.IIk150cSerhiEklenmesi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    kiymetTakdirininYapilmasiEkle(params: KiymetTakdirininYapilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.KiymetTakdirininYapilmasi);
        let talepObj = Object.assign({}, obj, {
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "aciklama": params.aciklama,
            "hacizTarihi": params.hacizTarihi
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    maasUzerindekiHacizlerinKaldirilmesiEkle(params: MaasUzerindekiHacizlerinKaldirilmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.MaasUzerindekiHacizlerinKaldirilmesi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "calismaDurumu": params.calismaDurumu
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiEkle(params: MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "yeddiemin": params.yeddiemin,
            "muhafazaTarihi": params.muhafazaTarihi
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    rehinAcigiBelgesiHazirlanmasiEkle(params: RehinAcigiBelgesiHazirlanmasiParamsInterface) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.RehinAcigiBelgesiHazirlanmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama,
            "belgeTip": params.belgeTip,
            "belgeTipAciklama": params.belgeTipAciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    rehininParayaCevrilmesiSerhiEkle(params: { aciklama: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.RehininParayaCevrilmesiSerhi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    tasinmazHaczininKaldirilmasiEkle(params: { aciklama: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.TasinmazHaczininKaldirilmasi);
        let talepObj = Object.assign({}, obj, {
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    dosyaYenilemeEkle() {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.DosyaYenileme);
        let talepObj = Object.assign({}, obj, {
            "aciklama": ""
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    takibinKesinlesmesiEkle() {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.TakibinKesinlesmesi);
        let talepObj = Object.assign({}, obj, {});
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    ibanGuncellemesiEkle(params: { ibanSecimi: any, ibanNo: string }) {
        let obj = mdb.digerTalepler.find((i: any) => i.className == DigerTalepEvrakTypeEnum.IbanGuncelleme);
        let ibanSecimStr = "Alacaklı";
        //let ibanSecimiAlacakli = ibanSecimi["Alacaklı"];
        let ibanSecimiVekil = params.ibanSecimi["Vekil"];
        if (ibanSecimiVekil == true)
            ibanSecimStr = "Vekil";
        let talepObj = Object.assign({}, obj, {
            hesapKimeAit: ibanSecimStr.substr(0, 1),
            hesapKimeAitText: ibanSecimStr,
            ibanNo: params.ibanNo.replace(" ","")
        });
        this.evrakTutar += talepObj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }
}
