import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface SorguBakiyeYukleTalep {
    bakiye: number, // sorgu bakiye ücreti 3tl
    odemeTip: 2     // barokart
}

export interface SorguBakiyeYukleCevap {
    type: "success" | "error",
    message: string,
    log: string,
}

export const SORGU_UCRETI: number = 3;

export default class SorguBakiyeYukle extends UyapRequest<SorguBakiyeYukleTalep, SorguBakiyeYukleCevap> {
    getUrl(): string {
        return this.ImzaClientAddr + "ws_sorgu_bakiye_yukleme.ajx"
    }
    getMockResponse(): any {
        return {"type":"success","message":"Bakiye yüklemeniz başarıyla gerçekleştirilmiştir.","log":""};
    }
    getMethod(): "POST" | "GET" {
        return "POST";
    }
    cache(): { active: boolean; ttl: number; key: string; } {
        return { active: false, ttl: 0, key: "_" }
    }
    async parse(data: any): Promise<SorguBakiyeYukleCevap> {
        return data;
    }
}