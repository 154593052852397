import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";
import app from "@/main";

export interface IcraTakipIlamDosyaTuruTalep {
    ilamTuru: number
}

export interface IcraTakipIlamDosyaTuruCevap {
    name: string,
    value: number
}

export default class IcraTakipIlamDosyaTuru extends UyapRequest<IcraTakipIlamDosyaTuruTalep, IcraTakipIlamDosyaTuruCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "icraTakipIlamDosyaTuru_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse() {
        return [{"name":"Hukuk Dava Dosyası","value":15},{"name":"Hukuk Değişik İş Dosyası","value":14},{"name":"Tereke Dosyası","value":78},{"name":"Hakem Dava Dosyası","value":79}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "icra_takip_ilam_dosya_turu.ajx";
    }

    async parse(data: any): Promise<IcraTakipIlamDosyaTuruCevap> {
        return data;
    }
}