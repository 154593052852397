import {UyapRequest} from "./UyapRequest";

export interface AvukatYetkiliKurumlarTalep {
}

export type AvukatYetkiliKurumlarCevap = AvukatYetkiliKurumlarItem[];

export interface AvukatYetkiliKurumlarItem {
    kurumAdi: string,
    dosyaSayisi: number,
    isDefaultKurum: boolean,
    islemId: number,
    islemTuru: number,
    kisiKurumId: number,
    kurumSayisi: number,
    odenenUcret: number,
    ucretDurumuKod: number,
    vergiNo: string,
    wsKullaniyorMu: number,
    mersisNo?: string
}


export default class AvukatYetkiliKurumlar extends UyapRequest<AvukatYetkiliKurumlarTalep, AvukatYetkiliKurumlarCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse() {
        return [
            {
                "kurumAdi": "YEŞİL ELEKTRİK PERAKENDE SATIŞ A.Ş.",
                "vergiNo": "2250559299",
                "kisiKurumId": 112341234,
                "dosyaSayisi": 0,
                "isDefaultKurum": false,
                "kurumSayisi": 0,
                "ucretDurumuKod": -1,
                "islemTuru": 0,
                "odenenUcret": 0,
                "islemId": 0,
                "wsKullaniyorMu": 0
            }, {
                "kurumAdi": "BERLİN SU DAIĞITIM A.Ş.",
                "vergiNo": "2250559299",
                "kisiKurumId": 2523533,
                "dosyaSayisi": 0,
                "isDefaultKurum": false,
                "kurumSayisi": 0,
                "ucretDurumuKod": -1,
                "mersisNo": "0225055929900019",
                "islemTuru": 0,
                "odenenUcret": 0,
                "islemId": 0,
                "wsKullaniyorMu": 0
            }, {
                "kurumAdi": "MOSKOVA TELEKOM A.Ş.",
                "vergiNo": "2250559293",
                "kisiKurumId": 22235342,
                "dosyaSayisi": 0,
                "isDefaultKurum": false,
                "kurumSayisi": 0,
                "ucretDurumuKod": -1,
                "mersisNo": "0225055455900019",
                "islemTuru": 0,
                "odenenUcret": 0,
                "islemId": 0,
                "wsKullaniyorMu": 0
            },
        ];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsAvukatYetkiliKurumlar_brd.ajx';
    }

    async parse(data: any): Promise<AvukatYetkiliKurumlarCevap> {
        return data;
    }

}

