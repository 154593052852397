import app from "@/main";
import {ITarafBilgisi} from "../../../uyap/DosyaTarafBilgileri";
import {DosyaBorcluSSKCevap} from "../../../uyap/DosyaBorcluSSK";
import {DosyaBorclu} from "../../../uyap/DosyaBorclu";
import TakipDosya from "../../../uyap/classes/TakipDosya";
import {AvukatKisiselBilgileriSorgulamaCevap} from "../../../uyap/AvukatKisiselBilgileriSorgulama";
import {DosyaSorguTipleriEnum} from "../../../uyap/enum/DosyaSorguTipleriEnum";
import {evrakGonder} from "../../../evrak_gonder/EvrakGonder";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {UdfSablon} from "./UdfSablon";
import {TaskDurum} from "../../../enum/TaskDurum";
import JSZip from "jszip";
import {EvrakGonderTaskDataInterface} from "../TaskDataInterface/EvrakGonderTaskDataInterface";
import {IKisiTumDVO, IKurumDVO} from "../../../uyap/CommonInterfaces";
import {DosyaBorcluEgmCevap} from "../../../uyap/DosyaBorcluEgm";

// TODO: Evrak gönder kullanılmıyor sorulacak

export class HazirEvrakGonderTaskHandler extends AbstractTaskHandler {
    //@ts-ignore
    avukat: AvukatKisiselBilgileriSorgulamaCevap = {};
    data: any;
    //@ts-ignore
    dosya: TakipDosya;
    evrak: Blob | null = null;

    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            this.dosya = dosya;
            this.data = task.data;
            task.dosya_id = dosya.dosyaId;
            task.data.dosya = dosya;
            await this.ucuncuSahisTarafSorgula();
            await this.borcluVarsaBorcluyaGoreHazirla();
            await this.evrakGonderimKosulunaUygunMu();
            await this.ozelSorgula();
            await this.evrakOlustur();
            if (this.evrak) {
                let imzaliEvrakB64 = await app.$imza.dokumanImzala(this.evrak);
                let imzaliEvrak = app.$uyapHelper.base64toBlob(imzaliEvrakB64, "application/octet-stream");
                let evrak: EvrakGonderTaskDataInterface = {
                    seciliTur: task.data.evrakTur,
                    aciklama: task.data.aciklama,
                    dosyaData: {
                        base64: null,
                        name: "evrak.udf",
                        type: "application/octet-stream",
                        dosya: imzaliEvrak,
                        hash: '',
                        // @ts-ignore !id:0 blobu dosyadan çekmesi için verildi!
                        file_res: {id:0}
                    },
                    ekDosyalarData: []
                };

                let evrakResult = await evrakGonder({
                    dosyaId: this.dosya.dosyaId,
                    birimAdi: this.dosya.birimAdi,
                    evraklar: [evrak],
                    dosyaNo: dosya.dosyaNo,
                    birimId: dosya.birimId
                });

                // let evrakResult = {message:"test gonderildi"};
                //FILE UPLOAD TO SERVER
                let zip = new JSZip();
                zip.file((task.data.dilekce.title+'.udf'), imzaliEvrak);
                let zipBlob = await zip.generateAsync({type: "blob"});
                let formData = new FormData();
                // @ts-ignore
                let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Hazır Evrağı_' + new Date().getTime() + '.zip';
                formData.append('name', name);
                formData.append('file', zipBlob);
                formData.append('task_id', (task.id as number).toString());
                await app.$http.post('/api/v1/task/file', formData);
                task.response = {
                    html: this.data.dilekce.html,
                    evrakResult: evrakResult,
                    evrak: evrak
                };
                task.status = TaskDurum.BITTI_BASARILI
                return task;
            } else {
                throw new Error("Evrak oluşturulamadı");
            }
        } catch (err) {
            throw new Error(err.message);
        }
    }

    async evrakGonderimKosulunaUygunMu(): Promise<void> {
        let strArray: any[] = [];
        switch (this.data.kosul) {
            case DosyaSorguTipleriEnum.DUSECEK_OLAN_DOSYALAR: //dusecek olan dosyalar
                console.log("//dusecek olan dosyalar QUERY");
                await this.dosya.dosyaDusecekMiSorgula();
                console.log("this.dosya.dosyaGuncelMi()", this.dosya.dosyaGuncelMi());
                if (this.dosya.dosyaGuncelMi()) {
                    throw new Error('Dosya güncel, Kalan gün sayısı : ' + this.dosya.sonIslemTarihindenItibarenKalanGunSayisi);
                }
                break;
            case DosyaSorguTipleriEnum.SGK_AKTIF_OLAN_DOSYALAR: //sgk aktif olan dosyalar
                let sskAktifFound = false;
                for (const borclu of this.data.borclular) {
                    let sskAktif = await borclu.isSskDurumAktif(this.dosya.dosyaId);
                    if (sskAktif) {
                        sskAktifFound = true;
                        strArray.push({
                            borcluAdSoyad: this.createBorcluStr(borclu),
                            isyeriUnvani: this.createIsyeriBilgileriStr(borclu.ssk)
                        })
                    }
                }
                if (!sskAktifFound)
                    throw new Error('Sgk aktif bulunamadı');
                break;
            case DosyaSorguTipleriEnum.EGM_ARACI_OLAN_BORCLULAR:
                let egmFound = false;
                for (const borclu of this.dosya.borclular) {
                    await borclu.queryEgm(this.dosya.dosyaId);
                    if ((borclu.egm as DosyaBorcluEgmCevap).sorguSonucu) {
                        egmFound = true;
                        strArray.push({
                            borcluAdSoyad: this.createBorcluStr(borclu)
                        })
                    }
                }
                this.data.dilekce.data.borcluAdSoyad = strArray.map(u => u.borcluAdSoyad).join(', ');
                if (!egmFound)
                    throw new Error('Aracı olan borçlu bulunamadı');
                break;
        }
        if (strArray.length > 1)
            this.data.dilekce.multiple = true;
    }

    async ucuncuSahisTarafSorgula() {
        let avukatAdSoyad = await this.getAvukatAdSoyad();
        await this.dosya.tarafBilgileriQuery();
        let alacakliAdSoyad = "";
        this.dosya.tarafBilgileri.forEach((taraf: ITarafBilgisi) => {
            if (taraf.rol == "Alacaklı" && taraf.vekil.includes(avukatAdSoyad))
                alacakliAdSoyad += ", " + taraf.adi;
            if (taraf.rol == "Borçlu" && taraf.vekil.includes(avukatAdSoyad))
                throw new Error('Dosyada borçlu vekilisiniz.');
            if (!this.data.ucuncuSahsaGonder && taraf.rol.includes('Üçüncü'))
                throw new Error('Dosyada üçüncü şahıs bulunuyor.');
        })
        alacakliAdSoyad = alacakliAdSoyad.substr(1);
        this.data.dilekce.data.alacakliAdSoyad = alacakliAdSoyad;
    }

    async ozelSorgula(): Promise<void> {
        let sorgular: any = [];
        let alanlar = this.data.ozelAlanlar.filter((alan: any) => alan.sorgula.length > 0);
        //sorgular = sorgular.concat(this.data.ozelAlanlar.filter((alan:any) => alan.sorgula.length > 0))
        if (this.data.dilekce.sorgula && this.data.dilekce.sorgula.length > 0) {
            sorgular = this.data.dilekce.sorgula;
        } else if (alanlar.length > 0) {
            alanlar.forEach((alan: any) => {
                sorgular = sorgular.concat(alan.sorgula);
            })
        } else {
            return;
        }
        if (this.data.ozelAlanlar.length > 0) {
            let createHtml = "";
            let leng = this.data.ozelAlanlar.length;
            let options = this.data.dilekce.options;
            this.data.ozelAlanlar.forEach((v: any, key: number) => {
                if (options.list === true) {
                    if (key == leng - 1) {
                        let newContent = v.content.substr(0, v.content.length - 5) + v.content.substr(v.content.length - 5).replace(",", "");
                        createHtml += "<p>" + (key + 1) + " - " + newContent + " " + options.lastText + "</p>";
                    } else {
                        createHtml += "<p>" + (key + 1) + " - " + v.content + "</p>";
                    }
                } else {
                    //console.table({v});
                    if (key != 0) {
                        createHtml += options.separator;
                    }
                    createHtml += v.content;
                    if (key == leng - 1) {
                        createHtml += " " + options.lastText;
                    }
                }
            })
            this.data.dilekce.html = this.data.dilekce.html.replace(/\{dilekceOzelAlanlar\}/g, createHtml);
        }

        for (const sorgu of sorgular) {
            await this.secenegeGoreSorgula(sorgu);
        }
        console.log("THIS", this.data);
    }

    async secenegeGoreSorgula(sorgu: string): Promise<void> {
        if (sorgu == "mernis") {
            this.data.dilekce.data.borcluMernisAdresi = "";
        }
        let borcluIndex = 0;
        for (const borclu of this.data.borclular) {
            switch (sorgu) {
                case "sgk":
                    let isYeriUnvaniVeAdresi = this.createBorcluStr(borclu);
                    await borclu.queryBorcluSsk(this.dosya.dosyaId);
                    isYeriUnvaniVeAdresi += " " + this.createIsyeriBilgileriStr(borclu.ssk);
                    if (borcluIndex != this.data.borclular.length - 1)
                        isYeriUnvaniVeAdresi += +" , ";
                    this.data.dilekce.data.isyeriUnvani += isYeriUnvaniVeAdresi;
                    break;
                case "dosyaTaraflari":
                    let alacakliAdSoyad = "";
                    this.dosya.tarafBilgileri.forEach((taraf: any) => {
                        if (taraf.rol == "Alacaklı" && taraf.vekil == "[" + this.avukat.avukatBilgileri.ad + " " + this.avukat.avukatBilgileri.soyad + "]") {
                            alacakliAdSoyad += taraf.adi;
                        }
                        if (borcluIndex != this.data.borclular.length - 1)
                            alacakliAdSoyad += +" , ";
                    });
                    alacakliAdSoyad = alacakliAdSoyad.trim();
                    this.data.dilekce.data.alacakliAdSoyad = alacakliAdSoyad;
                    break;
                case "mernis":
                    this.data.dilekce.data.borcluMernisAdresi += " " + this.createBorcluStr(borclu) + " ";
                    let adres = "";
                    if (borclu.isKurum()) {
                        console.log("mersis ISKURUM ");
                        await borclu.queryBorcluMersis(this.dosya.dosyaId);
                        adres += borclu.mersis.sorguSonucDVO;
                    } else {
                        await borclu.queryBorcluMernis(this.dosya.dosyaId);
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.mahalle)
                            adres += borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.mahalle;
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.csbm)
                            adres += " " + borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.csbm;
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.disKapiNo)
                            adres += " No:" + borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.disKapiNo;
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.icKapiNo)
                            adres += " Daire:" + borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.icKapiNo;
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.il)
                            adres += " " + borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.il;
                        if (borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.ilce)
                            adres += " / " + borclu.mernis.sorguSonucDVO.mernisAdres.adresler[0].ilIlceMerkeziAdresi.ilce;
                    }
                    if (borcluIndex != this.data.borclular.length - 1)
                        adres += " , ";
                    this.data.dilekce.data.borcluMernisAdresi += adres;
                    break;
            }
            borcluIndex++;
        }
    }

    async borcluVarsaBorcluyaGoreHazirla(): Promise<any[]> {
        let strArray: any[] = [];
        let borclular: Array<DosyaBorclu> = await this.dosya.borclularQuery();
        this.data.borclular = borclular;
        if (this.dilekcedeBorcluVarmi(this.data.dilekce)) {
            borclular.forEach((borclu) => {
                strArray.push({
                    borcluAdSoyad: this.createBorcluStr(borclu)
                })
            });
            if (strArray.length > 1)
                this.data.dilekce.multiple = true;
            this.data.dilekce.data.borcluAdSoyad = strArray.map(u => u.borcluAdSoyad).join(', ');
        }
        return strArray;
    }

    async evrakOlustur() {
        let dilekce = this.data.dilekce;
        if (typeof dilekce.multiple != "undefined" && typeof dilekce.multipleHtml != "undefined") {
            dilekce.html = dilekce.multipleHtml;
        }
        dilekce.data.icraDairesi = this.dosya.birimAdi.replace('İcra Dairesi', '');
        dilekce.data.dosyaEsasNo = this.dosya.dosyaNo;
        let sablon = new UdfSablon();
        this.evrak = await sablon.create({data: dilekce.data, html: dilekce.html});
    }

    createIsyeriBilgileriStr(sskData: DosyaBorcluSSKCevap) {
        let output = "";
        if (sskData.sorguSonucu && sskData.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.isyeriUnvan)
            output += sskData.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.isyeriUnvan;
        if (sskData.sorguSonucu && sskData.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.isyeriAdresi)
            output += " " + sskData.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.isyeriAdresi;
        return output;
    }

    async getAvukatAdSoyad(): Promise<string> {
        if (typeof this.avukat.avukatBilgileri.ad == "undefined")
            this.avukat = await app.$uyap.AvukatKisiselBilgileriSorgulama().run({});
        let avukatAdSoyad = this.avukat.avukatBilgileri.ad + " " + this.avukat.avukatBilgileri.soyad;
        this.data.dilekce.data.avAdSoyad = avukatAdSoyad;
        return avukatAdSoyad;
    }

    dilekcedeBorcluVarmi(dilekce: any): boolean {
        return typeof dilekce.data.borcluAdSoyad != "undefined" || typeof dilekce.data.tcKimlikNo != "undefined"
    }

    createBorcluStr(borclu: DosyaBorclu) {
        let borcluAdSoyad = "";
        if (borclu.isKurum()) {
            borcluAdSoyad += (borclu.borcluBilgileri as IKurumDVO).kurumAdi + " (" + (borclu.borcluBilgileri as IKurumDVO).vergiNo + ") ";
        } else {
            borcluAdSoyad += (borclu.borcluBilgileri as IKisiTumDVO).adi + " " + (borclu.borcluBilgileri as IKisiTumDVO).soyadi + " (" + (borclu.borcluBilgileri as IKisiTumDVO).tcKimlikNo + ") ";
        }
        return borcluAdSoyad;
    }
}