import {UyapRequest} from "./UyapRequest";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";

export interface OdemeYapTalep {
    dosyaId: string,
    harcMasrafList: any,                    // ""
    harcMasrafTipi: any,                    // ""
    kisiKurumId: number,
    odemeTipi: OdemeTipiEnum,               // "2"
    postaMasraflariList: any,               // ""
    vakifbankHesapBilgileri: any,           // "null"
}

/*
{
  "dosyaId": "0JZy9yJ4E8mcoo9i0SfWMrqbeZmEnytTFHFBR5zoctot5PCgV8nAGiLZqJoIZCrZ",
  "odemeTipi": "2",
  "kisiKurumId": 43107850,
  "vakifbankHesapBilgileri": "null",
  "harcMasrafTipi": "",
  "harcMasrafList": "",
  "postaMasraflariList": ""
}
 */

//var odemeDataJson = {dosyaId:odeme_bilgileri.dosyaId, kisiKurumId:kisiKurumId, odemeTipi:odeme_bilgileri.odemeTipi, uyapId:odeme_bilgileri.uyapId, vakifbankHesapBilgileri:odeme_bilgileri.vakifbankHesapBilgileri, vakifbankOdemeIstekBilgileri:odeme_bilgileri.vakifbankOdemeIstekBilgileri, smsSifre:odeme_bilgileri.vakifbankSMSSifre};


export interface OdemeYapCevapSuccess {
    message: string,
    icraDairesi: string,
    dosyaEsasNo: string,
    type: "success",
    log: string
}

export interface OdemeYapCevapFail {
    message: string,
    type: "fail"
}

export type OdemeYapCevap = OdemeYapCevapSuccess | OdemeYapCevapFail;

export class OdemeYap extends UyapRequest<OdemeYapTalep, OdemeYapCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "type": "success",
            "message": "Ödeme işlem(ler)i başarıyla gerçekleşti. ₺584,40 ilgili vezne hesap(lar)ına aktarıldı. Yapılan ödemelere ait makbuzlar dosyaya eklendi. \u003cbr\u003e\u003cbr\u003e\u003cstrong\u003eAnkara 4. Genel İcra Dairesi\u003c/strong\u003e - \u003cstrong\u003e2024/32125\u003c/strong\u003e esas numaralı dosyanız açılmıştır.",
            "log": ""
        };

        // Eski response
        // if (Math.random() > 0.4) {
        //     return `<root><ResultEvent>
        //   <type>error</type>
        //   <message>Bu işlemi gerçekleştirebilmek için barokart servislerine ulaşılamadı.</message>
        //   <log/>
        // </ResultEvent></root>`;
        // }
        //
        // return `<root><ResultEvent>
        //   <type>success</type>
        //   <message>Ödeme işlem(ler)i başarıyla gerçekleşti. 81.35 TL  ilgili vezne hesap(lar)ına aktarıldı. Yapılan ödemelere ait makbuzlar dosyaya eklendi. &lt;br&gt;&lt;br&gt;&lt;strong&gt;Bandırma 3. İcra Dairesi&lt;/strong&gt; - &lt;strong&gt;2019/1600&lt;/strong&gt; esas numaralı dosyanız açılmıştır.</message>
        //   <log/>
        // </ResultEvent></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'davaAcilisOdemeIslemleri_brd.ajx';
    }

    async parse(data: any): Promise<OdemeYapCevap> {
        let div = document.createElement("div");
        div.innerHTML = data.message;
        let strongs = div.querySelectorAll("strong");
        if (data.type == "success") {
            data.icraDairesi = strongs[0].innerText;
            data.dosyaEsasNo = strongs[1].innerText;
        }
        return data;
    }

}

export default OdemeYap;
