import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface AdliyedekiTumMahkemelerTalep {
    adliye: string,
    bltr2: string       //yargı birimi
}

export type AdliyedekiTumMahkemelerCevap = Array<IMahkeme>;

interface IMahkeme {
    birimAdi: string,
    birimId: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    isTumunuKopyala: boolean,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: number
}

export default class AdliyedekiTumMahkemeler extends UyapRequest<AdliyedekiTumMahkemelerTalep, AdliyedekiTumMahkemelerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "adliyedekiTumMahkemeler_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse() {
        return [{"birimAdi":"Ankara 1. Tüketici Mahkemesi","birimId":"1000933","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"Ankara 2. Tüketici Mahkemesi","birimId":"1009202","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},{"birimAdi":"Ankara(Kapatılan) 16. Tüketici Mahkemesi","birimId":"1040759","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "adliyedeki_tum_mahkemeler.ajx";
    }

    async parse(data: any): Promise<AdliyedekiTumMahkemelerCevap> {
        return data;
    }
}