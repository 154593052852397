import {UyapRequest} from "./UyapRequest";
import app from "@/main";
import {ISorguSonuc} from "./CommonInterfaces";

export interface SskEmekliTalep {
    dosyaId: string,
    kisiKurumId: number,
}

export interface SskEmekliCevap extends ISorguSonuc<ISskEmekliSonuc>{}

export interface ISskEmekliSonuc {
    emekliKaydi4AList: Array<any>,
    hataKodu: 0 | 995,      // başarılı | kayıt bulunamadı
    hataMesaji: string
}


export default class SskEmekli extends UyapRequest<SskEmekliTalep, SskEmekliCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "SskEmekli_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isGecerlilikAktifAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"4********10","adi":"AH**** MÜ***","soyadi":"YA*******","babaAdi":"A*********","anaAdi":"Ş*****","dogumTarihiStr":"1957","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":28698317,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 2. Genel İcra Dairesi","birimId":"1000963","orgKodu":"1.04.011.000.6037","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6037","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/27440","dosyaNoYil":2023,"dosyaSira":27440,"dosyaId":"013@RlwAzAkR3QHUdOJ2Ii6nSP@e0XsQjDM+hD526WYoCS7j25olVjBkjt+4aKn@","dosyaAcilisTarihi":"08/07/2023 10:35","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ABDULSELAM DENİZ, AHMETİ MÜNÜR YALÇINALP","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"26/07/2024 14:44","sorguSonucDVO":{"emekliKaydi4AList":[],"hataKodu":995,"hataMesaji":"Kayıt Bulunamadı..."},"sorgulamaTuru":"SSK Emeklisi","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_sgk_sskEmekliBilgileri.ajx';
    }

    async parse(data: any): Promise<SskEmekliCevap> {
        if (data.sorguSonucDVO?.hataKodu == 995) {
            data.message = data.sorguSonucDVO.hataMesaji;
            data.sorguSonucu = false;
        } else
            data.message = "Sorgulama başarılı";
        return data;
    }

}