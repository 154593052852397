import app from "@/main";
import {VakifbankHesapBilgileri, VakifbankHesapListesiCevap} from "../uyap/VakifbankHesapListesi";
import {VakifbankSmsOnayCevap} from "../uyap/VakifbankSmsOnay";
import {DosyaOdemeIslemleriCevap} from "../uyap/DosyaOdemeIslemleri";
import {OdemeTipiEnum} from "./OdemeTipleri";

export interface TebligatGideriOdeResult {
    result: DosyaOdemeIslemleriCevap,
    tutar: any,
    adet: any
}

export default class VakifbankOdeme {
    //{"2":"Barokart","4":"Vakıfbank"}
    selectedHesap!: VakifbankHesapBilgileri;
    hesapListesi!: VakifbankHesapListesiCevap;
    smsOnayResult!: VakifbankSmsOnayCevap;
    taksitSayisi: number = 1;
    smsSifre: any = "";
    tutar: any = "";
    tryCounter: number = 0;
    maxTryCount: number = 5;

    async run(tutar: any, uyapGrupId: string = ""): Promise<any> {
        try {
            await this.getHesapListesi(tutar, uyapGrupId);
            await this.smsOnay();
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getHesapListesi(tutar: any, uyapGrupId: string = ""): Promise<void> {
        try {
            this.tutar = tutar;
            this.hesapListesi = await app.$uyap.VakifbankHesapListesi().run({
                tutar: tutar,
                uyapGrupId: uyapGrupId
            });
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async smsOnay(): Promise<void> {
        try {
            this.tryCounter++;
            if (this.tryCounter >= this.maxTryCount)
                return Promise.reject({message: "Sms doğrulama süresi doldu.Ödeme Başarısız !!"});
            this.smsSifre = prompt("Vakıfbank ile " + this.tutar + " TL ödeme yapmak için lütfen telefonunuza gelen sms şifresini giriniz.", "Vakıfbank Ödeme");
            this.smsOnayResult = await app.$uyap.VakifbankSmsOnay().run({
                smsSifre: this.smsSifre,
                uyapGrupId: this.hesapListesi.uyapGrupId
            });
            if (this.smsOnayResult.sonucKodu == "00") {
                return Promise.resolve();
            } else {
                app.$toast.error(this.smsOnayResult.sonucAciklama);
                return this.smsOnay();
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    takipAcOdemeYapToUyapJson(
        dosyaId: any,
        kisiKurumId: any,
        uyapId: any,
    ) {
        return {
            dosyaId: dosyaId,
            kisiKurumId: kisiKurumId,
            odemeTipi: OdemeTipiEnum.Vakifbank,//vakifbank
            uyapId: uyapId,
            vakifbankHesapBilgileri: this.vakifbankHesapBilgileriToUyapJson(),
            vakifbankOdemeIstekBilgileri: this.vakifbankOdemeIstekBilgileriToUyapJson(),
            smsSifre: this.smsSifre
        };
    }

    setTaksitSayisi(taksitSayisi: number) {
        this.taksitSayisi = taksitSayisi;
    }

    vakifbankOdemeIstekBilgileriToUyapJson() {
        //{"bankaId":"c4678f50-5409-4cae-b954-381a07063f23","islemSahiplikTuru":"KendiAdina","toplamTutar":"13
        // .75","uyapGrupId":"36992574"}
        return {
            bankaId: this.smsOnayResult.bankaId,
            islemSahiplikTuru: this.hesapListesi.islemSahiplikTuru,
            toplamTutar: this.tutar,
            uyapGrupId: this.smsOnayResult.uyapGrupId
        }
    }

    /*
    Takip AÇ
    bankaId
            :
            ""
            islemSahiplikTuru
            :
            "KendiAdina"
            toplamTutar
            :
            "320.98"
            uyapGrupId
            :
            "37035762"
     */
    vakifbankHesapBilgileriToUyapJson() {
        //{"hesapKartNo":"00158007300608019","kartId":"","hesapTipi":"VADESIZ","subeKodu":"S00325","subeAdi":"ADL
        // İYE SARAYI ŞUBESİ","bakiyeDurumu":"YETERLI BAKIYE","taksitSayisi":"1","taksitSecenekleri":{}}
        return {
            hesapKartNo: this.selectedHesap.hesapKartNo,
            kartId: "",
            hesapTipi: this.selectedHesap.hesapTipi,
            subeKodu: this.selectedHesap.subeKodu,
            subeAdi: this.selectedHesap.subeAdi,
            bakiyeDurumu: this.selectedHesap.bakiyeDurumu,
            taksitSayisi: this.taksitSayisi.toString(),
            taksitSecenekleri: {}
        }
    }
}