import _swal from "sweetalert2";
import {AxiosResponse} from "axios";
import app from "@/main";
import {ProgramPaths} from "@/enum/ProgramPaths";

export class StatusCodeHelper {
    // !!UYARI!! Statik değişken sürekli true OLAMAMALIDIR. Yoksa ilgili uyarı sürekli kapalı kalır.
    static dontShow400Alert: boolean = false;
    static dontShow422Alert: boolean = false;
    static dontShow404Alert: boolean = false;

    success(response: AxiosResponse) {
        let method: string = response.config.method ? response.config.method.toUpperCase() : "";
        if (["POST", "PUT", "DELETE"].includes(method)) {
            app.$toast?.success("İşlem Başarılı");
        }
    }

    error(error: any) {
        switch (error.response.status) {
            case 400 : {
                if (!StatusCodeHelper.dontShow400Alert)
                    this.error400(error);
                break;
            }
            case 401 : {
                if (!location.href.includes("giris") || (location.href.includes("giris") && app.$store.state.user.id)) {
                    if (location.pathname != '/login')
                        localStorage.setItem('routingPath', location.pathname)
                    localStorage.clear();
                    sessionStorage.clear();
                    location.href = "/giris";
                    console.error("GIRIS HREF");
                }
                break;
            }
            case 402 : {
                this.error402(error)
                break;
            }
            case 403 : {
                this.error403(error)
                break;
            }
            case 404 : {
                if (!StatusCodeHelper.dontShow404Alert)
                    this.error404();
                break;
            }
            case 422 : {
                if (!StatusCodeHelper.dontShow422Alert)
                    this.error422(error);
                break;
            }
            case 500 : {
                this.error500();
                break;
            }
            case 503 : {
                this.error503();
                break;
            }
            default : {
                this.errorDefault(error);
                break;
            }
        }
    }

    errorDefault(error: any) {
        let message = "";
        let errors: Array<Array<string>> = error.response.data.errors;
        message = "<h2 style='color: white'>" + error.response.status + "</h2><h4 style='color: white'>" + error.response.statusText + "</h4>";
        if (errors) {
            message += Object.values(errors)
                .map((err: Array<string>) => {
                    return "<ul style='color: white'><li><h4>" + err[0] + "</h4></li></ul>";
                }).join("");
        }
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error400(error: any) {
        let message = "";
        let errors: Array<Array<string>> = error.response.data.errors;
        message = "<h2 style='color: white'>400</h2><h4 style='color: white'>İstek Hatalı</h4>";
        if (errors) {
            message += Object.values(errors)
                .map((err: Array<string>) => {
                    return "<ul style='color: white'><li><h4>" + err[0] + "</h4></li></ul>";
                }).join("");
        }
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error402(error: any) {
        _swal.fire({
            icon: 'error',
            title: 'Uyarı',
            html:
                '<b style="font-family: Calibri">' + error.response.data.message + '</b><br><br> ' +
                '<a target="_blank" href="https://zirve-bilgisayar.com/" style="font-family: Tahoma; font-size: medium;">Yükseltme yapmak için tıklayınız..</a> ',
            showCancelButton: true,
            showConfirmButton: false,
            background: "rgba(255,255,255,0.95)",
            cancelButtonText: 'Kapat',
        })
    }

    error403(error: any) {
        let message = "<h2 style='color: white'>403</h2><h4 style='color: white'>Yasak</h4><h4 style='color: white'>İşlemi gerçekleştirmeye yetkiniz yoktur.</h4>";
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error404() {
        let message = "<h2 style='color: white'>404</h2><h4 style='color: white'>Sayfa Bulunamadı</h4>";
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error422(error: any) {
        let message = "<h2 style='color: white'>422</h2><h4 style='color: white'>Eksik Veri Girişi</h4><h4 style='color: white'>Lütfen Bu Alanları Doldurun:</h4>";
        let errors: Array<Array<string>> = error.response.data.errors;
        if (errors) {
            message += Object.values(errors)
                .map((err: Array<string>) => {
                    return "<ul style='color: white'><li><h4>" + err[0] + "</h4></li></ul>";
                }).join("");
        }
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error500() {
        let message = "<h2 style='color: white'>500</h2><h4 style='color: white'>Sunucuda Bir Hata Oluştu</h4>";
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }

    error503() {
        let message = "<h2 style='color: white'>503</h2><h4 style='color: white'>Sunucu Şu Anda Hizmet Vermiyor</h4>";
        _swal.fire({
            icon: "error",
            iconColor: "white",
            position: "top-end",
            html: message,
            showConfirmButton: false,
            background: "rgba(255,0,0,0.5)",
            timer: 3500,
            customClass: {
                container: "errorMessageText",
            },
        });
    }
}