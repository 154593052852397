import {UyapRequest} from "./UyapRequest";
import {IAdresDVORow} from "./CommonInterfaces";

export interface MtsTarafTebligatListTalep {
    dosyaId: string
}

export type MtsTarafTebligatListCevap = MtsTarafTebligatCevapRow[];

export interface MtsTarafTebligatCevapRow {
    tarafTebligatDVO: {
        pttDurum: number,
        hasTebligatEvrak: boolean,
        evrakKisiDVO: EvrakKisiDvo,
        adresDVO: IAdresDVORow
    }
}

export interface EvrakKisiDvo {
    ilgiliDosyaKisiId: string,
    asilTarafDosyaKisiId: string,
    ilgiliKisiTipi: string,
    ilgili: string,
    dosya: IDosyaRow,
    ilgiliKisiIlkKisiKurumId: string,
    asilTarafIlkKisiKurumId: string,
    ilgiliKisiRolu: string,
    ilgiliKisiRolId: string,
    ilgilininHarcDurumu: string,
    sanikStatusu: string,
    secili: boolean,
    tcKimlikNo?: string,
    babaAdi: string,
    kisi: boolean,
    tcVatandasi: boolean,
    guncelKisiKurumId: string,
    mernisGuncellemesiYapildi: string
}

export interface IDosyaRow {
    clientDosyaDVO: Array<IClientDosyaDVORow>,
    dosyaTur: number
}

export interface IClientDosyaDVORow {
    dosyaID: string,
    dosyaNotSayisi: number,
    dosyaBilgileriTurKodu: number,
}


export default class MtsTarafTebligatList extends UyapRequest<MtsTarafTebligatListTalep, MtsTarafTebligatListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"tarafTebligatDVO":{"pttDurum":0,"hasTebligatEvrak":false,"evrakKisiDVO":{"ilgiliDosyaKisiId":"1430732437","asilTarafDosyaKisiId":"1430732437","ilgiliKisiTipi":"T","ilgili":"FULYA YÜZ","dosya":{"clientDosyaDVO":{"dosyaID":"tdafE@OMSDevOGWo3D4D0PqMkhwGjrlMDzPtwpzt3R1Cjm9OArYnZbVu2O83pMGX","dosyaNotSayisi":0,"dosyaBilgileriTurKodu":0},"dosyaTur":-1},"ilgiliKisiIlkKisiKurumId":"136438758","asilTarafIlkKisiKurumId":"136438758","ilgiliKisiRolu":"BORÇLU VE MÜFLİS","ilgiliKisiRolId":"22","ilgilininHarcDurumu":"1","sanikStatusu":"E","secili":false,"babaAdi":"FİRUZ","kisi":true,"tcVatandasi":true,"guncelKisiKurumId":136438758,"mernisGuncellemesiYapildi":"E"},"adresDVO":{"id":1430329992,"adresId":356941586,"dosyaId":"hcIuWBhJ5ymeLsU5as1V9PblM1Byzf579+eQafxXnmpZfzHZpS4vYjBTd07f78KF","ilkKisiKurumId":136438758,"guncelKisiKurumId":136438758,"gizliYazismaAdresi":false,"kisikurumtur":"G","adresDVO":{"ilKodu":26,"ilAdi":"ESKİŞEHİR","ilceAdi":"ODUNPAZARI ","ilceKodu":14,"adres":"VİŞNELİK MAH. TAŞKÖPRÜ CAD.  NO: 29A ODUNPAZARI / ESKİŞEHİR","kisiKurumID":136438758,"adresID":356941586,"adresTuru":"ADRTR00001","adresTuruAciklama":"Yurt İçi İkametgah Adresi","eklenebilirAdresMi":false,"kayitliAdresMi":false,"aktifAdresmi":true,"guncellendiMi":false,"aktif":"E"}}}}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mtsTarafTebligatList_brd.ajx';
    }

    async parse(data: any): Promise<MtsTarafTebligatListCevap> {
        return data;
    }

}

