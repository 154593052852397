import {UyapRequest} from "./UyapRequest";

export interface OdemeIslemiSorgulamaTalep {
    baslangicTarihi: string,        // Örnek: 25.1.2024
    bitisTarihi: string
}

export type OdemeIslemiSorgulamaCevap = Array<IOdemeIslemi>

export interface IOdemeIslemi {
    uyapId: number,
    uyapIslemTarihi: string,
    birimAdi: string,
    dosyaNo?: string,
    durum: string,
    bankaAdi: string,
    tutar: number,
    borcluHesapNo?: string,
    birimId: string,
    dosyaId: string,
    dosyaYil?: string,
    dosyaSira?: string,
    dosyaTurKod?: string,
    uyapIslemTur: string,
    teminatIadeEdilmeDurumu: number,
    dosyaTaraflari?: Array<IDosyaTaraf>,
    seriNo?: string,
    siraNo?: string,
    ozelNo?: string,
    harcMasrafTahsilatTarihi?: string,
    aciklama?: string,
    makbuzTipi?: string,
    kalanToplamMiktar?: number,
    gecerliMi?: string,
    tarafAdi?: string,
    internetDurum?: string
}

interface IDosyaTaraf {
    isim: string,
    sifat: string,
    soyad?: string
}

export default class OdemeIslemiSorgulama extends UyapRequest<OdemeIslemiSorgulamaTalep, OdemeIslemiSorgulamaCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"uyapId":435589707,"uyapIslemTarihi":"2023-12-28 10:22:07.0","birimAdi":"Ankara 4. Genel İcra Dairesi","dosyaNo":"2023/91045","durum":"Başarılı","bankaAdi":"Vakıfbank","tutar":87,"borcluHesapNo":"0105-00158007290493095","birimId":"1000954","dosyaId":"s@8ggywsMFcxTKSyhokG4Y@FIt2enhSpZU2BEExlCPcnzvTb052panfhy7u+IvFy","dosyaYil":"2023","dosyaSira":"91045","dosyaTurKod":"İcra Dosyası","uyapIslemTur":"24","teminatIadeEdilmeDurumu":0,"dosyaTaraflari":[{"isim":"GÜNEY YAPI İZOLASYON İNŞAAT SANAYİ TİCARET LİMİTED ŞİRKETİ","sifat":"BORÇLU VE MÜFLİS"},{"isim":"ZİRVE BİLGİSAYAR TİCARET VE SANAYİ LİMİTED ŞİRKETİ","sifat":"ALACAKLI"}]},{"uyapId":435961855,"uyapIslemTarihi":"2023-12-28 23:25:49.0","birimAdi":"Ankara 2. Genel İcra Dairesi","dosyaNo":"2023/276666","durum":"Başarılı","bankaAdi":"Vakıfbank","tutar":308.25,"borcluHesapNo":"0105-00158007296864054","birimId":"1000963","seriNo":"AB2023","siraNo":"255089260","ozelNo":"130566","harcMasrafTahsilatTarihi":"2023-12-28 23:25:49","aciklama":"Internetten yatırıldı.","makbuzTipi":"1","kalanToplamMiktar":308.25,"dosyaId":"pDdkxPOk0OOwlxcGAGPR1Nq@dmLfaXg9+b8hHl0DLpuqemfQPdcDNofAthDu58BV","gecerliMi":"1","tarafAdi":"NEZAHAT EZGİ ORAL Adına Avukat NİHAN ERDOĞAN Yatırmıştır.","dosyaYil":"2023","dosyaSira":"276666","dosyaTurKod":"İcra Dosyası","uyapIslemTur":"16","internetDurum":"1","teminatIadeEdilmeDurumu":0,"dosyaTaraflari":[{"isim":"MUSTAFA SERDAR","soyad":"KAYAOĞLU","sifat":"BORÇLU VE MÜFLİS"},{"isim":"NEZAHAT EZGİ","soyad":"ORAL","sifat":"ALACAKLI"}]},{"uyapId":441922483,"uyapIslemTarihi":"2024-01-23 11:50:54.0","birimAdi":"Bakırköy 14. İcra Dairesi","durum":"Banka Belirsizliği","bankaAdi":"Barokart","tutar":586.35,"birimId":"1034261","dosyaId":"Xxj5q+x3L3jkKMI19PUece7VCM0WimJjvCB2pAabdZyV6dbGARwaqObZEvdgn415","uyapIslemTur":"16","teminatIadeEdilmeDurumu":0}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "uyap_odeme_islemi_sorgula.ajx";
    }

    async parse(data: any): Promise<OdemeIslemiSorgulamaCevap> {
        return data;
    }
}