import Ilamsiz from "../Ilamsiz/Ilamsiz";
import MtsAlacakKalem, {MtsParaBirimi} from "./MtsAlacakKalem";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class MtsDiger extends Ilamsiz {
    id: any = null;
    no: string = "";
    tutarTL: any;
    selectedParaBirimi: MtsParaBirimi = new MtsParaBirimi();
    tarih: string = "";
    muacceliyetTarihi: string = "";
    aciklama: string = "";
    alacakKalemleri: MtsAlacakKalem[] = []

    static fromJson(jsonData: any): MtsDiger {
        return Args.staticFromJson(jsonData,new MtsDiger());
    }

    constructor() {
        super();
    }

    toUyapJson() {
        return this;
    }
}

