import {UyapRequest} from "./UyapRequest";

export interface MtsTebligatSafahatListTalep {
    barkodNo: number
}

export type MtsTebligatSafahatListCevap = MtsTebligatSafahatListRow[];


export interface MtsTebligatSafahatListRow {
    aciklama: string,
    islemYeri: string,
    safahatKodu: number,
    siraNo: number,
    tarih: string
}

export class MtsTebligatSafahatList extends UyapRequest<MtsTebligatSafahatListTalep, MtsTebligatSafahatListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): Array<MtsTebligatSafahatListRow> {
        return [{
            "aciklama": "Tebligat basım için hazırlanıyor",
            "islemYeri": "",
            "safahatKodu": 0,
            "siraNo": 0,
            "tarih": "20190821"
        }, {
            "aciklama": "Kabul Edildi",
            "islemYeri": "ANKARA P.İ.M./ANKARA P.İ.M.",
            "safahatKodu": 0,
            "siraNo": 1,
            "tarih": "22/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "ANKARA P.İ.M./ANKARA P.İ.M.",
            "safahatKodu": 0,
            "siraNo": 2,
            "tarih": "22/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "ANKARA P.İ.M./ANKARA P.İ.M.",
            "safahatKodu": 0,
            "siraNo": 3,
            "tarih": "22/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "KASTAMONU/KASTAMONU",
            "safahatKodu": 0,
            "siraNo": 4,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "Gönderinin Geliş Kaydı Yapıldı",
            "islemYeri": "DADAY/DADAY",
            "safahatKodu": 0,
            "siraNo": 5,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "Dağıtıcıya Verildi",
            "islemYeri": "DADAY/DADAY",
            "safahatKodu": 0,
            "siraNo": 6,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "MUHATABA BiZZAT TESLiM",
            "islemYeri": "DADAY/DADAY",
            "safahatKodu": 0,
            "siraNo": 7,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "DADAY/DADAY",
            "safahatKodu": 0,
            "siraNo": 8,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "KASTAMONU/KASTAMONU",
            "safahatKodu": 0,
            "siraNo": 9,
            "tarih": "23/08/2019"
        }, {
            "aciklama": "Mazbata Datası Çıkaran Merciye Gönderildi",
            "islemYeri": "DADAY/DADAY",
            "safahatKodu": 0,
            "siraNo": 10,
            "tarih": "24/08/2019"
        }, {
            "aciklama": "Gönderinin Geliş Kaydı Yapıldı",
            "islemYeri": "ANKARA P.İ.M./ANKARA P.İ.M.",
            "safahatKodu": 0,
            "siraNo": 11,
            "tarih": "24/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "ANKARA P.İ.M./ANKARA P.İ.M.",
            "safahatKodu": 0,
            "siraNo": 12,
            "tarih": "24/08/2019"
        }, {
            "aciklama": "Gönderinin Geliş Kaydı Yapıldı",
            "islemYeri": "ANKARA TEBLİGAT MERKEZİ/ANKARA TEBLİGAT MERKEZİ",
            "safahatKodu": 0,
            "siraNo": 13,
            "tarih": "24/08/2019"
        }, {
            "aciklama": "Torbaya Eklendi",
            "islemYeri": "ANKARA TEBLİGAT MERKEZİ/ANKARA TEBLİGAT MERKEZİ",
            "safahatKodu": 0,
            "siraNo": 14,
            "tarih": "26/08/2019"
        }, {
            "aciklama": "Gönderinin Geliş Kaydı Yapıldı",
            "islemYeri": "ETİMESGUT PDM/ETİMESGUT PDM",
            "safahatKodu": 0,
            "siraNo": 15,
            "tarih": "27/08/2019"
        }, {
            "aciklama": "Cihet Hazırlama Listesine Eklendi",
            "islemYeri": "ETİMESGUT PDM/ETİMESGUT PDM",
            "safahatKodu": 0,
            "siraNo": 16,
            "tarih": "28/08/2019"
        }, {
            "aciklama": "Dağıtıcıya Verildi",
            "islemYeri": "ETİMESGUT PDM/ETİMESGUT PDM",
            "safahatKodu": 0,
            "siraNo": 17,
            "tarih": "29/08/2019"
        }, {
            "aciklama": "MAZBATA TESLİM",
            "islemYeri": "ETİMESGUT PDM/ETİMESGUT PDM",
            "safahatKodu": 0,
            "siraNo": 18,
            "tarih": "29/08/2019"
        }];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_tebligat_safahat_list.ajx';
    }

    async parse(data: any): Promise<MtsTebligatSafahatListCevap> {
        return data
    }

}

export default MtsTebligatSafahatList;
