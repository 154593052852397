import {UyapRequest} from "./UyapRequest";

export interface TevziAlTalep {
    IcraDosyaBilgileri: any,
    TarafList: any,
    IlamsizList: any,
    IlamliList: any,
    TahsilatList: any
}

export interface TevziAlCevap {
    dosyaId: string,
    takibinYolu: number,
    aciklama: string,
    dayanakBelgeZorunlumu: boolean,
    takibeEsasTutar: number,
    dosyaDurumu: number,
    takipAnaTur: number,
    takipTur: number,
    bk84Uygulansinmi: "E" | "H",
    bsmvUygulansinmi: "E" | "H",
    dosyaTurKodu: number,
    aciklama48E9: string,
    dosyaAcilisTarihi: string,
    kkdfHesaplansinmi: "E" | "H",
    birimAdi: string,
    vekaletUcretineKDVUygulansinmi: "E" | "H",
    feragatIslmDrm: number,
    gelisYeri: number,
    kdv: "E" | "H",
    vergi: "E" | "H",
    mahiyetId: number,
    gnlDosyaDurumu: number,
    birimID: string
}

export default class TevziAl extends UyapRequest<TevziAlTalep, TevziAlCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "dosyaId": 510755058,
            "takibinYolu": 0,
            "aciklama": "Alacağın tahsili tarihine kadar %...... faizi masraf ve vekalet ücreti ile tahsili,kısmi ödemelerde BK.100 e göre yapılmasını talep ederim.",
            "takibeEsasTutar": 389,
            "dosyaDurumu": 0,
            "takipAnaTur": 1,
            "takipTur": 0,
            "bk84Uygulansinmi": "E",
            "bsmvUygulansinmi": "H",
            "dosyaTurKodu": 35,
            "aciklama48E9": "Haciz Yolu",
            "dosyaAcilisTarihi": "29/03/2019 15:59:31",
            "kkdfHesaplansinmi": "H",
            "birimAdi": "Dikili İcra Dairesi",
            "vekaletUcretineKDVUygulansinmi": "H",
            "feragatIslmDrm": 2,
            "gelisYeri": 1,
            "kdv": "H",
            "vergi": "H",
            "mahiyetId": 1307,
            "gnlDosyaDurumu": 0,
            "birimID": "1001843"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'icra_takip_tevzi_islemleri.ajx';
    }

    async parse(data: any): Promise<TevziAlCevap> {
        return data;
    }
}
