import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";
import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";
import app from "@/main";
import {AvukatDosyalariCevap} from "@/plugins/uyap-plugin/uyap/AvukatDosyalari";

export class AvukatIcraDosyalariTalep {
    dosyaDurumKod: DosyaDurum = DosyaDurum.ACIK;           // açık | kapalı
    pageSize: number = 500;
    pageNumber: number = 1;
    birimTuru2: string = "1101";
    birimTuru3: string = "2";
    dosyaYil?: number;
    dosyaSira?: number;
    dosyaAcilisTarihiStart?: string;    // dd.mm.yyyy
    dosyaAcilisTarihiEnd?: string;      // dd.mm.yyyy
    dosyaKapanisTarihiStart?: string;   // dd.mm.yyyy
    dosyaKapanisTarihiEnd?: string;     // dd.mm.yyyy
    tarafTuru?: 0 | 1;                  // kişi | kurum
    adi?: string;
    soyadi?: string;
    tcKimlikNo?: string;
    kurumAdi?: string;
    mersisNo?: string;
    vergiNo?: string;

    setAcilisTarihAraligi(baslangic: string, bitis: string) {
        this.dosyaAcilisTarihiStart = app.$uyapHelper.changeUyapDateFormat(baslangic);
        this.dosyaAcilisTarihiEnd = app.$uyapHelper.changeUyapDateFormat(bitis);
    }

    setKapanisTarihAraligi(baslangic: string, bitis: string) {
        this.dosyaKapanisTarihiStart = app.$uyapHelper.changeUyapDateFormat(baslangic);
        this.dosyaKapanisTarihiEnd = app.$uyapHelper.changeUyapDateFormat(bitis);
    }

    setKisi(adi: string, soyadi: string, tcKimlikNo: string) {
        if (adi)
            this.adi = adi
        if (soyadi)
            this.soyadi = soyadi
        if (tcKimlikNo)
            this.tcKimlikNo = tcKimlikNo
        this.tarafTuru = 0;
    }

    setKurum(kurumAdi: string, mersisNo: string, vergiNo: string) {
        if (kurumAdi)
            this.kurumAdi = kurumAdi;
        if (mersisNo)
            this.mersisNo = mersisNo;
        if (vergiNo)
            this.vergiNo = vergiNo;
        this.tarafTuru = 1;
    }
}

export enum DosyaDurum{
    ACIK,
    KAPALI
}


export interface AvukatIcraDosyalariCevap {
    items: Array<IAvukatDosya>,
    lenght: number
}

export default class AvukatIcraDosyalari extends UyapRequest<AvukatIcraDosyalariTalep, AvukatIcraDosyalariCevap> {
    delayTime = 1000;
    items: Array<IAvukatDosya> = [];
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "icradosyasorgula_" + app.$uyapHelper.hash(JSON.stringify(this.talepData))
        };
    }

    getUrl(): string {
        if(typeof this.talepData.tarafTuru != "undefined")
            return this.ImzaClientAddr + "search_phrase_taraf_detayli.ajx";
        else
            return this.ImzaClientAddr + "search_phrase_detayli.ajx";
    }

    async parse(data: any): Promise<AvukatIcraDosyalariCevap> {
        return {
            items: data[0],
            lenght: data[1]
        };
    }


    async recursive(data: any): Promise<AvukatDosyalariCevap>{
        this.talepData = data;
        let response = await this.run(this.talepData);
        this.items = this.items.concat(response.items);
        if(response.items.length == 500){
            data.pageNumber++;
            return this.recursive(data);
        }
        return {
            items:this.items,
            length:this.items.length
        };
    }

    async esasNoAraligi(talepData: AvukatIcraDosyalariTalep, yil: number, no1: number, no2: number | undefined): Promise<AvukatIcraDosyalariCevap> {
        if (no2) {
            let dosyaList = await this.run(talepData);
            dosyaList.items = dosyaList.items.filter((v) => {
                let esas = v.dosyaNo.trim();
                // bazi dosyalar 2019/1232 (2019/2321) olarak geldigi icin ara bir kontrol yapiliyor.
                if (esas.indexOf(" ") >= 0) {
                    esas = esas.split(" ")[0];
                }
                let splited = esas.split("/");
                let sYil = splited[0].trim();
                let sNo = splited[1].trim();
                if (yil == parseInt(sYil) && parseInt(sNo) >= no1 && parseInt(sNo) <= no2) {
                    return v;
                }
            });
            dosyaList.lenght = dosyaList.items.length;
            return dosyaList;
        } else {
            talepData.dosyaYil = yil;
            talepData.dosyaSira = no1;
            return await this.run(talepData);
        }
    }

    getMockResponse(): any {
        return [[{"dosyaId":"@VjFG@WegVa6S0oep1hdawWZKzisA2fYUtTxjTgdI2PqqNSQuGet5QzzF3gIspZA","dosyaNo":"2019/35562","dosyaDurumKod":0,"dosyaDurum":"Açık","dosyaTurKod":35,"dosyaTur":"İcra Dosyası","dosyaAcilisTarihi":{"date":{"year":2019,"month":8,"day":8},"time":{"hour":11,"minute":15,"second":37,"nano":0}},"birimAdi":"Alanya İcra Dairesi","birimId":"1054058","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","isDavaDosyasiAcilmisMi":false,"isNew":true},{"dosyaId":"joMIQKateYJH3hnz@cZzpucxo1Wcs@Nw0+wHDpaHwl8YlNAjvkZ78GXnMS7S9X3o","dosyaNo":"2023/97995","dosyaDurumKod":0,"dosyaDurum":"Açık","dosyaTurKod":35,"dosyaTur":"İcra Dosyası","dosyaAcilisTarihi":{"date":{"year":2015,"month":5,"day":22},"time":{"hour":10,"minute":0,"second":28,"nano":0}},"birimAdi":"Ankara 7. Genel İcra Dairesi","birimId":"1000942","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","isDavaDosyasiAcilmisMi":false,"isNew":true},{"dosyaId":"jWQ@dL@5RNRQEIUJScwlR9eAVBs3KqyoHnuR3Ab+kxEa@Il2By6g1MGH7HbHPP9v","dosyaNo":"2019/35560","dosyaDurumKod":0,"dosyaDurum":"Açık","dosyaTurKod":35,"dosyaTur":"İcra Dosyası","dosyaAcilisTarihi":{"date":{"year":2014,"month":8,"day":8},"time":{"hour":12,"minute":43,"second":2,"nano":0}},"birimAdi":"Alanya İcra Dairesi","birimId":"1054058","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","isDavaDosyasiAcilmisMi":false,"isNew":true}],3];
    }
}