import MtsTakipAc from "../../../takip_ac/MtsTakipAc";
import {TaskDurum} from "../../../enum/TaskDurum";
import {MtsXmlParser} from "../../../takip_ac/MtsXmlParser";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";

export class MtsTakipOdemeYapTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        console.log("handle MtsTakipOdemeYapTaskHandler TASK HANDLER", task);
        try {
            let takip: MtsTakipAc = MtsTakipAc.fromJson(task.data);
            takip.parsedFile = MtsXmlParser.fromJson(task.data);
            await takip.odemeYap();
            task.response = takip;
            task.status = TaskDurum.BITTI_BASARILI;
            return task
        } catch (e) {
            throw new Error(e.message);
        }
    }

}