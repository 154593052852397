import {UyapRequest} from "./UyapRequest";

export interface GetPortalAvukatTalepAdresTuruListTalep {
    // Boş
}

export type GetPortalAvukatTalepAdresTuruListCevap = Array<IAvukatTalepAdresTuru>

export interface IAvukatTalepAdresTuru {
    name: string,
    value: number
}

export default class GetPortalAvukatTalepAdresTuruList extends UyapRequest<GetPortalAvukatTalepAdresTuruListTalep, GetPortalAvukatTalepAdresTuruListCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetPortalAvukatTalepAdresTuruList_"
        };
    }

    getMockResponse(): any {
        return [{"name":"E-Tebligat Adresi","value":0},{"name":"Mernis Adresi","value":1},{"name":"Mersis Adresi","value":2},{"name":"Yeni Adres","value":3}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getPortalAvukatTalepAdresTuruList_brd.ajx";
    }

    async parse(data: any): Promise<GetPortalAvukatTalepAdresTuruListCevap> {
        return data;
    }
}