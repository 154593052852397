import {UyapRequest} from "./UyapRequest";

export interface EvrakGonderTalep {
    formData: true,
    data: FormData
}

export interface EvrakGonderCevap {
    type: string,
    message: string
}

export default class DosyaAcilisEvrakGonder extends UyapRequest<EvrakGonderTalep, EvrakGonderCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {"type":"success","message":"Evraklar, tevzi dosyasına gönderildi.","log":""};
    }

    getUrl(): string {
        return this.ImzaClientAddr + "davaAcilisEvrakGonderme_brd.ajx";
    }

    async parse(data: any): Promise<EvrakGonderCevap> {
        if (data.type == "success")
            return Promise.resolve(data);
        else
            return Promise.reject(data);
    }
}

